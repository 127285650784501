<template>
  <div ref="cookiebot" class="mt-12 cookie-bot" v-show="cookiesOpen"></div>
  <!--<CookieLaw buttonText="Accetta" :class="{'open' : cookiesOpen}">
    <div slot-scope="props">
      <MarkdownBlock :inline="false" linkify tag="div" :text="$root.allLanguages[$route.params.language].cookiesCopy" />
      <p @click="cookiesOpen = !cookiesOpen" :class="pointer">Show Cookies Detail</p>
      <a class="pa-md-0 pl-2 button" @click="props.accept"><IconClose class="svg-fill-white"></IconClose></a>

      </div>
    </div>
  </CookieLaw>-->
</template>

<script>
// import CookieLaw from 'vue-cookie-law'
// import { MarkdownBlock } from '@monogrid/vue-lib'
// import IconClose from '@/assets/svg/close.svg'
export default {
  name: 'BannerCookie',

  data () {
    return {
      cookiesOpen: false
    }
  },
  mounted () {
    let lang = 'EN'
    if (this.$route.params && this.$route.params.language) {
      lang = this.$route.params.language.toUpperCase()
    }

    if (this.$root.$language.current) {
      lang = this.$root.$language.current.toUpperCase()
    }

    if (lang === 'CN') {
      lang = 'EN'
    }
    const ckeditor = document.createElement('script')
    ckeditor.setAttribute('id', 'Cookiebot')
    console.log(lang)

    ckeditor.setAttribute('data-culture', lang)
    ckeditor.setAttribute('src', 'https://consent.cookiebot.com/uc.js')
    ckeditor.setAttribute('data-cbid', 'a8cda3ff-a187-4a65-8686-f61904bcd155')
    ckeditor.setAttribute('data-blockingmode', 'auto')

    if (this.$refs.cookiebot) {
      this.$refs.cookiebot.appendChild(ckeditor)
    }
  },

  watch: {
    '$route.params.language' () {
      const ckeditor = document.getElementById('Cookiebot')
      if (ckeditor) {
        let lang = this.$route.params.language.toUpperCase()
        if (lang === 'CN') {
          lang = 'ZH'
        }
        ckeditor.setAttribute('data-culture', lang)
      }
    }
  },
  components: {
    // CookieLaw,
    // MarkdownBlock,
    // IconClose
  }
}
</script>
<style lang="scss" scoped>
.Cookie--base {//stylelint-disable-line
  background-color: $c-black-85;
  color: $c-white;
  padding: 10px;
  padding-left: 10vw;
  padding-right: 10vw;
  text-align: left;
  transition: height 0.5s;

  @include breakpoint('sm-and-down') {
    padding-left: 10vw;
    padding-right: 15vw;
    text-align: left;
  }

  &.open {
    height: 50vh;
    overflow-y: auto;
  }

  ::v-deep p {
    font-size: 12px;
    color: $c-white;
    margin-bottom: 0 !important;
    letter-spacing: 0.5px;
    @include breakpoint('xs-only') {
      font-size: 10px;
    }
  }

  a {
    font-size: 12px;
    cursor: pointer;
    padding: 6px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
    float: right;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);

    @include breakpoint('sm-and-down') {
      right: 0;
    }

    &.button {
      text-align: right;
      display: block;
      color: $c-brown;
      font-size: 12px;
      letter-spacing: 1px;
      display: inline-block;
      text-transform: uppercase;
    }
  }

  .Cookie__button { //stylelint-disable-line
    background-color: $c-brown;
  }
}

.cookie-bot {
  font-size: 12px;
  display: none;
}
</style>
