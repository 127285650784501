<template>
  <div
    v-if="data && $route.name != 'Error'"
    class="floors-map-container"
    ref="container"
    :class="[
      {'open': (showMap)},
      {'hide-mobile': hideMobile}
    ]"
  >

    <v-container fluid fill-height class="map-container mt-n12">
      <v-row class="height-80 pa-6 pa-md-12"
        align="center"
        justify="center">
        <v-col align="left">

          <v-row v-if="showMap">
            <v-col cols="3">
              <v-row class="back height-100 py-6 py-xl-12 pointer" align="center" justify="center" >
                <ArrowLeft class="svg-fill-white inline-block"></ArrowLeft>
                <h5 class="uppercase inline-block pointer letter-spacing-20 ml-3" @click="showMap = false; $emit('onMapClose')">{{$root.allLanguages[$route.params.language].mapBackTour}}</h5>
              </v-row>
            </v-col>

            <v-col cols="6" align="center">
              <v-row class="height-100 py-6 py-xl-12" align="center" justify="center" v-if="$root.floors.length > 1">
                <h4
                  :class="{'current': index === currentFloorIndex}"
                  class="floor-title xl bodoni inline-block mx-3 mx-md-6 pointer"
                  v-for="(floor, index) in data"
                  @click="onFloorClick(index)"
                  :key="'floor'+index">{{floor[$route.params.language + 'Title']}}</h4>
                <!--<h4 class="xl bodoni uppercase underline">{{$root.allLanguages[$route.params.language].mapTitle}}</h4>-->
              </v-row>
            </v-col>

            <v-col cols="3" align="right">
              <CircularPercent :radius="viewPort.height < 768 ? 50 : 70" :percent="mapPercent"></CircularPercent>
            </v-col>
          </v-row>

          <v-row align="center" justify="center" v-show="showMap">
            <div class="floors-container" ref="floorsContainer">
              <div class="floor-parent"
                :class="[
                  (showMap ? 'floor-' + currentFloor._slug : ''),
                  {'single': $root.floors.length === 1}
                  ]"
                >
                <div class="floor" :key="index" v-for="(floor, index) in data">
                  <transition :name="'slide-' + slideDirection">
                    <div :key="'floor' + index" v-show="index === currentFloorIndex">
                      <img class="floor-map" :src="'/media/'+floor.map.id" />
                      <FloorMapDot
                        :floor="floor"
                        @onRoomClick="onRoomClick"></FloorMapDot>
                    </div>
                  </transition>
                </div>
              </div>
            </div>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-container fluid fill-height class="map-container-mobile" v-show="showMap">
      <ShrinkIcon @click="showMap = false; $emit('onMapClose')" class="svg-fill-white inline-block"></ShrinkIcon>

      <div class="floor-container" ref="floorScroller">
        <div class="floor-scroller">
          <h4
            :class="{'current': index === currentFloorIndex}"
            class="floor-title xl bodoni inline-block mx-3 pointer"
            v-for="(floor, index) in data"
            @click="onFloorClick(index)"
            :ref="'mobilebutton'+index"
            :key="'floor'+index">{{floor[$route.params.language + 'Title']}}</h4>
        </div>
      </div>

      <CircularPercent :radius="40" :percent="mapPercent"></CircularPercent>
      <transition name="fade">
        <div class="floor" ref="floormobile" :key="currentFloorIndex">
          <img class="floor-map" :src="'/media/'+currentFloor.map.id"/>
          <FloorMapDot :floor="currentFloor"
            @onRoomClick="onRoomClick"></FloorMapDot>
        </div>
      </transition>
    </v-container>

    <div v-if="!showMap" @click="showMap = true; $emit('onMapOpen')" class="open-map pointer">
      <img class="floor-map" :src="'/media/'+currentFloor.map.id"/>
      <UserViewCompass class="compass inline-block mr-3" :rotation="rotation"></UserViewCompass>
      <h6 class="letter-spacing-15 uppercase inline-block pointer mb-0 mr-3" @click="showMap = false">{{$root.allLanguages[$route.params.language].mapOpen}}</h6>
      <ExpandIcon class="mt-2 svg-fill-white inline-block float-right"></ExpandIcon>
    </div>

  </div>
</template>

<script>
import UserViewCompass from '../atoms/UserViewCompass.vue'
import CircularPercent from '../atoms/CircularPercent.vue'
import FloorMapDot from '../atoms/FloorMapDot.vue'
import ArrowLeft from '@/assets/svg/arrow-long-left.svg'
import ExpandIcon from '@/assets/svg/expand.svg'
import ShrinkIcon from '@/assets/svg/shrink.svg'
import { Viewport } from '@monogrid/vue-lib'

export default {
  name: 'FloorMap',
  mixins: [Viewport],

  components: {
    FloorMapDot, ArrowLeft, CircularPercent, ExpandIcon, UserViewCompass, ShrinkIcon
  },

  props: {
    rotation: {
      type: Number,
      default: 180
    }
  },

  data () {
    return {
      data: null,
      showMap: false,
      currentFloor: {},
      allRooms: 0,
      currentFloorIndex: 0,
      hideMobile: false,
      mobileButtonX: '0px',
      slideDirection: 'next'
    }
  },

  watch: {
    '$root.currentSettings' () {
      this.currentFloorIndex = 0
      this.updateFloor()
    },
    showMap (newValue, oldValue) {
      if (newValue === true) {
        for (let i = 0; i < this.data.length; i++) {
          const floor = this.data[i]
          if (floor._slug === this.$route.params.floor) {
            this.currentFloorIndex = i
            this.currentFloor = this.data[this.currentFloorIndex]
          }
        }
        setTimeout(() => {
          this.centerMapMobile()
          this.centerButtonMobile()
        }, 100)
      }
    }
  },

  mounted () {
    this.$events.on('onTourRestart', () => { this.showMap = false })
    this.$events.on('onOpenHotspot', () => { this.hideMobile = true })
    this.$events.on('openArtHotspot', () => { this.hideMobile = true })
    this.$events.on('closeArtHotspot', () => { this.hideMobile = false })
    this.$events.on('onCloseHotspot', () => { this.hideMobile = false })
    this.updateFloor()
  },

  computed: {
    mapPercent () {
      if (this.$root.currentSettings._slug.indexOf('salone') === -1) {
        return Math.min(100, Math.ceil((this.$root.visitedRooms.length / this.$root.allRooms) * 100))
      } else if (this.$root.currentSettings._slug.indexOf('salone') > -1) {
        return Math.min(100, Math.ceil((this.$root.visitedSaloneRooms.length / this.$root.allSaloneRooms) * 100))
      } else {
        return 0
      }
    }
  },

  methods: {
    updateFloor () {
      if (this.$root.floors) {
        console.log('floor map update', this.$root.floors)
        this.data = this.$root.floors
        this.currentFloor = this.data[this.currentFloorIndex]

        for (let i = 0; i < this.data.length; i++) {
          const floor = this.data[i]
          // this.allRooms += floor.rooms.length
          console.log(floor)
          if (floor._slug === this.$route.params.floor) {
            this.currentFloor = floor
            this.currentFloorIndex = i
          }
        }
        this.allRooms = this.$root.currentSettings._slug.indexOf('salone') === -1 ? this.$root.allRooms : this.$root.allSaloneRooms
        console.log(this.allRooms)
      }
    },
    onFloorClick (index) {
      this.slideDirection = index > this.currentFloorIndex ? 'next' : 'prev'
      this.currentFloorIndex = index
      this.currentFloor = this.data[index]
      this.centerButtonMobile()
      this.centerMapMobile()
    },

    centerButtonMobile () {
      let tx = 0
      for (let i = 0; i < this.currentFloorIndex; i++) {
        const element = this.$refs['mobilebutton' + i][0]
        tx += element.offsetLeft + element.clientWidth
      }

      this.$refs.floorScroller.scrollLeft = tx
      // const el = this.$refs['mobilebutton' + this.currentFloorIndex][0]
      // this.mobileButtonX = ((el.offsetLeft + (el.clientWidth / 2)) * -1) + 'px'
    },

    centerMapMobile () {
      if (this.viewPort.width < 600) {
        this.$refs.container.scrollLeft = 0
        for (let i = 0; i < this.currentFloor.rooms.length; i++) {
          const room = this.currentFloor.rooms[i]
          if (room.roomName._slug === this.$route.params.roomName) {
            const posX = room.positionInMap.split(',')[0] / 100
            if (this.$refs.floormobile) {
              const targetX = (posX * this.$refs.floormobile.clientWidth) - (this.viewPort.width / 2)
              this.$refs.container.scrollLeft = targetX
              // this.$refs.floormobile.scrollLeft = 300
              // this.$refs.container.scrollLeft = 300
            }
          }
        }
      }
    },

    floorClassIndex (index) {
      return 'floor-index-' + Math.abs(this.currentFloor - index)
    },

    onRoomClick (room) {
      this.showMap = false
    }
  }
}
</script>

<style lang="scss" scoped>

/* Floors map */
.floors-map-container {
  position: fixed;
  bottom: 120px;
  left: 30px;
  z-index: 2;
  transition: all 0.35s ease-in-out;
  overflow: hidden;
  background-color: $c-anthracite;
  width: 340px;
  height: 70px;

  @include breakpoint('xs-only') {
    z-index: 5;
    bottom: -20px;
    left: 0;
    width: 100vw;
    height: 90px;
    border-radius: 20px;
    overflow-x: scroll;
    overflow-y: hidden;
  }

  svg {
    width: 20px;
    height: 20px;
  }

  &.product {
    z-index: 3;
  }

  &.hide-mobile {
    @include breakpoint('xs-only') {
      display: none;
    }
  }

  &.open {
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    @include breakpoint('xs-only') {
      border-radius: 0;
    }
  }
  /* Close map btn */
  button.close {
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #ccc;
    color: #222;
    padding: 6px 12px;
  }
}

.back {
  svg {
    transition: transform 0.5s;
  }

  &:hover {
    svg {
      transform: translateX(-4px);
    }
  }
}

.floor-title {
  opacity: 0.3;
  position: relative;
  transition: opacity 0.5s;

  &::before {
    position: absolute;
    content: ' ';
    border-bottom: 1px solid $c-white;
    width: 0;
    height: 1px;
    bottom: -10px;
    transition: width 0.5s;
  }

  &:hover {
    opacity: 1;
  }

  &.current {
    opacity: 1;

    &::before {
      width: 100%;
    }
  }
}

.underline {
  position: absolute;

  &::before {
    position: absolute;
    content: ' ';
    border-bottom: 1px solid $c-white;
    width: 100%;
    height: 1px;
    bottom: -10px;
  }
}

.open-map {
  position: absolute;
  bottom: 0;
  padding: 15px;
  width: 340px;
  overflow: hidden;
  transition: all 0.4s ease-in-out;

  @include breakpoint('xs-only') {
    width: 100vw;
    bottom: 20px;
  }

  h6 {
    transition: margin 0.4s ease-in-out;
  }

  &:hover {
    background-color: $c-light-anthracite;

    h6 {
      margin-left: 4px;
    }
  }

  .floor-map {
    transform: translate(-50%, -50%) scale(0.7);
    opacity: 0.2;
    position: absolute;
  }
}

.map-container {
  max-width: 85vw;
  margin: auto;

  @include breakpoint('sm-and-down') {
    max-width: 95vw;
  }

  @include breakpoint('xs-only') {
    display: none;
  }

  .floor-parent {
    height: 45vh;

    @include breakpoint('lg-and-down') {
      height: 42vh;
    }

    @include breakpoint('md-and-down') {
      height: 41vh;
    }
  }

  .floor {
    position: absolute;
    transition: transform 0.5s ease-in-out;
    margin: auto;
    text-align: center;
    display: inline-block;
    transform: translateX(-50%);

    img {
      height: 45vh;

      @include breakpoint('lg-and-down') {
        height: 42vh;
      }

      @include breakpoint('md-and-down') {
        height: 41vh;
      }
    }
  }
}

.floor-parent.single {
  margin-top: -40px;
}

.floor-parent.single,
.single .floor img {
  height: 64vh;

  @include breakpoint('lg-and-down') {
    height: 61vh;
  }

  @include breakpoint('md-and-down') {
    height: 60vh;
  }
}

.map-container-mobile {
  display: none;
  margin: auto;
  position: absolute;

  @include breakpoint('xs-only') {
    display: block;
  }

  .floor {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  img {
    position: relative;
    height: 50vh;
    width: auto;
  }

  svg {
    position: fixed;
    top: 40px;
    right: 30px;
  }

  .floor-container {
    position: fixed;
    bottom: 40px;
    left: 0;
    width: 100%;
    height: 40px;
    overflow-x: scroll;
    transition: transform 0.25s;
  }

  .floor-scroller {
    position: absolute;
    display: flex;
    flex-wrap: nowrap;
    padding-left: 48px;
    padding-right: 48px;
  }

  h4 {
    white-space: nowrap;
  }

  ::v-deep .circular-percent {
    position: fixed;
    top: 20px;
    left: 20px;
  }
}

.slide-next-leave-active,
.slide-next-enter-active,
.slide-prev-leave-active,
.slide-prev-enter-active,

.slide-prev-enter {
  transform: translateX(-100vw) !important;
}

.slide-prev-leave-to {
  transform: translateX(100vw) !important;
}

.slide-next-enter {
  transform: translateX(100vw) !important;
}

.slide-next-leave-to {
  transform: translateX(-100vw) !important;
}
</style>
