<template>
  <div class="fullscreen d-block d-sm-none mobile-menu">
    <div class="mobile-menu-container">
      <div class="mobile-menu-content">
        <div class="pa-6">
          <h4 class="extra-light bodoni" v-for="(item, index) in this.$root.currentSettings.menu"
            :key="index"
            @click="$router.push({
              name: 'Room',
              params: { hotspotContent: item._slug }
            })">
            {{item[$route.params.language + 'HeaderTitle']}}</h4>
            <a v-if="$root.currentSettings.catalog" :download="$root.currentSettings.catalog.originalName"
              target="_blank"
              class="download uppercase"
              :href="'/media/'+$root.currentSettings.catalog.id">
              <div class="inner-link-container">
                <DownloadSvg/>
                <h5 class="ml-3 letter-spacing-10">{{ $root.allLanguages[$route.params.language].downloadCatalog }}</h5>
              </div>
            </a>
        </div>
        <hr>
        <div class="pa-6 uppercase">
          <h4
            @click="$emit('onInfoClick')"
          >
            <InfoSvg class="mr-4 svg-stroke-white"/>
            <p class="ma-0 letter-spacing-25">Virtual tour guide</p>
          </h4>
          <h4
            @click="$emit('onLeaveTour')"
          >
            <ExitSvg class="mr-4 svg-stroke-white"/>
            <p class="ma-0 letter-spacing-25">{{ $root.allLanguages[$route.params.language].headerLeaveTourCopy }}</p>
          </h4>
        </div>
        <hr>
        <div class="pa-6 letter-spacing-12">
          <a :href="$root.allLanguages[$route.params.language].footerVisitLink ? $root.allLanguages[$route.params.language].footerVisitLink : 'https://www.molteni.it'" target="_blank" class="d-block my-8">{{ $root.allLanguages[$route.params.language].footerVisitMolteni }}</a>
          <a @click="$events.emit('showCredit')" class="d-block my-8">{{ $root.allLanguages[$route.params.language].credits }}</a>
          <a :href="$root.allLanguages[$route.params.language].footerContactLink" target="_blank" class="d-block my-8">{{ $root.allLanguages[$route.params.language].footerContacts }}</a>
          <a :href="'/static/pdf/molteni-experience-cookies-policy-' + $route.params.language + '.pdf'" target="_blank" class="d-block my-8">{{ $root.allLanguages[$route.params.language].footerCookiePolicy }}</a>
          <a :href="'/static/pdf/molteni-experience-privacy-policy-2021-' + $route.params.language + '.pdf'" target="_blank" class="d-block my-8">{{ $root.allLanguages[$route.params.language].footerPrivacyPolicy }}</a>
        </div>
        <div class="d-flex align-center pa-6">
          <button
            v-for="(lang, i) in $root.allLanguages"
            :key="i"
            class="lang uppercase"
            :class="{'current': lang._slug === $route.params.language}"
            :disabled="lang._slug === $route.params.language"
            @click="$router.push({ params: { language: lang._slug } })"
          >
            {{ lang._slug }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DownloadSvg from '@/assets/svg/download.svg'

import InfoSvg from '@/assets/svg/info.svg'
import ExitSvg from '@/assets/svg/exit.svg'

export default {
  components: {
    InfoSvg,
    DownloadSvg,
    ExitSvg
  }
}
</script>

<style lang="scss" scoped>
  .mobile-menu {
    background-image: linear-gradient(340deg, #383838, #292929);
  }

  .mobile-menu-container {
    // background-color: $c-dark-grey;
    height: 100%;
    width: 100%;
  }

  .mobile-menu-content {
    margin: 80px 0;
    text-align: left;
    overflow-y: scroll;
    height: 100%;
    padding-bottom: 128px;
  }

  hr {
    opacity: 0.2;
  }

  h4 {
    display: flex;
    align-items: center;
    margin: 32px 0;
  }

  svg {
    cursor: pointer;
    display: inline-block;
    width: 24px;
    height: auto;

    @include breakpoint('xs-only') {
      width: 20px;
    }
  }

  a {
    color: $c-white;
    text-decoration: none;
  }

  .lang {
    width: 50%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000;

    &.current {
      background-color: #555;
    }
  }

  .download {
    margin: 0 auto;
    text-align: left;
    display: inline-flex;

    .inner-link-container {
      display: inline-flex;
      flex-direction: row;
      border: 1px solid $c-white;
      padding: 10px 15px;
      color: $c-white;
    }

    svg {
      path {
        fill: $c-white;
      }
    }
  }
</style>
