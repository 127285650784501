<template>
  <div class="hotspot-art" v-show="!isHidden">
    <div v-if="!isOpen" class="pointer button-open absolute-center">
      <IconPlus class="absolute-center svg-fill-brown"></IconPlus>
    </div>

    <transition name="fade">
      <div v-if="isOpen" class="art-content pa-12">
        <h4 class="bodoni mb-6">{{hotspot.hotspot.artist}} {{hotspot.hotspot[$route.params.language + 'Caption']}}</h4>
        <MarkdownBlock class="mb-3" :inline="false" linkify tag="h4" :text="hotspot[$route.params.language + 'WorkTitle'] ? hotspot[$route.params.language + 'WorkTitle'] : hotspot.hotspot.workTitle" />
        <MarkdownBlock :inline="false" linkify tag="div" :text="hotspot.hotspot[$route.params.language + 'WorkDescription']" />
      </div>
    </transition>

    <div v-if="isOpen" class="pointer button-close absolute-center">
      <IconClose class="absolute-center svg-fill-white"></IconClose>
    </div>

    <div class="round-button pointer" @mouseover="openArtHotspot" @mouseout="closeArtHotspot" @click="toggleArtHotspot"></div>
  </div>
</template>

<script>
import IconPlus from '@/assets/svg/plus.svg'
import IconClose from '@/assets/svg/close.svg'
import { MarkdownBlock } from '@monogrid/vue-lib'

export default {
  name: 'HotspotArtHtml',
  props: {
    hotspot: {
      type: Object,
      required: true
    }
  },

  mounted () {
    this.$events.on('closeArtHotspot', () => {
      this.isOpen = false
    })

    this.$events.on('onCloseHotspot', () => {
      this.isHidden = false
    })

    this.$events.on('onOpenHotspot', (hotspot) => {
      if (hotspot.hotspot.id !== this.hotspot.hotspot.id) {
        this.isHidden = true
      }
    })
  },

  methods: {
    openArtHotspot () {
      if (!this.$root.platform.mobile) {
        this.isOpen = true
        this.$events.emit('openArtHotspot', this.hotspot)
      }
    },
    closeArtHotspot () {
      if (!this.$root.platform.mobile) {
        this.isOpen = false
        this.$events.emit('closeArtHotspot')
      }
    },
    toggleArtHotspot () {
      if (this.isOpen === true) {
        this.isOpen = false
        this.$events.emit('closeArtHotspot')
      } else {
        this.isOpen = true
        this.$events.emit('openArtHotspot', this.hotspot)
      }
    }
  },
  components: {
    IconPlus, IconClose, MarkdownBlock
  },

  data () {
    return {
      isOpen: false,
      isHidden: false
    }
  }
}
</script>

<style lang="scss" scoped>
.round-button {
  width: 40px;
  height: 40px;
  display: block;
  position: relative;
}

.button-open {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: $c-white;
}

.button-close {
  width: 40px;
  height: 40px;
  border-radius: 20px;

  border: 1px solid $c-white;

  @include breakpoint('xs-only') {
    display: none;
  }
}

.art-content {
  position: absolute;
  left: -48px;
  top: 20px;
  width: 450px;
  background-color: $c-black-50;
  pointer-events: none;

  @include breakpoint('xs-only') {
    display: none;
  }
}
</style>
