var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isVisible && _vm.isTutorialStepsLoaded)?_c('div',{staticClass:"tutorial-container"},[_c('div',{staticClass:"dark-layer"}),_vm._v(" "),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"tutorial-content",attrs:{"cols":"12","sm":"8","md":"6"}},[_vm._l((_vm.$root.TutorialSteps),function(step,i){return _c('div',{key:("index-" + i),ref:"tutorialSteps",refInFor:true,staticClass:"tutorial-step mb-3 mb-sm-6",class:{
            'first active': i == 0,
            'last': i == _vm.$root.TutorialSteps.length - 1
          }},[_c('div',{staticClass:"icons-container",class:("group-" + i)},_vm._l((step.tutorialStepIcons),function(img,j){return _c('img',{key:j,class:[
                'img-child-' + j,
                {
                  'd-none d-sm-block': i == 1 && j == 0,
                  'd-block d-sm-none': i == 1 && j == 1
                }
              ],attrs:{"src":("/media/" + (img.id))}})}),0),_vm._v(" "),_c('h4',{staticClass:"bodoni xl uppercase mt-2 mt-sm-10"},[_vm._v(_vm._s(step[_vm.$route.params.language + 'TutorialStepTitle']))]),_vm._v(" "),_c('p',{staticClass:"my-3 my-md-6"},[_vm._v(_vm._s(step[_vm.$route.params.language + 'TutorialStepDescription']))])])}),_vm._v(" "),(_vm.currentStepIndex < _vm.$root.TutorialSteps.length - 1)?_c('OnboardingButton',{attrs:{"text":_vm.$root.allLanguages[_vm.$route.params.language].tutorialNextSlideBtnCopy},on:{"onClick":_vm.nextTutorialStep}}):_c('OnboardingButton',{attrs:{"text":_vm.$root.allLanguages[_vm.$route.params.language].tutorialLastStepBtnCopy},on:{"onClick":_vm.skipTutorial}}),_vm._v(" "),_c('div',{staticClass:"skip-tutorial uppercase mb-2 mb-sm-6 pb-sm-2 mt-6",on:{"click":_vm.skipTutorial}},[_c('span',{staticClass:"bold letter-spacing-20"},[_vm._v(_vm._s(_vm.$root.allLanguages[_vm.$route.params.language].tutorialSkipBtnCopy))])])],2),_vm._v(" "),(_vm.currentStepIndex < _vm.$root.TutorialSteps.length - 1)?_c('v-col',{staticClass:"tutorial-content-bottom pa-0",attrs:{"cols":"12","sm":"6","md":"4"}},[_c('div',{staticClass:"tutorial-progress-container pa-6"},[_c('div',{staticClass:"d-flex"},[_c('h4',{staticClass:"bodoni"},[_vm._v(_vm._s(_vm.currentStepIndex + 1))]),_vm._v(" "),_c('h4',{staticClass:"bodoni opacity-3"},[_vm._v("/"+_vm._s(_vm.$root.TutorialSteps.length - 1))])]),_vm._v(" "),_c('div',{staticClass:"progress-bar"},[_c('div',{staticClass:"progress",style:({ 'width': (_vm.currentStepIndex + 1) * 100 / (_vm.$root.TutorialSteps.length - 1) + '%' })})])])]):_vm._e()],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }