<template>
  <div
    v-show="!isHidden"
    v-if="hotspot || exit"
    class="hotspot-room absolute-center"
    :class="{'no-border': iconType === 'open' || iconType === 'close' || iconType === 'exit' || iconType === 'stairs'}"
    @click="onClick">
    <div class="pointer button-open absolute-center">
      <div>
        <IconStairs v-if="iconType === 'stairs'" class="absolute-center svg-fill-brown"></IconStairs>
        <IconExit v-if="iconType === 'exit'" class="absolute-center svg-fill-brown"></IconExit>
        <IconClose v-if="iconType === 'close'" class="absolute-center svg-fill-none svg-stroke-brown"></IconClose>
        <IconOpen v-if="iconType === 'open'" class="absolute-center svg-fill-none svg-stroke-brown"></IconOpen>
        <IconPov v-if="iconType === 'pov'" class="absolute-center svg-fill-brown"></IconPov>
        <IconArrowRight  v-if="iconType === 'right'" class="absolute-center svg-fill-brown"></IconArrowRight>
        <IconArrowLeft v-if="iconType === 'left'" class="absolute-center svg-fill-brown"></IconArrowLeft>
        <IconArrowUp  v-if="iconType === '' || iconType === 'up'" class="absolute-center svg-fill-brown"></IconArrowUp>
        <IconArrowDown v-if="iconType === 'down'" class="absolute-center svg-fill-brown"></IconArrowDown>
      </div>
    </div>
  </div>
</template>

<script>
import IconStairs from '@/assets/svg/stairs.svg'
import IconExit from '@/assets/svg/exit.svg'
import IconClose from '@/assets/svg/close-door.svg'
import IconOpen from '@/assets/svg/open.svg'
import IconPov from '@/assets/svg/pov.svg'
import IconArrowLeft from '@/assets/svg/arrow-left.svg'
import IconArrowRight from '@/assets/svg/arrow-right.svg'
import IconArrowUp from '@/assets/svg/arrow-up.svg'
import IconArrowDown from '@/assets/svg/arrow-down.svg'

export default {
  name: 'HotspotArtHtml',
  props: {
    hotspot: {
      type: Object,
      required: false
    },
    exit: {
      type: Boolean
    }
  },

  methods: {
    onClick () {
      if (this.exit) {
        this.$events.emit('onTourExit')
      } else {
        if (this.iconType === 'open' || this.iconType === 'close') {
          this.$events.emit('keepCameraSteady')
        } else {
          this.$events.emit('useCameraInitialPoint')
        }

        const floor = this.$root.getFloor(this.hotspot.room._slug) || this.$route.params.floor
        this.$router.push(
          {
            name: 'Room',
            params: {
              floor: floor,
              roomName: this.hotspot.room._slug
            }
          }
        )
      }
    }
  },

  data () {
    return {
      isHidden: false
    }
  },
  mounted () {
    this.$events.on('onCloseHotspot', () => {
      this.isHidden = false
    })

    this.$events.on('onOpenHotspot', (hotspot) => {
      this.isHidden = true
    })
  },

  components: {
    IconArrowRight, IconArrowLeft, IconArrowUp, IconArrowDown, IconPov, IconClose, IconOpen, IconExit, IconStairs
  },

  computed: {
    iconType () {
      if (this.exit) {
        return 'exit'
      } else {
        const pos = this.hotspot.position.split(',')
        return pos[3] ? pos[3] : ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.hotspot-room {
  width: 72px;
  height: 72px;

  border-radius: 36px;
  border: 1px solid $c-white;

  &.no-border {
    border-style: none;
  }

  svg {
    width: 22px;
    height: 22px;
    transition: transform 0.25s;
  }

  &:hover {
    svg {
      transform: translate(-50%, -50%) scale(1.2);
    }
  }
}

.button-open {
  width: 44px;
  height: 44px;
  border-radius: 25px;
  background-color: $c-white;
}
</style>
