<template>
  <div class="hotspot-mobile" v-if="hotspot">
    <v-container class="mt-12 pt-12 px-6 product-container">
      <v-row
        v-for="(content, index) in hotspotContent"
        :key="index"
        class="button-content"
        @click.stop="onClick(content._slug)"
        align="center"
        >

        <v-col cols="3">
          <div class="circle">
            <img v-if="content.category && content.category.iconBlack" :src="'/media/' + content.category.iconBlack.id">
          </div>
        </v-col>
        <v-col cols="9" class="pl-6 button-content-title" align="left">
          <h2 class="uppercase bodoni mb-1">{{content.title}}</h2>
          <h5 v-if="content.category" class="uppercase mb-2">{{ content.category[$route.params.language + 'Category'] }}</h5>
          <h5 class="letter-spacing-12" v-if="content.collection">{{ content.collection[$route.params.language + 'Title'] }}</h5>
        </v-col>

      </v-row>
    </v-container>

    <div class="top"></div>

    <div class="close" @click.stop="$events.emit('onCloseHotspot', false, null)">
      <div class="close-circle">
        <div class="inner-circle">
          <CloseSvg/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CloseSvg from '@/assets/svg/close.svg'

export default {
  name: 'HotspotHtmlMobile',

  components: {
    CloseSvg
    // PlaySvg
  },

  data () {
    return {
      hotspot: null
    }
  },

  mounted () {
    this.$events.on('onOpenHotspot', (hotspot) => {
      this.hotspot = hotspot
    })

    this.$events.on('onCloseHotspot', () => {
      this.hotspot = null
    })
  },

  computed: {
    hotspotContent () {
      let hsContent = this.hotspot.hotspot.hotspotContent

      if (this.hotspot.hotspot.hotspotContentCategory && this.hotspot.hotspot.hotspotContentCategory.length > 1) {
        hsContent = []

        for (let i = 0; i < this.hotspot.hotspot.hotspotContentCategory.length; i++) {
          const element = this.hotspot.hotspot.hotspotContentCategory[i]
          if (element.category) {
            element.hotspot.category = element.category
          }
          hsContent.push(element.hotspot)
        }
      }
      return hsContent
    }
  },

  methods: {
    onClick (slug) {
      this.$root.currentHotspot = this.hotspot
      this.$router.push({
        name: 'Room',
        params: { hotspotContent: slug }
      })

      this.$emit('onCloseHotspot', false, null)
    }
  }
}
</script>

<style lang="scss" scoped>

.hotspot-mobile {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100%;
  padding-bottom: 12px;
  background-color: $c-black-60;
  overflow-y: scroll;
  flex-direction: column;
  justify-content: flex-end;
  @include breakpoint('xs-only') {
    display: flex;
  }

  &.solid {
    .top {
      display: block;
    }

    .close {
      background: linear-gradient(0deg, rgba(55, 53, 52, 1) 0%, rgba(55, 53, 52, 1) 73%, rgba(55, 53, 52, 0) 100%);
    }

    .product-container {
      bottom: auto;
      top: 0;
      padding-bottom: 180px;
    }
  }
}

.button-content {
  cursor: pointer;
}

.product-container {
  // position: absolute;
  // bottom: 160px;
}

.iphone .solid .product-container {
  bottom: auto !important;
  top: 0;
  padding-bottom: 220px;
}

.iphone .product-container {
  bottom: 240px !important;
}

.circle {
  width: 64px;
  height: 64px;
  border-radius: 32px;
  background-color: $c-white;
  padding: 20px;

  .icon {
    width: 40px;
    height: 40px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }
}

.top {
  position: fixed;
  top: 0;
  display: none;
  background: linear-gradient(0deg, rgba(55, 53, 52, 0) 0%, rgba(55, 53, 52, 1) 23%, rgba(55, 53, 52, 1) 100%);
  width: 100vw;
  height: 90px;
}

.close {
  // position: fixed;
  // bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  // height: 160px;

  .close-circle {
    margin-top: 25px;
    width: 110px;
    height: 110px;
    border: solid $c-white-30 25px;
    border-radius: 63px;
    padding: 10px;
  }

  .inner-circle {
    width: 40px;
    height: 40px;
    border: solid $c-white-30 1px;
    border-radius: 63px;
    padding: 9px;
  }

  svg {
    fill: $c-white;
    stroke: $c-white;
    width: 20px;
    height: auto;
  }

  path {
    fill: $c-white;
    stroke: $c-white;
  }
}
</style>
