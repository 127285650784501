<template>
  <div class="credits">
    <div class="bg"></div>

    <transition name="slide" appear mode="out-in">
      <v-container fluid class="hotspotContainer pa-0">
        <v-row class="mx-0">
          <v-col cols="12" sm="9" offset-sm="1" md="10" lg="9" offset-md="2" class="px-6 hotspotContent">
            <div class="pointer close" @click.prevent="$events.emit('hideCredit')">
              <CloseSvg class="close-svg absolute-center"/>
              <ArrowLeft class="arrow-svg absolute-center"></ArrowLeft>
            </div>

            <v-row class="my-0 pa-0 px-md-16 pb-md-12">
              <v-col>
                <h3 class="bodoni">{{$root.allLanguages[$route.params.language].thanksTo}}</h3>
                <div class="mt-6 title-line"></div>
              </v-col>
            </v-row>
            <v-row class="my-0 pa-0 px-md-16 pb-md-12">
              <v-col cols="12" md="6" v-for="(credit, i) in $root.allLanguages[$route.params.language].creditList" :key="i">
                <p class="uppercase mb-0">{{credit}}</p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </transition>
  </div>
</template>

<script>
import CloseSvg from '@/assets/svg/close.svg'
import ArrowLeft from '@/assets/svg/arrow-long-left.svg'

export default {
  name: 'Credits',

  components: {
    CloseSvg,
    ArrowLeft
  }
}
</script>

<style lang="scss" scoped>
.credits {
  position: relative;
  z-index: 6;
}

.title-line {
  width: 120px;
  height: 1px;
  border-top: 1px solid $c-brown;
}

.bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 0;
  background-color: $c-black-60;
}

.hotspotContainer {
  min-height: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

.hotspotContent {
  height: auto;
  background-color: $c-white;
  color: $c-black;
  margin: 100px auto;
  padding: 0;
  overflow: visible;

  @include breakpoint('xs-only') {
    margin: 0;
    padding-bottom: 120px;
  }
}

.close {
  width: 88px;
  height: 88px;
  background-color: $c-white;
  position: sticky;
  top: 0;
  transform: translate(-87px, 0);

  @include breakpoint('xs-only') {
    position: relative;
    top: 0;
    margin-top: 0;
    width: 50px;
    height: 50px;
    transform: translateX(0);
  }

  svg {
    transition: transform 0.25s;
  }

  &:hover {
    svg {
      transform: translate(-50%, -50%) scale(1.2);
    }
  }

  .close-svg {
    @include breakpoint('xs-only') {
      display: none;
    }
  }

  .arrow-svg {
    display: none;
    @include breakpoint('xs-only') {
      display: block;
      margin-left: -12px;
      margin-top: 8px;
      width: 28px;
      height: 28px;
    }
  }
}

.slide-leave-active,
.slide-enter-active {
  transition: top 1s $ease-out-quint;
}

.slide-enter,
.slide-leave-to {
  top: 100vh !important;
}
</style>
