<template>
  <div class="related-hotspot-container" v-if="$root.currentHotspot && $root.currentHotspot.hotspotContent">
    <div class="related-hotspot inline-block pointer"
      v-for="(content, index) in $root.currentHotspot.hotspotContent"
      :key="index"
      :class="{'odd': index%2 === 0}">
      <div class="pa-8 hotspot-parent" v-if="content._slug !== $route.params.hotspotContent" @click.stop="openProduct(content)">
        <h5 class="uppercase inline-block letter-spacing-12 mr-4">{{content[$route.params.language + 'ProductTitle'] ? content[$route.params.language + 'ProductTitle'] : content.title}}</h5>
        <img class="inline-block mr-3 icon" v-if="content.category && content.category.iconWhite" :src="'/media/' + content.category.iconWhite.id">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HotspotRelated',

  methods: {
    openProduct (content) {
      this.$router.push({
        name: 'Room',
        params: { hotspotContent: content._slug }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.related-hotspot-container {
  position: absolute;
  transform-origin: top left;
  transform: rotate(-90deg) translate(calc(-100% - 88px), 0);
  white-space: nowrap;

  @include breakpoint('sm-and-down') {
    display: none;
  }
}

.hotspot-parent {
  position: relative;
  display: inline-flex;
  height: 88px;

  h5 {
    width: max-content;
  }

  .icon {
    transform-origin: center center;
    transform: rotate(90deg);
    width: 24px;
    height: 24px;
  }
}

.related-hotspot {
  position: relative;
  height: 88px;
  color: $c-white;
  background-color: $c-dark-grey;

  &.odd {
    background-color: $c-anthracite;
  }
}
</style>
