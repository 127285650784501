<template>
  <div class="transparent-black-85 fullscreen d-none d-sm-flex desktop-menu-container">
    <div class="desktop-menu-content">
      <transition-group name="fade" appear>
        <h1 class="bodoni light pa-6" v-for="(item, index) in this.$root.currentSettings.menu"
          :key="'itemmenu'+index"
          @click="openMenuPage(item)"
          :style="{transitionDelay: (index * 0.1) + 's'}"
        >
          {{item[$route.params.language + 'HeaderTitle']}}
        </h1>
      </transition-group>
      <a v-if="$root.currentSettings.catalog" :download="$root.currentSettings.catalog.originalName"
        target="_blank"
        class="mt-3 mt-md-6 mt-lg-12 download uppercase"
        :href="'/media/'+$root.currentSettings.catalog.id">
        <div class="inner-link-container">
          <DownloadSvg/>
          <h5 class="ml-3 letter-spacing-10">{{ $root.allLanguages[$route.params.language].downloadCatalog }}</h5>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import DownloadSvg from '@/assets/svg/download.svg'

export default {
  components: {
    DownloadSvg
  },

  methods: {
    openMenuPage (room) {
      this.$root.currentHotspot = null
      this.$emit('onPageChange')
      this.$root.roomClass = ''
      this.$router.push({
        name: 'Room',
        params: { hotspotContent: room._slug }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.desktop-menu-container {
  align-items: center;
  justify-content: center;
  z-index: 4;
  text-align: center;
}

.desktop-menu-content {
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:hover h1:not(:hover) {
    opacity: 0.5;
  }

  h1 {
    display: flex;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;
    transition: all 0.4s ease-in-out;

    &::before,
    &::after {
      content: ' ';
      width: 0;
      height: 1px;
      margin: 0 10px;
      background-color: $c-white;
      transition: width 0.5s;
    }

    &:hover {
      opacity: 1;

      &::before,
      &::after {
        content: ' ';
        width: 30px;
      }
    }
  }
}

.download {
  margin: 0 auto;
  text-align: center;
  display: inline-flex;

  h5 {
    line-height: 1.6;
  }

  .inner-link-container {
    display: inline-flex;
    flex-direction: row;
    border: 1px solid $c-white;
    padding: 15px 25px;
    color: $c-white;
  }

  svg {
    path {
      fill: $c-white;
    }
  }
}
</style>
