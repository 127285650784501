<template>
  <v-container fluid class="content-video px-0 pt-0"
    :class="containerClass">
    <div class="video-container" v-show="showPlayer">

      <vue-plyr :class="videoClass" @ended="onVideoFinished"
        v-if="content.videoFile" class="player" ref="plyr">
        <video :src="'/media/' + content.videoFile.id"></video>
      </vue-plyr>

      <vue-plyr :class="videoClass" @ended="onVideoFinished"
        v-if="content.videoLink && (content.videoLink.indexOf('youtube.com') > -1 || content.videoLink.indexOf('youtu.be') > -1)"
          class="player" ref="plyr">

        <div data-plyr-provider="youtube" data-target="video.player" :data-plyr-embed-id="getYoutubeId(content.videoLink)"></div>
      </vue-plyr>

      <vue-plyr :class="videoClass" @ended="onVideoFinished"
        v-if="content.videoLink && content.videoLink.indexOf('vimeo.com') > -1"
        class="player" ref="plyr">

        <div data-plyr-provider="vimeo" data-target="video.player" :data-plyr-embed-id="getVimeoID(content.videoLink)"></div>
      </vue-plyr>
    </div>

    <div class="blocker" v-if="!isOpen && !hide"></div>
    <div class="play-button inline-block pointer" v-if="!isOpen" @click="openPlayer"
      :class="[
        {'technical': technical},
        {'vimeo': content.videoLink && content.videoLink.indexOf('vimeo.com') > -1},
        'button-' + $root.roomClass]">
      <IconPlay class="absolute-center" :class="technical ? 'svg-stroke-white': 'svg-stroke-brown'"></IconPlay>
      <p v-if="technical" class="uppercase letter-spacing-12">{{$root.allLanguages[$route.params.language].technicalVideoCopy}}</p>
    </div>

    <div class="close-button inline-block pointer" v-if="isOpen || full" @click="closePlayer">
      <IconClose class="absolute-center svg-fill-white"></IconClose>
    </div>
  </v-container>
</template>

<script>
import IconPlay from '@/assets/svg/play.svg'
import IconClose from '@/assets/svg/close.svg'
import VuePlyr from 'vue-plyr'
import Vue from 'vue'
import { Viewport } from '@monogrid/vue-lib'

// The second argument is optional and sets the default config values for every player.
Vue.use(VuePlyr, {
  plyr: {
    fullscreen: { enabled: false },
    hideControls: true,
    playsinline: true,
    vimeo: {
      dnt: false
    }
  },
  emit: ['ended']
})

export default {
  name: 'ContentVideo',
  mixins: [Viewport],

  data () {
    return {
      isOpen: false,
      iphoneHide: false
    }
  },

  mounted () {
    this.$events.on('videoOpen', (vdl) => {
      if (this.$root.platform.mobile && this.content.videoLink !== vdl) {
        this.iphoneHide = true
      }
    })
    this.$events.on('videoClose', () => { this.iphoneHide = false })
  },

  components: {
    IconPlay, IconClose
  },

  computed: {
    containerClass () {
      let cls = ('video-' + this.$root.roomClass)
      cls += this.iphoneHide ? ' display-none' : ''
      cls += this.isOpen ? ' overlay' : ''
      cls += this.designer ? ' designer' : ''
      cls += this.technical ? ' technical' : ''
      cls += this.full ? ' full' : ''
      cls += this.hide ? ' hide' : ''
      cls += this.viewPort.width > this.viewPort.height ? ' landscape' : ''
      cls += this.$root.platform.mobile ? ' mobile' : ''

      return cls
    },
    videoClass () {
      let cls = ''
      if (this.isOpen && (this.viewPort.width / this.viewPort.height) > (16 / 9)) {
        cls += 'vertical '
      }
      if (this.isOpen) {
        cls += 'open'
      }

      return cls
    },

    showPlayer () {
      if (this.hide && this.isOpen) {
        return true
      } else if (this.hide && !this.isOpen) {
        return false
      } else {
        return true
      }
    }
  },
  methods: {
    onVideoFinished () {
      console.log('onVideoFinished')
      this.closePlayer()
    },

    openPlayer () {
      this.isOpen = true
      this.$refs.plyr.player.play()
      this.$events.emit('audioOff')
      this.$events.emit('videoOpen', this.content.videoLink)
    },

    closePlayer () {
      this.$events.emit('audioOn')
      this.$events.emit('videoClose')
      this.isOpen = false
      this.$refs.plyr.player.stop()
      if (this.full) {
        this.$events.emit('openMenu')
        this.$router.push(
          {
            name: 'Room',
            params: {}
          }
        )
      }
    },
    getYoutubeId (url) {
      const match = url.match(/^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/) // eslint-disable-line
      return (match && match[7].length === 11) ? match[7] : false
    },

    getVimeoID (url) {
      const match = url.match(/https?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|video\/|)(\d+)(?:$|\/|\?)/) // eslint-disable-line
      // console.log(match)
      return (match && match[3]) ? match[3] : false
    }
  },

  props: {
    hide: {
      type: Boolean,
      default: false
    },

    technical: {
      type: Boolean,
      default: false
    },

    designer: {
      type: Boolean,
      default: false
    },

    full: {
      type: Boolean,
      default: false
    },

    content: {
      type: Object
    }
  }
}
</script>

<style lang="scss" scoped>
.content-video {
  width: 100%;
  margin: auto;
  text-align: center;
  position: relative;
  z-index: 1;
  user-select: none;

  @include breakpoint('xs-only') {
    padding-bottom: 0;
  }

  &::before {
    content: '';
    width: 120px;
    height: 1px;
    border-bottom: 1px solid $c-brown;
    position: absolute;
    left: 0;
    top: 60px;
    transform: translateX(-50%);
    z-index: 3;

    @include breakpoint('xs-only') {
      width: 60px;
      top: 30px;
      left: 10px;
      transform-origin: top right;
      transform: translate(-50%, 50%) rotate(90deg);
    }
  }

  &.hide {
    &::before {
      display: none;
    }
  }

  &.technical {
    min-height: 30vh;
    margin-top: 36px;
    margin-bottom: 36px;
  }

  &.overlay.designer {
    margin-top: 0;
  }

  &.overlay.landscape.mobile {
    height: 100%;
  }

  &.designer {
    margin-top: -44px;

    @include breakpoint('xs-only') {
      margin-top: 0;
    }

    .video-container {
      width: 80%;
      height: 100%;
      margin-left: 10%;

      @include breakpoint('xs-only') {
        width: 100%;
        height: 100%;
        margin: 0;
      }
    }

    .play-button {
      margin-top: -22px;

      @include breakpoint('xs-only') {
        margin-top: 0;
      }
    }
  }

  &.full,
  &.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    padding: 0;
    z-index: 3;
    background-color: $c-black-90;

    &::before {
      display: none;
    }
  }

  &.full {
    .play-button {
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .video-container {
      padding: 0;
      margin: 0;
      position: absolute;
    }

    .player {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100vw;
    }
  }
}

.ios .video-container {
  opacity: 0.99;
}

.video-container {
  padding: 0;
  width: 100%;
  height: 100%;

  @include breakpoint('sm-and-down') {
    padding: 0;
    margin-top: 48px;
    margin-bottom: 48px;
  }

  @include breakpoint('xs-only') {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.close-button {
  position: fixed;
  top: 40px;
  right: 40px;
  background-color: $c-black-60;
  pointer-events: all;
  z-index: 2;
  transition: transform 0.25s;

  svg {
    width: 40px;
    height: 40px;
  }

  &:hover {
    transform: scale(1.2);
  }
}

.blocker {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 1;
}

.hide {
  .play-button {
    background-color: transparent;

    @include breakpoint('xs-only') {
      width: 160px;
      height: 160px;
    }

    &:hover {
      background-color: $c-brown;

      path {
        fill: $c-white;
        stroke: $c-white;
      }
    }
  }
}

.play-button {
  z-index: 2;
  width: 160px;
  height: 160px;
  border-radius: 80px;
  position: absolute;
  border-style: none;
  top: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.5s;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: $c-brown;

  @include breakpoint('xs-only') {
    width: 80px;
    height: 80px;
  }

  &:hover {
    background-color: $c-white;

    path {
      fill: $c-brown;
      stroke: $c-brown;
    }
  }

  path {
    stroke: $c-white;
  }

  &.technical {
    border: 1px solid $c-dark-grey;
    color: $c-dark-grey;

    &:hover {
      border-style: none;
    }

    path {
      stroke: $c-black;
    }
  }

  p {
    position: absolute;
    top: 50%;
    left: calc(50% - 18px);
    white-space: nowrap;
    transform: translate(-100%, -50%);
    margin-bottom: 0;
    line-height: 29px;
    vertical-align: middle;
  }
}

.player.open {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
}

::v-deep .plyr.plyr--stopped .plyr__control,
::v-deep .plyr.plyr--stopped .plyr__controls { display: none; }

::v-deep .plyr.plyr--stopped .vp-controls-wrapper { display: none; }

.vertical ::v-deep .plyr {
  height: 100vh;
  width: calc((100vh/9)*16);
  left: 50%;
  transform: translate(-50%, 0);
}

::v-deep .plyr {
  &.open {
    width: 100vw;
    height: calc((100vw/16)*9);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

// COLOR RULES
@each $name, $color in $group-colors {
  .video-#{$name} {
    &::before {
      border-bottom: 1px solid #{$color} !important;
    }
  }

  .hide {
    .button-#{$name} {
      &:hover {
        background-color: #{$color};

        path {
          stroke: $c-white !important;
        }
      }
    }
  }

  .button-#{$name} {
    background-color: #{$color};

    &:hover {
      path {
        stroke: #{$color} !important;
        fill: #{$color} !important;
      }
    }

    &.technical {
      border: 1px solid #{$color};

      &:hover {
        border-style: none;
      }

      path {
        stroke: #{$color};
      }
    }
  }
}
</style>
