<template>
  <div class="circular-percent">
    <svg :width="(radius + 5) * 2" :height="(radius + 5) * 2" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <circle class="bg" :r="radius" :cx="radius + 5" :cy="radius + 5" fill="transparent" :stroke-dasharray="strokeDasharray"></circle>
      <circle ref="circle" :r="radius" :cx="radius + 5" :cy="radius + 5" fill="transparent" :stroke-dasharray="strokeDasharray" :stroke-dashoffset="strokeOffset"></circle>
    </svg>

    <div class="info absolute-center text-center">
      <h4 class="xl bodoni inline-block">{{percent}}%</h4>
      <span class="uppercase inline-block letter-spacing-15">{{$root.allLanguages[$route.params.language].mapVisited}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CirclePercent',
  props: {
    percent: {
      type: Number
    },
    radius: {
      type: Number
    }
  },

  computed: {
    strokeDasharray () {
      return Math.PI * (this.radius * 2)
    },

    strokeOffset () {
      const pct = ((100 - this.percent) / 100) * this.strokeDasharray

      return pct
    }
  }
}
</script>

<style lang="scss" scoped>
.circular-percent {
  position: relative;
  display: inline-block;
}

svg {
  transform: rotate(-90deg);
}

circle {
  stroke: $c-brown;
  stroke-width: 5px;

  &.bg {
    stroke-width: 1px;
    stroke: $c-dark-grey;
  }
}

.info span {
  @include breakpoint('xs-only') {
    position: absolute;
    top: 12px;
    left: 75px;
  }
}
</style>
