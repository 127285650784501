<template>
  <div class="lang-switcher">
    <div
      class="current-lang uppercase width-100 height-100 d-flex align-center justify-center bold letter-spacing-15"
      :class="{'open': open}"
    >
      {{ $route.params.language }}
      <arrowDown
        @click="open = !open"
        class="svg-fill-white arrow"
        :class="{'flip': open}"
      />
    </div>
    <div class="other-lang" :class="{'open': open}">
      <div
        v-for="lang in availableLanguages"
        :key="lang"
        class="uppercase option d-flex align-center justify-center"
        @click="switchCurrentLang(lang)"
      >
        {{ lang }}
      </div>
    </div>
  </div>
</template>

<script>
import arrowDown from '@/assets/svg/arrow-down.svg'

export default {
  components: {
    arrowDown
  },

  data () {
    return {
      open: false
    }
  },
  computed: {
    availableLanguages () {
      const allLang = []
      for (const lang in this.$root.allLanguages) {
        const element = this.$root.allLanguages[lang]
        if (element._slug !== this.$route.params.language) {
          allLang.push(element._slug)
        }
      }
      return allLang
    }
  },
  methods: {

    switchCurrentLang (currentLang) {
      this.open = !this.open
      this.$router.push({ params: { language: currentLang } })
    }
  }
}
</script>

<style lang="scss" scoped>

.lang-switcher {
  width: 80px;
  height: 80px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $c-white;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1.5px;
}

.current-lang.open {
  background-color: $c-black-30;

}

.arrow {
  width: 20px;
  margin-left: 10px;
  cursor: pointer;
  transform-origin: center;
  transition: transform 0.5s;
  transform: rotate(180deg);

  &.flip {
    transform: scale(1.2) rotate(0deg);
  }
}

.other-lang {
  background-color: $c-black-30;
  position: absolute;
  right: 0;
  bottom: 80px;
  overflow: hidden;
  width: 80px;
  height: 0;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: height 0.4s ease-in-out;

  &.open {
    height: 160px;
  }

  .option {
    width: 80px;
    height: 80px;
    display: block;

    &:hover {
      background-color: $c-black-60;
    }
  }
}
</style>
