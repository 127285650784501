import packageConfig from '../package.json'

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import Platform from '@monogrid/js-utils/lib/Platform'

import router from './plugins/router.js'
import './plugins/analytics.js'
import './plugins/locale.js'
import VueMeta from 'vue-meta'
import VueEvents from 'vue-event-handler'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Preloader from './preloader'
import onlineApolloProvider from './services/CMS'
import DragDirective from './directives/drag'
// import CMSOffline from './services/CMSOffline'
// import cmsOfflineCatalog from './services/CMSOfflineCatalog.json'
// import cmsOfflineSchema from './services/CMSOfflineSchema.graphql'

// usage: https://vuetifyjs.com/
import vuetify from './plugins/vuetify'
// usage: https://github.com/declandewet/vue-meta
Vue.use(VueMeta)
// usage: https://github.com/sandeepk01/vue-event-handler
Vue.use(VueEvents)
// usage: https://github.com/imcvampire/vue-axios
Vue.use(VueAxios, axios)
Vue.directive('drag', DragDirective)

//
//
// Initialization of the preloader app (placed on the HTML)
//
//
const preloaderInstance = new Vue(Preloader)

// management of languages
router.beforeEach((to, from, next) => {
  // set current language on change route
  preloaderInstance.visible = true
  if (packageConfig.multilanguage) {
    if (to.params.language) {
      Vue.config.language = to.params.language || 'en'
    }
  } else {
    Vue.config.language = 'en'
  }
  next()
})
router.afterEach((to, from) => {
  preloaderInstance.visible = false
})

//
//
// Initialization of the app (placed on the HTML)
//
//
/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  vuetify,
  apolloProvider: onlineApolloProvider,
  // apolloProvider: process.env.SERVICE_STAGE !== 'production' ? onlineApolloProvider : new CMSOffline(cmsOfflineCatalog, cmsOfflineSchema).getProvider(),
  data () {
    return {
      preloader: preloaderInstance,
      userType: '',
      collectionSettings: null,
      saloneSettings: null,
      currentSettings: null,
      allFloors: null,
      floors: null,
      arraySettings: null,
      userEmail: '',
      donottrack: false,
      usVersion: false,
      userCountryCode: null,
      visitedRooms: [],
      visitedSaloneRooms: [],
      process: {
        env: process.env
      }
    }
  },

  methods: {
    updateFloorBySetting () {
      if (this.currentSettings.floors && this.currentSettings.floors.length > 0) {
        this.floors = []
        for (let n = 0; n < this.currentSettings.floors.length; n++) {
          const floor = this.currentSettings.floors[n]
          const newfloor = this.allFloors.find(({ _slug }) => _slug === floor._slug)

          this.floors.push(newfloor)
        }
      }
    },
    getFloor (slug) {
      let currentfloor = null
      if (this.floors) {
        for (let i = 0; i < this.floors.length; i++) {
          const floor = this.floors[i]

          for (let n = 0; n < floor.rooms.length; n++) {
            const room = floor.rooms[n]
            if (room.roomName._slug === slug && room.positionInMap.indexOf('stairs') === -1) {
              currentfloor = floor._slug
            }
          }
        }
      }
      console.log('root getFloor', currentfloor)
      return currentfloor
    }
  },
  computed: {
    platform () {
      return Platform
    },
    multilanguage () {
      return packageConfig.multilanguage
    }
  },
  // this block allows passing props to the main App
  // these props are set by html-webpack-plugin in the html
  // and the values are taken from git-revision-webpack-plugin
  render (createElement) {
    return createElement(App, {
      props: {
        preloader: preloaderInstance
      }
    })
  }
})
