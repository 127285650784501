<template>
  <v-col cols="12" align="left" class="exit-footer">
    <v-row>

      <v-col cols="8" offset="0" sm="3" offset-sm="0" class="my-md-6">
        <div
          @click="onVisitClick"
          :class="{'clicked': isLinkClicked}"
          class="outer-link-container"
        >
          <div class="inner-link-container">
            <LinkSvg/>
            <h5 class="letter-spacing-10">{{ $root.allLanguages[$route.params.language].exitPageLinkVisitCopy }}</h5>
          </div>
        </div>
      </v-col>

      <v-col cols="8" offset="0" sm="3" offset-sm="0" class="my-md-6">
        <div
          :class="{'clicked': isLinkClicked}"
          class="outer-link-container"
        >
          <a :download="$root.currentSettings.catalog.originalName"
            target="_blank"
            :href="'/media/'+$root.currentSettings.catalog.id">
            <div class="inner-link-container">
              <DownloadSvg/>
              <h5 class="letter-spacing-10">{{ $root.allLanguages[$route.params.language].downloadCatalog }}</h5>
            </div>
          </a>
        </div>
      </v-col>

      <v-col cols="8" offset="0" sm="3" offset-sm="0" class="my-md-6">
        <div
          @click="onShareClick"
          :class="{'clicked': isContactClicked}"
          class="outer-link-container"
        >
          <div class="inner-link-container">
            <ShareSvg/>
            <h5 class="letter-spacing-10">{{ $root.allLanguages[$route.params.language].exitPageLinkShareCopy }}</h5>
          </div>
        </div>
      </v-col>

      <v-col cols="8" offset="0" sm="3" offset-sm="0" class="my-md-6">
        <div
          @click="onContactClick"
          :class="{'clicked': isContactClicked}"
          class="outer-link-container"
        >
          <div class="inner-link-container">
            <MailSvg/>
            <h5 class="letter-spacing-10">{{ $root.allLanguages[$route.params.language].exitPageLinkContactCopy }}</h5>
          </div>
        </div>
      </v-col>

    </v-row>
  </v-col>
</template>

<script>
import LinkSvg from '@/assets/svg/link.svg'
import MailSvg from '@/assets/svg/mail.svg'
import ShareSvg from '@/assets/svg/share.svg'
import DownloadSvg from '@/assets/svg/download.svg'
export default {
  components: {
    LinkSvg,
    MailSvg,
    DownloadSvg,
    ShareSvg
  },
  data () {
    return {
      isLinkClicked: false,
      isContactClicked: false
    }
  },
  methods: {
    onVisitClick () {
      window.open('https://www.molteni.it', '_blank')

      this.isLinkClicked = true
      setTimeout(() => {
        this.isLinkClicked = false
      }, 500)
    },

    onShareClick () {
      this.isLinkClicked = true
      this.$emit('share')
      this.$root.roomClass = ''
      setTimeout(() => {
        this.isLinkClicked = false
      }, 500)
    },

    onContactClick () {
      window.open('mailto:' + this.$root.currentSettings.emailContact, '_blank')
      this.isContactClicked = true
      setTimeout(() => {
        this.isContactClicked = false
      }, 500)
    }
  }
}
</script>

<style lang="scss" scoped>
.exit-footer {
  position: relative;
  left: 0;
  bottom: 0;
  background-color: $c-light-grey;
  min-height: 180px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;

  @include breakpoint('xs-only') {
    position: relative;
  }
}

.outer-link-container {
  position: relative;
  width: 100px;
  height: 100px;
  border: 1px solid transparent;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.4s ease-in-out;

  a {
    color: $c-black;
  }

  svg path {
    transition: all 0.4s ease-in-out;
    fill: $c-brown;
  }

  &:hover {
    border-color: $c-black-10;
    cursor: pointer;

    .inner-link-container {
      border-color: $c-brown;
    }
  }

  &.clicked {
    border-color: transparent;
    transition: all 0.4s ease-in-out;

    .inner-link-container {
      background-color: $c-brown;
    }

    svg path {
      transition: all 0.4s ease-in-out;
      fill: $c-white;
    }
  }

  .inner-link-container {
    width: 80px;
    height: 80px;
    border: 1px solid transparent;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.4s ease-in-out;
  }

  h5 {
    display: inline-block;
    width: 100%;
    position: absolute;
    top: 50%;
    transform: translate(80px, -50%);

    @include breakpoint('xs-only') {
      width: 150px;
      transform: translate(105px, -50%);
    }
  }
}
</style>
