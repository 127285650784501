<template>
  <div v-if="isVisible && isTutorialStepsLoaded" class="tutorial-container">
    <div class="dark-layer"></div>
    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="8" md="6" class="tutorial-content">

          <!-- Steps -->
          <div
            v-for="(step, i) in $root.TutorialSteps"
            :key="`index-${i}`"
            ref="tutorialSteps"
            class="tutorial-step mb-3 mb-sm-6"
            :class="{
              'first active': i == 0,
              'last': i == $root.TutorialSteps.length - 1
            }"
          >
            <div class="icons-container" :class="`group-${i}`">
              <img
                v-for="(img, j) in step.tutorialStepIcons"
                :key="j"
                :src="`/media/${img.id}`"
                :class="[
                  'img-child-' + j,
                  {
                    'd-none d-sm-block': i == 1 && j == 0,
                    'd-block d-sm-none': i == 1 && j == 1
                  }
                ]"
              />
            </div>
            <h4 class="bodoni xl uppercase mt-2 mt-sm-10">{{ step[$route.params.language + 'TutorialStepTitle'] }}</h4>
            <p class="my-3 my-md-6">{{ step[$route.params.language + 'TutorialStepDescription'] }}</p>
          </div>

          <OnboardingButton
            v-if="currentStepIndex < $root.TutorialSteps.length - 1"
            @onClick="nextTutorialStep"
            :text="$root.allLanguages[$route.params.language].tutorialNextSlideBtnCopy"
          />

          <OnboardingButton
            v-else
            @onClick="skipTutorial"
            :text="$root.allLanguages[$route.params.language].tutorialLastStepBtnCopy"
          />

          <div
            class="skip-tutorial uppercase mb-2 mb-sm-6 pb-sm-2 mt-6"
            @click="skipTutorial"
          >
            <span class="bold letter-spacing-20">{{ $root.allLanguages[$route.params.language].tutorialSkipBtnCopy }}</span>
          </div>
        </v-col>

        <!-- Bottom right content, hide after last step -->
        <v-col
          v-if="currentStepIndex < $root.TutorialSteps.length - 1"
          cols="12" sm="6" md="4"
          class="tutorial-content-bottom pa-0"
        >
          <!-- Tutorial progress bar -->
          <div class="tutorial-progress-container pa-6">
            <div class="d-flex">
              <h4 class="bodoni">{{ currentStepIndex + 1 }}</h4>
              <h4 class="bodoni opacity-3">/{{ $root.TutorialSteps.length - 1 }}</h4>
            </div>
            <div class="progress-bar">
              <div class="progress" :style="{ 'width': (currentStepIndex + 1) * 100 / ($root.TutorialSteps.length - 1) + '%' }"></div>
            </div>
          </div>

        </v-col>
      </v-row>
    </v-container>

  </div>
</template>

<script>

import TutorialSteps from '@/graphql/GetTutorialSteps.gql'
import OnboardingButton from '@/atoms/OnboardingButton.vue'

export default {
  props: ['isVisible'],

  components: {
    OnboardingButton
  },

  data () {
    return {
      isTutorialStepsLoaded: false,
      currentStepIndex: 0,
      isLeft: false
    }
  },

  mounted () {
    this.isFirstTime = false
  },

  apollo: {
    TutorialSteps: {
      query: TutorialSteps,
      async result () {
        this.$root.TutorialSteps = this.TutorialSteps.items
        this.isTutorialStepsLoaded = true
        // console.log('Tutorial Steps Loaded:', this.$root.TutorialSteps)
      }
    }
  },

  methods: {
    nextTutorialStep () {
      const activeStep = this.$refs.tutorialSteps[this.currentStepIndex]
      const nextStep = this.$refs.tutorialSteps[this.currentStepIndex + 1]
      this.currentStepIndex++
      this.isLeft = true
      activeStep.classList.remove('active')
      nextStep.classList.add('active')
    },
    skipTutorial () {
      this.currentStepIndex = 0
      this.$emit('update:isVisible', false)
      if (!this.isFirstTime) {
        this.$events.emit('audioOn')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .tutorial-container {
    width: 100vw;
    height: 100%;
    position: fixed;
    z-index: 6;
    top: 0;
  }

  .tutorial-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    @include breakpoint('xs-only') {
      top: 45%;
    }
  }

  .tutorial-step {
    position: relative;
    min-height: 300px;
    transition: all 0.4s ease-in-out;

    &:not(.active) {
      display: none;
    }

    &.active {
      display: block;
      animation: slide-from-right 0.4s ease-in-out;

      @keyframes slide-from-right {
        0% { right: -200vw; }
        100% { right: 0; }
      }
    }
  }

  .icons-container {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .img-child-0,
  .img-child-1,
  .img-child-2,
  .img-child-3 {
    width: 100px;
    height: 100px;
    padding: 10px;
  }

  .group-0,
  .group-2,
  .group-3,
  .group-5 {
    .img-child-0 {
      border: 1px solid $c-white-30;
      border-radius: 50%;
    }
  }

  .group-1 {
    .img-child-0,
    .img-child-1,
    .img-child-2,
    .img-child-3 {
      width: 80px;
      height: 80px;
    }

    .img-child-0,
    .img-child-1 {
      padding: 8px;
    }

    .img-child-2,
    .img-child-3 {
      border: 1px solid $c-white;
      border-radius: 50%;
      padding: 0;
    }
  }

  .group-4 {
    .img-child-0 {
      width: 80px;
      height: 80px;
      background-color: $c-dark-grey;
    }
  }

  .tutorial-content-bottom {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .skip-tutorial {
    display: inline-block;
    border-bottom: 1px solid $c-white;
    cursor: pointer;

    &:hover {
      border-bottom: 1px solid $c-white-80;
    }
  }

  .tutorial-progress-container {
    background-color: $c-anthracite;
    width: 100%;
    height: 70px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;

    @include breakpoint('xs-only') {
      border-radius: 20px 20px 0 0;
    }
  }

  .progress-bar {
    width: 100%;
    height: 4px;
    background-color: $c-white-30;
    margin-left: 24px;

    .progress {
      height: 100%;
      background-color: $c-white;
      transition: all 0.4s ease-in-out;
    }
  }

</style>
