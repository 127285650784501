<template>
  <div class="content-fullscreen-img">
    <img :src="'/media/'+ (content.alternativeImage ? content.alternativeImage.id : content.cutoutImage.id)">
  </div>
</template>

<script>

export default {
  name: 'ContentLongQuote',
  props: {
    content: {
      type: Object
    }
  }
}
</script>

<style lang="scss" scoped>
.content-fullscreen-img {
  width: 80%;
  margin: auto;
  // margin-top: -7vh;
  background-color: $c-white;
  z-index: 1;
  @include breakpoint('sm-and-down') {
    margin-top: 0;
    width: 100%;
  }

  img {
    width: 100%;
    height: auto;
  }
}
</style>
