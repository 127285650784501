<template>
  <div class="share-popup">
    <v-col cols="12" class="text-center">
      <h3 class="uppercase bodoni xl my-12">{{ $root.allLanguages[$route.params.language].sharePopupTitle }}</h3>

      <v-row align="center" justify="center">
        <v-col cols="9" sm="3" align="left" v-if="$route.params.language !== 'cn'">
          <a :href="'http://www.facebook.com/sharer.php?u=' + encodeURIComponent(shareURL)" target="_blank">
            <OverlayOval :mail="true" :icon="'facebook'" text="facebook"></OverlayOval>
          </a>
        </v-col>
        <v-col cols="9" sm="3" align="left" v-if="$route.params.language !== 'cn'">
          <a :href="'http://www.linkedin.com/shareArticle?mini=true&url=' + encodeURIComponent(shareURL)" target="_blank">
            <OverlayOval :mail="true" :icon="'linkedin'" text="linkedin"></OverlayOval>
          </a>
        </v-col>
        <v-col cols="9" sm="3" align="left" v-if="$route.params.language === 'cn'">
          <a :href="'http://service.weibo.com/share/share.php?url=' + encodeURIComponent(shareURL) + '&appkey=&title=' + encodeURIComponent('Molteni&C|Dada Virtual Experience. An immersive journey to discover the 2020 collection') + '&pic=' +encodeURIComponent('https://' + this.$root.process.env.SERVICE_TARGET_DOMAIN + shareImage) + '&ralateUid=&language=zh_cn'" target="_blank">
            <OverlayOval :mail="true" :icon="'weibo'" text="weibo"></OverlayOval>
          </a>
        </v-col>
        <v-col cols="9" sm="3" align="left" v-if="$route.params.language === 'cn'">
          <div @click="showQRCode = !showQRCode">
            <OverlayOval :mail="true" :icon="'wechat'" text="wechat"></OverlayOval>
          </div>
        </v-col>

      </v-row>
      <v-row align="center" justify="center" class="py-12 link-container mx-0">
        <p class="mb-0 width-100" v-if="!showQRCode">{{ $root.allLanguages[$route.params.language].sharePopupCopyInstruction }}</p>
        <div class="link-copy inline-block" v-if="!showQRCode">
          <input readonly class="link pa-6 letter-spacing-25 inline-block" type="text" :value="shareURL" id="myInput" ref="input"><span class="copy pa-6 letter-spacing-25 pointer" @click="copyURL">{{ $root.allLanguages[$route.params.language].sharePopupCopy }}</span>
        </div>
        <img class="qrcode inline-block" :src="qrCode" v-if="showQRCode">
      </v-row>
    </v-col>

  </div>
</template>

<script>
import OverlayOval from './OverlayOval.vue'
import shareImage from '@/assets/img/share.jpg'
import qrCode from '@/assets/img/qr-code.png'

export default {
  name: 'ExitPopupShare',

  components: {
    OverlayOval
  },

  data () {
    return {
      shareImage,
      qrCode,
      showQRCode: false
    }
  },

  computed: {
    shareURL () {
      return 'https://' + window.location.hostname // eslint-disable-line compat/compat
    }
  },

  methods: {
    copyURL () {
      const copyText = this.$refs.input

      copyText.select()
      copyText.setSelectionRange(0, 99999)

      document.execCommand('copy')

      alert('Copied the Molteni Experience URL')
    }
  }
}
</script>

<style lang="scss" scoped>
.share-popup {
  width: 100%;
  padding-bottom: 220px;
}

input {
  border-style: none !important;
  border-color: transparent !important;
}

.link-container {
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #f5f5f5;
  min-height: 220px;
  width: 100%;
  background-color: $c-light-grey;
}

.link-copy {
  // display: block;
  // width: 100%;
  text-align: center;

  .link {
    background-color: $c-white;
  }

  .copy {
    background-color: $c-brown;
    color: $c-white;
    text-transform: uppercase;

    &:hover {
      background-color: #8a6f53;
    }
  }
}

.qrcode {
  width: 160px;
  height: 160px;
}
</style>
