<template>
  <div class="hotspot-overlay">
    <div class="bg"></div>

    <transition name="fade">
      <ContentVideo
        v-if="$route.params.language !== 'cn' && hotspotContent && hotspotContent[$route.params.language+'DirectVideoLink']"
        :full="true"
        :content="{
          videoLink: hotspotContent[$route.params.language+'DirectVideoLink'] ,
          videoFile: null
        }">
      </ContentVideo>
      <ContentVideo
        v-if="$route.params.language === 'cn' && hotspotContent && hotspotContent['enDirectVideoLink']"
        :full="true"
        :content="{
          videoLink: '' ,
          videoFile: hotspotContent.mainVideoFile,
        }">
      </ContentVideo>
    </transition>

    <transition name="slide" appear mode="out-in">
      <v-container :key="hotspotContent.title" fluid class="hotspotContainer pa-0" v-if="hotspotContent && !hotspotContent[$route.params.language+'DirectVideoLink']" :class="$root.roomClass">
        <v-row >
          <v-col cols="12" sm="9" offset-sm="1" md="10" lg="9" offset-md="2"  class="hotspotContent">
            <!-- Close Hotspot -->
            <!-- Hotspot content top -->
            <v-row class="pa-0 ma-0 mt-md-n9 collection-desktop">
              <!-- Product Collection Title -->
              <v-col v-if="hotspotContent.collection" cols="12" class="collection-parent pa-0 ma-0">
                <div class="product-collection px-9 px-md-12 py-8" :class="$root.roomClass">
                  <h5 class="mx-md-10 letter-spacing-20">{{ hotspotContent.collection[$route.params.language + 'Title'] }}</h5>
                </div>
              </v-col>
            </v-row>

            <div class="my-4 my-md-9 pointer close" @click.prevent="closeHotspotContent">
              <CloseSvg class="close-svg absolute-center"/>
              <ArrowLeft class="arrow-svg absolute-center"></ArrowLeft>
              <HotspotRelated></HotspotRelated>
            </div>

            <v-row class="pa-0 ma-0 mt-md-n9 collection-mobile">
              <!-- Product Collection Title -->
              <v-col v-if="hotspotContent.collection" cols="12" class="collection-parent pa-0 ma-0">
                <div class="product-collection px-9 px-md-12 py-8" :class="$root.roomClass">
                  <h5 class="mx-md-10 letter-spacing-20">{{ hotspotContent.collection[$route.params.language + 'Title'] }}</h5>
                </div>
              </v-col>
            </v-row>

            <v-row class="my-0 pa-0 px-md-12 pb-md-12">
              <!-- Product header Title -->
              <v-col v-if="hotspotContent[$route.params.language + 'HeaderTitle']" cols="12" class="pa-12 pl-md-12 pt-0">
                <h1 class="bodoni">{{ hotspotContent[$route.params.language + 'HeaderTitle'] }}</h1>
                <h4 v-if="hotspotContent[$route.params.language + 'HeaderSubtitle']" class="mt-3 uppercase font-weight-100">{{ hotspotContent[$route.params.language + 'HeaderSubtitle'] }}</h4>
                <div class="mt-6 mt-md-12 title-line" v-if="hotspotContent.showTitleLine"></div>
              </v-col>
              <!-- Product Title -->
              <v-col cols="12" md="5" class="px-12 pl-md-12 py-0">
                <v-row class="height-100" :align="hotspotContent.extraPageVersion ? 'center' : 'start'" fill-height>
                  <v-col align="left">
                    <h4 v-if="hotspotContent.enProductTitle && hotspotContent.extraPageVersion" class="bodoni mb-3 mb-md-9">{{ hotspotContent[$route.params.language + 'ProductTitle'] ? hotspotContent[$route.params.language + 'ProductTitle'] : hotspotContent.enProductTitle }}</h4>
                    <h3
                      v-else-if="hotspotContent.enProductTitle"
                      :class="hotspotContent.productDesignerName ? 'mb-md-3' : 'mb-md-9'"
                      class="uppercase bodoni mb-3">{{ hotspotContent[$route.params.language + 'ProductTitle'] ? hotspotContent[$route.params.language + 'ProductTitle'] : hotspotContent.enProductTitle }}</h3>

                    <h4 v-if="hotspotContent.productDesignerName" class="designer mb-6 mb-md-12 uppercase letter-spacing-12 c-anthracite extra-light" :class="$root.roomClass">{{ hotspotContent.productDesignerName }}</h4>
                    <!--<p class="product-description">{{ hotspotContent[$route.params.language + 'ProductDescription'] }}</p>-->
                    <MarkdownBlock v-if="hotspotContent[$route.params.language + 'ProductDescription']" :inline="false" linkify tag="div" class="description" :text="hotspotContent[$route.params.language + 'ProductDescription']" />
                    <a :href="hotspotContent.shopOnlineURL" target="_blank" v-if="$root.usVersion && hotspotContent.shopOnlineURL" class="button-square mt-6 mb-12 mb-md-0">
                      <span class="uppercase letter-spacing-15 font-weight-bold">{{ $root.allLanguages[$route.params.language].shopOnline }}</span>
                      <ArrowRight class="ml-sm-3"/>
                    </a>
                  </v-col>
                </v-row>
              </v-col>

              <!-- Video player -->
              <v-col cols="12" md="7" class="pa-0">
                <div v-if="$route.params.language !== 'cn' && (hotspotContent[$route.params.language + 'DesignerVideoLink'] || hotspotContent.mainVideoLink || hotspotContent.mainVideoFile)" class="video-player-container">
                  <ContentVideo
                    :full="false"
                    :content="{
                      videoLink: hotspotContent[$route.params.language + 'DesignerVideoLink'] || hotspotContent.mainVideoLink ,
                      videoFile: hotspotContent.mainVideoFile
                    }">
                  </ContentVideo>
                </div>

                <div v-else-if="$route.params.language === 'cn' && hotspotContent.cnMainVideoFile" class="video-player-container">
                  <ContentVideo
                    :full="false"
                    :content="{
                      videoFile: hotspotContent.cnMainVideoFile
                    }">
                  </ContentVideo>
                </div>

                <div v-else-if="hotspotContent.mainPhoto" class="main-photo px-0 px-md-12">
                  <img class="image" :src="'/media/'+ hotspotContent.mainPhoto.id">
                  <!--<div class="image" :style="{backgroundImage: 'url(/media/'+ hotspotContent.mainPhoto.id+')'}"></div>-->
                  <p v-if="hotspotContent[$route.params.language + 'MainPhotoCaption']" class="mt-3 px-12 italic">{{ hotspotContent[$route.params.language + 'MainPhotoCaption'] }}</p>
                </div>
              </v-col>

              <v-col cols="12"
                v-if="hotspotContent[$route.params.language + 'MainDownload'] && hotspotContent[$route.params.language + 'MainDownload'].file"
                class="px-12" :class="{'iphone-hide': iphoneHide}">
                <div class="main-download">
                  <a :download="hotspotContent[$route.params.language + 'MainDownload'].file.originalName" target="_blank" :href="'/media/'+hotspotContent[$route.params.language + 'MainDownload'].file.id">
                    <OverlayOval :text="hotspotContent[$route.params.language + 'MainDownload'].title"></OverlayOval>
                  </a>
                </div>
              </v-col>
              <!-- Product Actions -->
              <v-col cols="12" class="my-3 px-9" :class="{'iphone-hide': iphoneHide}"
              v-if="hotspotContent[$route.params.language + 'ProductDatasheet'] ||
              hotspotContent.productTechnicalDrawing ||  !hotspotContent.hidePress">
                <ContentDownload :hotspotContent="hotspotContent"></ContentDownload>
              </v-col>
            </v-row>

            <v-row class="ma-0" v-for="(content, index) in contentGroup" :key="'content'+index">
              <ContentQuote :class="{'iphone-hide': iphoneHide}"
                v-if="content._repeaterType === 'quote'"
                :content="content"></ContentQuote>

              <ContentLongQuote :class="{'iphone-hide': iphoneHide}"
                v-if="content._repeaterType === 'longQuote'"
                :content="content"></ContentLongQuote>

              <ContentTextWithTitle :class="{'iphone-hide': iphoneHide}"
                v-if="content._repeaterType === 'TextWithTitle'"
                :content="content"></ContentTextWithTitle>

              <ContentGalleryRightText :class="{'iphone-hide': iphoneHide}"
                v-if="content._repeaterType === 'Gallery with text on the right'"
                :content="content"></ContentGalleryRightText>

              <ContentGalleryMenuTop :class="{'iphone-hide': iphoneHide}"
                v-if="content._repeaterType === 'galleryWithTopMenu'"
                :content="content.content"></ContentGalleryMenuTop>

              <ContentTextWithImageRight :class="{'iphone-hide': iphoneHide}"
                v-if="content._repeaterType === 'Text with Image on the Right'"
                :content="content"></ContentTextWithImageRight>

              <ContentGallery :class="{'iphone-hide': iphoneHide}"
                v-if="content._repeaterType === 'Gallery'"
                :content="content"></ContentGallery>

              <ContentVideo
                :full="false"
                :designer="true"
                v-if="($route.params.language !== 'cn' || ($route.params.language == 'cn' && content.cnVideoFile)) && content._repeaterType === 'technicalVideo'"
                :content="{
                  videoFile: $route.params.language === 'cn' ? content.cnVideoFile : content.videoFile,
                  videoLink: content[$route.params.language + 'VideoLink'] || videoLink
                }">
              </ContentVideo>

              <ContentFullscreenImage :class="{'iphone-hide': iphoneHide}"
                class="mt-6"
                v-if="$route.params.language === 'cn' && !content.cnVideoFile && content.alternativeImage && content._repeaterType === 'technicalVideo'"
                :content="content"
              ></ContentFullscreenImage>

              <ContentFullscreenImage :class="{'iphone-hide': iphoneHide}"
                v-if="content._repeaterType === 'FullscreenImage'"
                :content="content"></ContentFullscreenImage>
            </v-row>
            <v-row v-if="$root.usVersion && hotspotContent.configURL" justify="center" class="py-3 py-md-12 mb-12">
              <a :href="hotspotContent.configURL" target="_blank" class="button-square mt-6">
                <span class="uppercase letter-spacing-15 font-weight-bold">{{ $root.allLanguages[$route.params.language].configure }}</span>
                <ArrowRight class="ml-sm-3"/>
              </a>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </transition>
  </div>
</template>

<script>
import ContentTextWithTitle from '../atoms/ContentTextWithTitle.vue'
import OverlayOval from '../atoms/OverlayOval.vue'
import { MarkdownBlock } from '@monogrid/vue-lib'

import ContentDownload from '../atoms/ContentDownload.vue'
import HotspotRelated from '../atoms/HotspotRelated.vue'
import ContentFullscreenImage from '../atoms/ContentFullscreenImage.vue'
import ContentLongQuote from '../atoms/ContentLongQuote.vue'
import ContentVideo from '../atoms/ContentVideo.vue'
import ContentGallery from '../atoms/ContentGallery.vue'
import ContentTextWithImageRight from '../atoms/ContentTextWithImageRight.vue'
import ContentGalleryMenuTop from '../atoms/ContentGalleryMenuTop.vue'
import ContentGalleryRightText from '../atoms/ContentGalleryRightText.vue'
import ContentQuote from '../atoms/ContentQuote.vue'

import GetHotspotContent from '@/graphql/GetHotspotContent.gql'

import CloseSvg from '@/assets/svg/close.svg'

import ArrowLeft from '@/assets/svg/arrow-long-left.svg'
import ArrowRight from '@/assets/svg/arrow-long-right.svg'

export default {
  components: {
    ContentLongQuote,
    CloseSvg,
    ArrowLeft,
    ArrowRight,
    ContentQuote,
    ContentGalleryRightText,
    ContentGalleryMenuTop,
    ContentTextWithImageRight,
    ContentGallery,
    ContentVideo,
    ContentFullscreenImage,
    HotspotRelated,
    ContentDownload,
    OverlayOval,
    MarkdownBlock,
    ContentTextWithTitle
  },

  data () {
    return {
      fullscreenCarouselEnabled: false,
      showVideo: false,
      showTechVideo: false,
      hotspotContent: null,
      iphoneHide: false
    }
  },

  apollo: {
    HotspotContent: {
      query: GetHotspotContent,
      variables () {
        return {
          slug: this.$route.params.hotspotContent
        }
      },
      async result () {
        this.$events.emit('scrollToTop')
        this.$events.emit('isLoadingEnd')

        this.hotspotContent = this.HotspotContent
        if (this.hotspotContent.colorCategory) {
          this.$root.roomClass = this.hotspotContent.colorCategory
        }
      }
    }
  },

  mounted () {
    this.$events.on('videoOpen', () => { this.iphoneHide = true })
    this.$events.on('videoClose', () => { this.iphoneHide = false })
  },

  watch: {
    '$apollo.loading': function (value) {
      if (value) {
        console.log('restart content')
        this.hotspotContent = null
        this.$events.emit('isLoadingStart')
      }
    }
  },

  computed: {
    contentGroup () {
      const content = []
      const contentGalleryTopMenu = []
      let indexContent = -1

      if (this.hotspotContent && this.hotspotContent.content) {
        for (let i = 0; i < this.hotspotContent.content.length; i++) {
          const element = this.hotspotContent.content[i]
          if (element._repeaterType === 'galleryWithTopMenu') {
            // remember first index
            if (indexContent === -1) {
              indexContent = i
            }
            contentGalleryTopMenu.push(element)
          } else {
            content.push(element)
          }
        }
      }
      if (indexContent > -1) {
        content.splice(indexContent, 0, {
          _repeaterType: 'galleryWithTopMenu',
          content: contentGalleryTopMenu
        })
      }

      return content
    }
  },
  methods: {
    closeHotspotContent () {
      this.hotspotContent = null

      this.$router.push(
        {
          name: 'Room',
          params: {}
        }
      )
    },
    fullscreenCarousel () {
      this.fullscreenCarouselEnabled = !this.fullscreenCarouselEnabled
      console.log('fullscreen is', this.fullscreenCarouselEnabled)
    }
  }
}
</script>

<style lang="scss" scoped>

.bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 0;
  background-color: $c-black-60;
}

.hotspot-overlay {
  position: relative;
  z-index: 6;
}

.hotspotContainer {
  min-height: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

.hotspotContent {
  height: auto;
  background-color: $c-white;
  color: $c-black;
  margin: 100px auto;
  padding: 0;
  overflow: visible;

  @include breakpoint('xs-only') {
    margin: 0;
    padding-bottom: 120px;
  }
}

.title-line {
  width: 120px;
  height: 1px;
  border-top: 1px solid $c-brown;
}

.collection-desktop {
  display: block;
  @include breakpoint('xs-only') {
    display: none;
  }
}

.collection-mobile {
  display: none;
  @include breakpoint('xs-only') {
    display: block;
  }
}

.description {
  strong {
    font-weight: 600;
  }

  em {
    color: $c-brown;
  }
}

.video-player-container {
  width: 120%;

  @include breakpoint('sm-and-down') {
    width: 100%;
  }
}

.close {
  width: 88px;
  height: 88px;
  background-color: $c-white;
  position: sticky;
  top: 0;
  margin-top: 36px;
  transform: translate(-87px, 0);

  @include breakpoint('xs-only') {
    position: relative;
    top: 0;
    margin-top: 0;
    width: 50px;
    height: 50px;
    transform: translateX(0);
  }

  svg {
    transition: transform 0.25s;
  }

  &:hover {
    svg {
      transform: translate(-50%, -50%) scale(1.2);
    }
  }

  .close-svg {
    width: 28px;
    height: 28px;

    @include breakpoint('xs-only') {
      display: none;
    }
  }

  .arrow-svg {
    display: none;
    @include breakpoint('xs-only') {
      display: block;
      margin-left: 28px;
      width: 28px;
      height: 28px;
    }
  }
}

.collection-parent {
  position: absolute;

  @include breakpoint('xs-only') {
    position: relative;
  }
}

.product-collection {
  background-color: $c-dark-grey;
  color: $c-white;
  display: inline-block;
  align-items: center;
  justify-content: center;

  @include breakpoint('sm-and-down') {
    position: relative;
    top: 0;
  }
}

.product-description {
  margin-top: 60px;

  @include breakpoint('sm-and-down') {
    margin-top: 30px;
  }
}

svg {
  width: 20px;
  height: auto;
}

button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-photo {
  position: relative;
  width: 100%;

  @include breakpoint('sm-and-down') {
    height: 50%;
  }

  p {
    &::before {
      width: 1px;
      height: 120px;
      content: '';
      border-left: 1px solid $c-brown;
      position: absolute;
      left: 72px;
      margin-top: -60px;

      @include breakpoint('sm-and-down') {
        left: 36px;
      }
    }
  }
}

.image {
  position: relative;
  width: 100%;
  height: auto;
  margin: auto;
}

.slide-leave-active,
.slide-enter-active {
  transition: top 1s $ease-out-quint;
}

.slide-enter,
.slide-leave-to {
  top: 100vh !important;
}

.button-square {
  height: 100%;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  padding: 15px 20px;

  span {
    color: $c-black;
  }

  svg {
    transition: transform 0.25s;
  }

  &:hover {
    svg {
      transform: translateX(4px);
    }
  }
}
// COLOR RULES
@each $name, $color in $group-colors {
  .#{$name} {
    .button-square {
      background-color: #{$color};
    }
  }

  .designer.#{$name} {
    &::after {
      border-bottom: 1px solid #{$color};
    }
  }
}
</style>
