<template>
  <div @click="toogleAudio" :class="{'clicked': isClicked }" class="audio-container">
    <AudioIcon :playing="playing"/>
  </div>
</template>

<script>
import VueHowler from 'vue-howler'
import AudioIcon from '@/atoms/SoundIconWithAnimation.vue'

export default {
  mixins: [
    VueHowler
  ],

  components: {
    AudioIcon
  },

  data () {
    return {
      volume: 0.1,
      isClicked: false
    }
  },

  methods: {
    toogleAudio () {
      this.isClicked = true

      setTimeout(() => {
        this.isClicked = false
      }, 500)

      if (this.playing) {
        this.pause()
        this.wasPlaying = false
      } else {
        if (this) {
          this.play()
        }

        this.wasPlaying = true
      }
    }
  },

  mounted () {
    this.wasPlaying = true

    this.$events.on('audioOff', () => {
      if (this.wasPlaying) {
        this.pause()
      }
    })

    this.$events.on('audioOn', () => {
      if (this.wasPlaying) {
        this.play()
      }
    })

    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        if (this.wasPlaying) {
          this.play()
        }
      } else {
        if (this.wasPlaying) {
          this.pause()
        }
      }
    })
  },
  watch: {
    '$route' (to, from) {
      if (to.name === 'Home' && from.name === 'Room') {
        this.stop()
      }
      if (to.name === 'Room' && from.name === 'Home') {
        this.play()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .audio-container {
    &.clicked {
      transition: all 0.4s ease-in-out;
      background-color: $c-white-30;
    }
  }
</style>
