<template>
  <div :class="{'mobile-top': showMenu}">
    <v-container
      fluid
      class="header-container pa-0"
      >
      <v-row
        align="center"
        class="header-content my-0"
      >

        <v-col
          :class="$route.name !== 'Home' && $route.name !== 'Registration' ? 'justify-start' : 'justify-center'"
          class="header-left-side pl-5 pl-sm-10"
        >
          <a :href="$root.allLanguages[$route.params.language].footerVisitLink ? $root.allLanguages[$route.params.language].footerVisitLink : 'https://www.molteni.it'" target="_blank">
            <MolteniLogo class="logo"/>
          </a>
        </v-col>

        <v-col cols="5" class="header-right-side">

          <div
            @click="showTutorial"
            :class="{'clicked': isClickedInfo }"
            class="menu-button d-none d-sm-flex"
          >
            <InfoSvg/>
          </div>

          <AudioPlayer
            class="menu-button"
            :sources="audioSource"
            :loop="true"
            :html5="true"
            :autoplay="audioAutoPlay"
          />

          <div
            class="menu-button leave-tour d-none d-sm-flex"
            @click="leaveTour"
            :class="{'clicked': isClickedLeave }"
          >
            <ExitSvg class="svg-stroke-white"/>
            <span class="d-none d-sm-block ml-2 bold letter-spacing-20 uppercase">{{ $root.allLanguages[$route.params.language].headerLeaveTourCopy }}</span>
          </div>

          <div
            @click="showMenu = !showMenu"
            class="menu-button d-flex d-sm-none"
          >
            <MenuSvg
              v-if="!showMenu"
              class="svg-stroke-white"
            />
            <CloseSvg
              v-else
              class="svg-stroke-white"
            />
          </div>

        </v-col>
      </v-row>
    </v-container>

    <!-- Desktop Menu -->
    <transition name="fade">
      <Menu v-if="showMenu" @onPageChange="showMenu = !showMenu"/>
    </transition>
    <!-- Mobile Menu -->
    <transition name="fade">
      <MenuMobile v-if="showMenu" @onInfoClick="showTutorial" @onLeaveTour="leaveTour"/>
    </transition>

    <!-- Center menu icon -->
    <div class="header-center d-none d-sm-flex">
      <MenuSvg v-if="!showMenu" @click="showMenu = !showMenu" class="svg-stroke-white"/>
      <CloseSvg v-if="showMenu" @click="showMenu = !showMenu" class="svg-stroke-white"/>
      <p class="uppercase">{{ $root.allLanguages[$route.params.language].menuCopy }}</p>
    </div>

  </div>
</template>

<script>

import AudioPlayer from '@/organisms/AudioPlayer.vue'
import Menu from '@/molecules/Menu.vue'
import MenuMobile from '@/molecules/MenuMobile.vue'

import MolteniLogo from '@/assets/svg/molteni-logo-white.svg'

import MenuSvg from '@/assets/svg/menu.svg'
import InfoSvg from '@/assets/svg/information-white.svg'
import ExitSvg from '@/assets/svg/exit.svg'
import CloseSvg from '@/assets/svg/close.svg'

export default {
  components: {
    AudioPlayer,
    MenuSvg,
    InfoSvg,
    ExitSvg,
    CloseSvg,
    Menu,
    MenuMobile,
    MolteniLogo
  },

  data () {
    return {
      MolteniLogo,
      showMenu: false,
      audioSource: [
        `/media/${this.$root.currentSettings.bgAudioMp3.id}`
      ],
      audioAutoPlay: this.$route.name !== 'Home' && this.$route.name !== 'Registration',
      isClickedInfo: false,
      isClickedAudio: false,
      isClickedLeave: false
    }
  },

  mounted () {
    this.$events.on('openMenu', () => {
      this.showMenu = true
    })
  },
  methods: {
    showTutorial () {
      if (this.showMenu) {
        this.showMenu = !this.showMenu
      }
      this.isClickedInfo = true
      setTimeout(() => {
        this.isClickedInfo = false
      }, 500)
      this.$emit('onInfoClick')
    },
    leaveTour () {
      if (this.showMenu) {
        this.showMenu = !this.showMenu
      }
      this.isClickedLeave = true
      setTimeout(() => {
        this.isClickedLeave = false
      }, 500)
      this.$emit('onTourExit')
    }
  }
}
</script>

<style lang="scss" scoped>
.mobile-top {
  @include breakpoint('xs-only') {
    z-index: 6;
    position: fixed;
  }
}

.header-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 4;
  height: 80px;
}

.header-content {
  height: 100%;
}

.header-left-side,
.header-right-side {
  height: 100%;
  display: flex;
  align-items: center;
}

.header-left-side {
  // padding-left: 40px;
}

.header-right-side {
  justify-content: flex-end;
}

.menu-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $c-black-30;
  height: 80px;
  width: 80px;
  cursor: pointer;
  transition: all 0.4s ease-in-out;

  &:hover {
    background: $c-black-60;
  }

  &.leave-tour {
    width: 150px;
  }

  &.clicked {
    transition: all 0.4s ease-in-out;
    background-color: $c-white-30;
  }
}

.header-center {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  position: absolute;
  top: 16px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;

  p {
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 2.5px;
    margin-top: 8px;
  }
}

.menu-button,
.header-center {
  svg {
    transition: transform 0.25s;
  }

  &:hover {
    svg {
      transform: scale(1.2);
    }
  }
}

svg {
  cursor: pointer;
  display: inline-block;
  width: 20px;
  height: 20px;
  height: auto;

  @include breakpoint('xs-only') {
    width: 20px;
  }
}
</style>
