<template>
  <div class="checkbox" :class="{'active': check}">
  </div>
</template>

<script>
export default {
  name: 'RegistrationCheckbox',

  props: {
    check: {
      type: Boolean
    }
  }
}
</script>

<style lang="scss" scoped>
.checkbox {
  width: 20px;
  height: 20px;
  border: 1px solid $c-black;
  cursor: pointer;
  display: inline-block;

  &.active {
    border: 1px solid $c-brown;

    &::before {
      opacity: 1;
    }
  }

  &::before {
    content: '';
    width: 10px;
    height: 10px;
    position: absolute;
    background-color: $c-brown;
    margin-top: 4px;
    margin-left: 4px;
    opacity: 0;
  }
}
</style>
