<template>
  <div class="action-container" :class="'button-' + $root.roomClass">
    <div class="oval-big">
    </div>
    <div class="oval-container">
      <div class="oval">
        <MailSvg v-if="icon === 'mail'"></MailSvg>
        <FacebookSvg v-else-if="icon === 'facebook'"></FacebookSvg>
        <LinkedinSvg v-else-if="icon === 'linkedin'"></LinkedinSvg>
        <WeiboSvg v-else-if="icon === 'weibo'"></WeiboSvg>
        <WechatSvg v-else-if="icon === 'wechat'"></WechatSvg>
        <DownloadSvg v-else />
      </div>
    </div>
    <h5 class="action-title uppercase letter-spacing-12">{{ text }}</h5>
  </div>
</template>

<script>
import DownloadSvg from '@/assets/svg/download.svg'
import MailSvg from '@/assets/svg/mail.svg'
import WeiboSvg from '@/assets/svg/weibo.svg'
import WechatSvg from '@/assets/svg/wechat.svg'
import FacebookSvg from '@/assets/svg/facebook.svg'
import LinkedinSvg from '@/assets/svg/linkedin.svg'

export default {
  name: 'OverlayOval',
  components: {
    DownloadSvg, MailSvg, FacebookSvg, LinkedinSvg, WeiboSvg, WechatSvg
  },
  props: {
    text: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>

.action-container {
  display: flex;
  align-items: center;
  position: relative;

  &:hover {
    cursor: pointer;

    .oval-big {
      opacity: 1;
    }

    .oval {
      border-style: none;
      background-color: $c-brown;
    }

    path {
      fill: $c-white !important;
      stroke: $c-white !important;
    }
  }

  .action-title {
    color: $c-dark-grey;
    margin: 0;
    position: absolute;
    top: 50%;
    margin-left: 96px;
    transform: translateY(-50%);
    text-align: left;
  }
}

.oval-big {
  opacity: 1;
  position: absolute;
  width: 126px;
  height: 126px;
  border: solid 1px $c-brown;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.5s;
  opacity: 0.2;
}

.oval-container {
  opacity: 1;
  position: relative;
  width: 126px;
  height: 126px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.5s;
}

.oval {
  width: 96px;
  height: 96px;
  border: solid 1px $c-brown;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;

  svg {
    width: 20px;
    height: 20px;
    stroke: $c-brown;
    fill: $c-brown;

    path {
      stroke: $c-brown;
      fill: $c-brown;
    }
  }
}

// COLOR RULES
@each $name, $color in $group-colors {

  .button-#{$name} {
    .oval,
    .oval-big {
      border: 1px solid #{$color};
    }

    path {
      fill: #{$color};
      stroke: #{$color};
    }

    &:hover {
      .oval {
        background-color: #{$color};
      }

      path {
        stroke: $c-white;
        fill: $c-white;
      }
    }
  }
}

</style>
