<template>
  <v-container
    fluid
    class="footer-container d-none d-sm-block"
    :class="[
      {'d-none': ($route.name === 'Home')},
      {'relative': relative}
    ]">
    <v-row
      align="center"
      class="footer-content"
      no-gutters>
      <v-col cols="10" align="left" class="d-flex">
        <a :href="getStoreLocatorLink" target="_blank" class="footer-link mr-md-6 mr-lg-12 bold letter-spacing-15">{{ $root.allLanguages[$route.params.language].footerVisitMolteni }}</a>
        <a @click="$events.emit('showCredit')" class="footer-link mr-md-6 mr-lg-12 bold letter-spacing-15">{{ $root.allLanguages[$route.params.language].credits }}</a>
        <a :href="$root.allLanguages[$route.params.language].footerContactLink" target="_blank" class="footer-link mr-md-6 mr-lg-12 bold letter-spacing-15">{{ $root.allLanguages[$route.params.language].footerContacts }}</a>
        <a :href="'/static/pdf/molteni-experience-cookies-policy-' + $route.params.language + '.pdf'" target="_blank" class="footer-link mr-md-6 mr-lg-12 bold letter-spacing-15">{{ $root.allLanguages[$route.params.language].footerCookiePolicy }}</a>
        <a :href="'/static/pdf/molteni-experience-privacy-policy-2021-' + $route.params.language + '.pdf'" target="_blank" class="footer-link mr-md-6 mr-lg-12 bold letter-spacing-15">{{ $root.allLanguages[$route.params.language].footerPrivacyPolicy }}</a>
      </v-col>
      <v-col v-if="$route.name !== 'Cookie'" cols="2" align="right">
        <LangSwitcher/>
      </v-col>
    </v-row>

    <ProductInRoom></ProductInRoom>
  </v-container>
</template>

<script>
import ProductInRoom from '../atoms/ProductInRoom.vue'
import LangSwitcher from '@/atoms/LangSwitcher.vue'

export default {
  props: {
    relative: {
      type: Boolean
    }
  },
  components: {
    ProductInRoom,
    LangSwitcher
  },

  computed: {
    getStoreLocatorLink () {
      const link = 'https://store.molteni.it/' + this.$route.params.language + '/store_locator'

      /*
      if (this.$root.userCountryCode) {
        const originalCountry = 'de,es,at,ch,gb,us,jp,fr,it'
        const spanishCountry = 'es,ar,bo,cl,co,cr,cu,do,ec,sv,gt,hn,mx,ni,pa,py,pe,pr,uy,ve'

        if (originalCountry.indexOf(this.$root.userCountryCode) > -1) {
          link = 'https://store.molteni.it/' + this.$root.userCountryCode + '/store_locator'
        } else if (spanishCountry.indexOf(this.$root.userCountryCode) > -1) {
          link = 'https://store.molteni.it/es/store_locator'
        }
      } */
      return link
    }
  }
}
</script>

<style lang="scss" scoped>
  .footer-container {
    position: fixed;
    bottom: 0;
    z-index: 3;
    color: $c-white;
    padding: 0;
    font-size: 14px;
    letter-spacing: 0.7px;

    @include breakpoint('sm-only') {
      font-size: 14px;
    }

    @include breakpoint('xs-only') {
      font-size: 12px;
    }

    &.relative {
      position: relative;
      margin-top: 48px;

      .footer-link {
        color: $c-black;

        &::after {
          background-color: $c-black;
        }
      }

      .footer-content {
        border-top: 1px solid $c-black;
        width: 100%;
        height: 80px;
      }
    }

    .footer-content {
      border-top: 1px solid $c-white;
      width: 95%;
      margin: 0 auto;
    }

    .footer-link {
      color: $c-white;
      text-decoration: none;
      display: flex;
      align-items: center;

      &:hover {
        &::after {
          transform: translateX(5px);
        }
      }

      &::after {
        content: '';
        transition: transform 0.5s;
        width: 15px;
        height: 1px;
        margin-left: 20px;
        background-color: $c-white;

        @include breakpoint('md-and-down') {
          margin-left: 10px;
          width: 10px;
          margin-right: 10px;
        }

        @include breakpoint('sm-and-down') {
          margin-left: 5px;
          width: 10px;
          margin-right: 10px;
        }
      }
    }
  }
</style>
