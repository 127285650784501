<template>
  <div class="panolens" :class="{'map-open': isMapOpen}">
    <div v-if="currentRoom" class="hotspot-reference">
      <div v-if="currentRoom.hotspotInfo">
        <div class="hotspot-info" v-for="(hotspot, index) in currentRoom.hotspotInfo"
            :id="'hs'+hotspot.hotspot.title+index"
            :key="'hs'+hotspot.hotspot.title+index"
            :ref="'hotspot-info'+index"
          >

          <HotspotHtml :hotspot="hotspot"/>
        </div>
      </div>
      <div v-if="currentRoom.hotspotArt">
        <div class="hotspot-art" v-for="(hotspot, index) in currentRoom.hotspotArt"
            :id="'ha'+index"
            :key="'ha'+index"
            :ref="'hotspot-art'+index"
          >

          <HotspotArtHtml v-if="!hotspot.hotspot.isCapsule" :hotspot="hotspot"/>
          <HotspotCapsuleHtml v-else :hotspot="hotspot"/>
        </div>
      </div>
      <div v-if="currentRoom.hotspotRoom">
        <div class="hotspot-room" v-for="(hotspot, index) in currentRoom.hotspotRoom"
            :id="'hr'+index"
            :key="'hr'+index"
            :ref="'hotspot-room'+index"
          >
          <HotspotRoomHtml :hotspot="hotspot"/>
        </div>
      </div>

      <div v-if="currentRoom.exitPosition">
        <div class="hotspot-room" :ref="'hotspot-exit'">
          <HotspotRoomHtml :exit="true"/>
        </div>
      </div>
    </div>

    <div class="pano-container" ref="pano" @click="$events.emit('onCloseHotspot')">
    </div>

    <div class="black-overlay"></div>
    <div class="pano-hotspot-container" ref="panohotspot">
    </div>

    <ExitPopup :isVisible.sync="showExitPage" />

    <Footer></Footer>

    <Header @onInfoClick="showTutorial = true" @onTourExit="showExitPage = !showExitPage" />

    <TutorialPopup :isVisible.sync="showTutorial" />

    <HotspotHtmlMobile></HotspotHtmlMobile>
    <HotspotArtHtmlMobile></HotspotArtHtmlMobile>

    <FloorMap :rotation="camRotationY" @onMapOpen="isMapOpen=true" @onMapClose="isMapOpen=false"/>
  </div>
</template>

<script>
import HotspotCapsuleHtml from '../atoms/HotspotCapsuleHtml.vue'
import Footer from '../organisms/Footer.vue'
import HotspotRoomHtml from '../atoms/HotspotRoomHtml.vue'
import ExitPopup from './ExitPopup.vue'
import TutorialPopup from '../organisms/TutorialPopup.vue'
import Header from '../organisms/Header.vue'
import HotspotArtHtmlMobile from '../atoms/HotspotArtHtmlMobile.vue'
import HotspotHtmlMobile from '../atoms/HotspotHtmlMobile.vue'
import HotspotArtHtml from '../atoms/HotspotArtHtml.vue'

import { ImagePanorama, Viewer, InfospotSprite } from 'panolens/build/panolens.module'
import { Vector3 } from 'three'

import GetRoom from '@/graphql/GetRoom.gql'
import GetHotspotContent from '@/graphql/GetHotspotContent.gql'

import HotspotHtml from '@/atoms/HotspotHtml'
// import PanolensRoomList from '@/atoms/PanolensRoomList'
import FloorMap from '@/molecules/FloorMap'
import { RAF } from '@monogrid/js-utils'
import { Viewport } from '@monogrid/vue-lib'
import floorArrow from '@/assets/img/floor-arrow.png'
import infoCircle from '@/assets/img/info-circle.png'
import anime from 'animejs'
import { optimizedImageURL } from '@/services/OptimizedImage'
import axios from 'axios'

export default {
  name: 'Panolens360',
  mixins: [Viewport],
  data () {
    return {
      rooms: null,
      roomslugs: [],
      currentRoom: null,
      camRotationY: 0,
      floorArrow,
      infoCircle,
      isMapOpen: false,
      showTutorial: false,
      showExitPage: false
    }
  },

  components: {
    HotspotArtHtmlMobile,
    FloorMap,
    // PanolensRoomList,
    HotspotHtml,
    HotspotArtHtml,
    HotspotHtmlMobile,
    Header,
    TutorialPopup,
    ExitPopup,
    HotspotRoomHtml,
    Footer,
    HotspotCapsuleHtml
  },

  mounted () {
    if (document.cookie && document.cookie !== '') {
      const cookieMolteniTutorial = document.cookie.split('; ').find(row => row.startsWith('moltenitutorial'))
      if (!cookieMolteniTutorial) {
        this.showTutorial = true
        document.cookie = 'moltenitutorial=true; max-age=2592000;'
      }
    }

    this.$events.on('onTourRestart', () => { this.showExitPage = false })
    this.$events.on('showCredit', () => { this.blockScroll = true })
    this.$events.on('hideCredit', () => { this.blockScroll = false })

    this.cacheImage = {}
    this.infospots = []
    this.newpanorama = null
    this.currentPanorama = null
    this.zoomDefault = this.viewPort.width < 600 ? 90 : 70
    this.minZoom = this.viewPort.width < 600 ? 90 : 70
    this.maxZoom = 40
    this.fixedCamera = false
    this.currentZoom = this.zoomDefault

    RAF.add(this.update)
    this.$events.on('keepCameraSteady', () => { this.fixedCamera = true })
    this.$events.on('useCameraInitialPoint', () => { this.fixedCamera = false })
    this.$events.on('onTourExit', () => { this.showExitPage = true })
    this.$events.on('onOpenHotspot', (hotspot) => {
      this.isHotspotOpen = true
      const ip = hotspot.position.split(',')
      this.viewer.tweenControlCenter(new Vector3(parseInt(ip[0]), parseInt(ip[1]), parseInt(ip[2])), 500)

      anime({
        targets: this,
        currentZoom: 50,
        easing: 'easeInOutCirc',
        update: () => {
          if (this.viewer) {
            this.viewer.setCameraFov(this.currentZoom)
          }
        }
      })
    })

    this.$events.on('openArtHotspot', (hotspot) => {
      if (hotspot.hotspot.isCapsule) {
        this.isHotspotOpen = true
        this.blockScroll = true
      }
      const ip = hotspot.position.split(',')
      this.viewer.tweenControlCenter(new Vector3(parseInt(ip[0]), parseInt(ip[1]), parseInt(ip[2])), 500)
    })

    this.$events.on('closeArtHotspot', (hotspot) => {
      this.isHotspotOpen = false
      this.blockScroll = false
    })

    this.$events.on('preloadRoom', (slug) => {
      this.loadRoomAndImage(slug)
    })

    this.$events.on('onCloseHotspot', () => {
      if (this.isHotspotOpen === true) {
        this.isHotspotOpen = false
        anime({
          targets: this,
          currentZoom: this.zoomDefault,
          easing: 'easeInOutCirc',
          update: () => {
            if (this.viewer) {
              this.viewer.setCameraFov(this.currentZoom)
            }
          }
        })
      }
    })
    // document.addEventListener('mousemove', this.onMouseUpdate)

    document.addEventListener('wheel', (e) => {
      if (!this.$route.params.hotspotContent && !this.blockScroll) {
        if (e.deltaY < 0) {
          if (this.currentZoom > this.maxZoom && this.viewer) {
            this.currentZoom--
            this.viewer.setCameraFov(this.currentZoom)
          }
        } else {
          if (this.currentZoom < this.minZoom && this.viewer) {
            this.currentZoom++
            this.viewer.setCameraFov(this.currentZoom)
          }
        }
      }
    })
    this.isTouching = false

    document.addEventListener('keyup', (e) => {
      // console.log(e.code)
      if (e.code === 'KeyA') {
        if (this.viewer.OrbitControls) {
          console.log('orbit control', this.viewer.OrbitControls.getAzimuthalAngle())
        }
      }

      if (e.code === 'KeyF') {
        if (this.viewer.OrbitControls) {
          this.viewer.OrbitControls.minPolarAngle = 0
          this.viewer.OrbitControls.minAzimuthAngle = -Infinity
          this.viewer.OrbitControls.maxAzimuthAngle = Infinity
        }
      }
    })

    document.addEventListener('touchend', (e) => {
      if (this.isTouching && (!this.$route.params.hotspotContent)) {
        if (this.zoomDefault > 60) {
          anime({
            targets: this,
            zoomDefault: this.maxZoom,
            easing: 'easeInOutCirc',
            update: () => {
              this.viewer.setCameraFov(this.zoomDefault)
            }
          })
        } else {
          anime({
            targets: this,
            zoomDefault: this.minZoom,
            easing: 'easeInOutCirc',
            update: () => {
              this.viewer.setCameraFov(this.zoomDefault)
            }
          })
        }
      } else {
        this.isTouching = true
        setTimeout(() => {
          this.isTouching = false
        }, 200)
      }
    })
  },

  beforeDestroy () {
    RAF.remove(this.update)
    // document.removeEventListener('mousemove', this.onMouseUpdate)
  },

  methods: {
    update () {
      if (this.viewer) {
        this.camRotationY = this.viewer.camera.rotation.y * (180 / Math.PI)
      }
    },

    /* onMouseUpdate (e) {
      this.isSmall = e.pageY > this.viewPort.height - 100 || e.pageY < 100
    }, */

    checkVideoPlay () {
      if (this.currentRoom.video360 && this.currentRoom.video360.id && this.currentPanorama.videoElement) {
        this.currentPanorama.videoElement.play()
      }
    },

    hotspotClick (hotspot) {
      // console.log('click', hotspot.title)
      this.viewer.autoRotate = false

      if (hotspot.linkToRoom || hotspot.exit) {
        this.cleanHotspot()

        let roomfloor = ''
        for (let i = 0; i < this.$root.floors.length; i++) {
          const floor = this.$root.floors[i]
          for (let n = 0; n < floor.rooms.length; n++) {
            const room = floor.rooms[n]
            if (room.roomName._slug === hotspot.linkToRoom._slug) {
              roomfloor = floor._slug
            }
          }
        }
        this.$router.push({
          name: 'Room',
          params: { floor: roomfloor, roomName: hotspot.linkToRoom._slug }
        })
      }
    },

    initPano () {
      this.infospots = []
      this.viewer = new Viewer({
        output: 'console',
        container: this.$refs.pano,
        controlBar: false,
        autoHideInfospot: false,
        autoRotate: false
      })

      this.viewer.OrbitControls.noZoom = true
      const target = new Vector3(0, 0, 0)
      const { x, y, z } = target.normalize()
      this.viewer.camera.position.set(x, -y, -z)

      this.viewer.setCameraFov(this.zoomDefault)
      this.changePano()
    },

    async changePano () {
      this.startPreload = false
      // console.log(this.$refs)
      this.$events.emit('isLoadingStart')
      if (this.currentRoom) {
        if (this.currentRoom.showRoomBottom) {
          this.viewer.OrbitControls.minPolarAngle = 0
        } else {
          this.viewer.OrbitControls.minPolarAngle = Math.PI / 2.8
          this.viewer.OrbitControls.maxPolarAngle = Math.PI / 1.6
        }

        this.imagePano = await this.loadImage(optimizedImageURL(this.currentRoom.backgroundImage360, {
          maxWidth: (this.$root.platform.mobile && !this.$root.platform.ipad) ? 4096 : 8192,
          format: window.Modernizr && !!window.Modernizr.webp ? 'webp' : 'jpeg',
          quality: 70
        }))

        // if (this.currentRoom.background360Mobile && this.$root.useLOWRES) {
        //   this.imagePano = '/media/' + this.currentRoom.background360Mobile.id
        // }

        this.newpanorama = new ImagePanorama(this.imagePano)
        this.newpanorama.addEventListener('progress', this.onPanoProgress)

        this.newpanorama.addEventListener('enter-fade-start', () => {
          if (this.currentRoom.initialPoint && !this.fixedCamera) {
            const ip = this.currentRoom.initialPoint.split(',')
            this.viewer.tweenControlCenter(new Vector3(parseInt(ip[0]), parseInt(ip[1]), parseInt(ip[2])), 0)
          } else if (!this.fixedCamera) {
            this.viewer.tweenControlCenter(new Vector3(1000, 0, 0), 0)
          }

          if (this.currentRoom.customZoom) {
            this.viewer.setCameraFov(this.currentRoom.customZoom)
            this.currentZoom = parseInt(this.currentRoom.customZoom)
          } else {
            this.viewer.setCameraFov(this.zoomDefault)
            this.currentZoom = this.zoomDefault
          }

          let lPov = null
          if (this.currentRoom.limitPOV) {
            lPov = this.currentRoom.limitPOV
          }

          if (this.currentRoom.limitPOVMobile && this.$root.platform.mobile) {
            lPov = this.currentRoom.limitPOVMobile
          }

          if (lPov) {
            setTimeout(() => {
              const pv1 = parseFloat(lPov.split(',')[0])
              const pv2 = parseFloat(lPov.split(',')[1])

              if (pv1 < pv2) {
                this.viewer.OrbitControls.minAzimuthAngle = pv1
                this.viewer.OrbitControls.maxAzimuthAngle = pv2
              } else {
                this.viewer.OrbitControls.minAzimuthAngle = pv2
                this.viewer.OrbitControls.maxAzimuthAngle = pv1
              }
            }, 100)
          } else {
            this.viewer.OrbitControls.minAzimuthAngle = -Infinity
            this.viewer.OrbitControls.maxAzimuthAngle = Infinity
          }
          this.fixedCamera = false
        })

        const infospot1 = new InfospotSprite(0, this.infoCircle)

        this.infospots.push(infospot1)
        this.newpanorama.add(infospot1)

        const infospot2 = new InfospotSprite(0, this.infoCircle)

        this.infospots.push(infospot2)
        this.newpanorama.add(infospot2)

        if (this.currentRoom.hotspotInfo) {
          for (let i = 0; i < this.currentRoom.hotspotInfo.length; i++) {
            const hs = this.currentRoom.hotspotInfo[i]
            // const iconSrc = hs.hotspot.icon ? '/media/' + hs.hotspot.icon.id : DataImage.Info
            if (hs && hs.position) {
              const infospot = new InfospotSprite(1, this.infoCircle)

              const pos = hs.position.split(',')
              infospot.position.set(pos[0], pos[1], pos[2])
              // infospot.position.set(0, -2000, -5000)
              const panel = this.$refs['hotspot-info' + i][0]
              infospot.addHoverElement(panel, 150, false, this.$refs.panohotspot)

              this.infospots.push(infospot)
              this.newpanorama.add(infospot)
            }
          }
        }

        if (this.currentRoom.hotspotArt) {
          for (let i = 0; i < this.currentRoom.hotspotArt.length; i++) {
            const hs = this.currentRoom.hotspotArt[i]
            // const iconSrc = hs.hotspot.icon ? '/media/' + hs.hotspot.icon.id : DataImage.Info
            if (hs.position) {
              const infospot = new InfospotSprite(1, this.infoCircle)

              const pos = hs.position.split(',')
              infospot.position.set(pos[0], pos[1], pos[2])
              // infospot.position.set(0, -2000, -5000)
              const panel = this.$refs['hotspot-art' + i][0]
              infospot.addHoverElement(panel, 150, false, this.$refs.panohotspot)

              this.infospots.push(infospot)
              this.newpanorama.add(infospot)
            }
          }
        }

        // Hotspot Change Room
        if (this.currentRoom.hotspotRoom) {
          for (let i = 0; i < this.currentRoom.hotspotRoom.length; i++) {
            const hs = this.currentRoom.hotspotRoom[i]
            // const iconSrc = hs.hotspot.icon ? '/media/' + hs.hotspot.icon.id : DataImage.Info
            if (hs.position) {
              const roomspot = new InfospotSprite(1, this.infoCircle)

              const pos = hs.position.split(',')
              roomspot.position.set(pos[0], pos[1], pos[2])
              const panel = this.$refs['hotspot-room' + i][0]
              roomspot.addHoverElement(panel, 150, false, this.$refs.panohotspot)

              this.infospots.push(roomspot)
              this.newpanorama.add(roomspot)
            }
          }
        }

        // Hotspot Change Room
        if (this.currentRoom.exitPosition) {
          const exitspot = new InfospotSprite(1, this.infoCircle)

          const pos = this.currentRoom.exitPosition.split(',')
          exitspot.position.set(pos[0], pos[1], pos[2])
          const panel = this.$refs['hotspot-exit']
          exitspot.addHoverElement(panel, 150, false, this.$refs.panohotspot)

          this.infospots.push(exitspot)
          this.newpanorama.add(exitspot)
        }

        this.viewer.add(this.newpanorama)
        this.viewer.setPanorama(this.newpanorama)

        if (this.currentRoom.video360 && this.currentRoom.video360.id) {
          // setTimeout(this.showPano, 1000)
          this.showPano()
        }
      } else {
        this.$router.push({ name: 'Home' })
      }
    },

    onPanoProgress (event) {
      const percentage = event.progress.loaded / event.progress.total * 100
      // console.log(percentage)
      if (percentage >= 100) {
        this.showPano()
        // setTimeout(this.showPano, 1000)
      }
    },

    showPano () {
      setTimeout(() => {
        this.$events.emit('isLoadingEnd')
        this.preloadNextPano()
      }, 500)

      this.viewer.onWindowResize(this.viewPort.width, this.viewPort.height)
      this.currentPanorama = this.newpanorama
      if (this.$root.currentSettings._slug.indexOf('salone') > -1) {
        if (!this.$root.visitedSaloneRooms.includes(this.$route.params.roomName)) {
          this.$root.visitedSaloneRooms.push(this.$route.params.roomName)
        }
      } else {
        if (!this.$root.visitedRooms.includes(this.$route.params.roomName)) {
          this.$root.visitedRooms.push(this.$route.params.roomName)
        }
      }
    },

    async preloadNextPano () {
      this.startPreload = true

      if (this.currentRoom.hotspotInfo) {
        for (let i = 0; i < this.currentRoom.hotspotInfo.length; i++) {
          const hc = this.currentRoom.hotspotInfo[i].hotspot

          for (let n = 0; n < hc.hotspotContent.length; n++) {
            const element = hc.hotspotContent[n]
            this.$apollo.query({
              query: GetHotspotContent,
              variables: {
                slug: element._slug
              }
            })
          }
        }
      }

      if (this.currentRoom.hotspotRoom) {
        for (let i = 0; i < this.currentRoom.hotspotRoom.length; i++) {
          const hs = this.currentRoom.hotspotRoom[i]
          if (this.startPreload) {
            this.loadRoomAndImage(hs.room._slug)
          }
        }
      }
    },

    cleanHotspot () {
      if (this.currentPanorama) {
        for (let i = 0; i < this.infospots.length; i++) {
          this.infospots[i].removeHoverElement()
          this.currentPanorama.remove(this.infospots[i])
          this.infospots[i].dispose()
        }
        this.infospots = []
      }

      if (this.$refs.panohotspot) {
        this.$refs.panohotspot.innerHTML = ''
      }
    },

    cleanPano () {
      if (this.currentPanorama) {
        this.viewer.remove(this.currentPanorama)
        this.currentPanorama.dispose()
      }
    },

    updateZoom (value) {
      if (this.viewer) {
        this.viewer.setCameraFov(this.zoomDefault)
      }
    },

    async loadRoomAndImage (inslug) {
      const room = await this.$apollo.query({
        query: GetRoom,
        variables: {
          slug: inslug
        }
      })

      if (room.data.Room) {
        const { backgroundImage360 } = room.data.Room
        await this.loadImage(optimizedImageURL(backgroundImage360, {
          maxWidth: (this.$root.platform.mobile && !this.$root.platform.ipad) ? 4096 : 8192,
          format: window.Modernizr && !!window.Modernizr.webp ? 'webp' : 'jpeg',
          quality: 70
        }))
      } else {
        return false
      }
    },

    async loadImage (imageURL) {
      if (this.cacheImage[imageURL]) {
        return this.cacheImage[imageURL]
      }

      try {
        const image = await axios.get(imageURL, {
          responseType: 'blob'
        })
        this.cacheImage[imageURL] = URL.createObjectURL(image.data) //eslint-disable-line
        console.log('preload', this.cacheImage[imageURL])
        return this.cacheImage[imageURL]
      } catch (err) {
        console.log(err)
      }
    }
  },

  watch: {
    '$apollo.loading': function (value) {
      if (value) {
        this.$events.emit('isLoadingStart')
      }
    }
  },

  apollo: {
    Room: {
      query: GetRoom,
      skip () {
        return !this.$route.params.roomName
      },
      variables () {
        return {
          slug: this.$route.params.roomName
        }
      },
      async result () {
        // console.log('Get Room result:', this.Room)
        if (!this.currentRoom || this.currentRoom.roomTitle !== this.Room.roomTitle) {
          this.cleanHotspot()
          this.currentRoom = this.Room

          await this.$nextTick()

          if (!this.viewer) {
            this.initPano()
          } else {
            this.changePano()
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.panolens {
  position: fixed;
  z-index: 0;
  overflow: hidden;

  width: 100vw;
  height: 100vh;

  transition: width 0.5s, height 0.5s, margin 0.5s;

  &.map-open {
    @include breakpoint('xs-only') {
      z-index: 4;
    }
  }
}

.pano-container {
  width: 100vw;
  height: 100vh;
  transition: transform 0.5s;
  position: fixed;
}

.black-overlay {
  width: 100vw;
  height: 100vh;
  transition: transform 0.5s;
  position: fixed;
  pointer-events: none;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.1);
}

.pano-hotspot-container {
  position: absolute;
  top: 0;
  left: 0;
}

.hotspot-art,
.hotspot-info {
  z-index: 2;
  position: relative;
}

.hotspot-reference {
  position: absolute;
  display: none;
}

.zoom-slider {
  position: fixed;
  top: 0;
  right: 0;
  background-color: $c-white;
}
</style>
