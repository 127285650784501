<template>
  <div class="compass">
    <div class="round-bg"/>
    <triangle
      class="triangle"
      :style="{transform: 'rotate('+ rotation +'deg)'}"
    />
  </div>
</template>

<script>

import triangle from '@/assets/svg/triangle-light.svg'

export default {
  name: 'UserViewCompass',

  components: {
    triangle
  },

  props: {
    rotation: {
      type: Number,
      default: 90
    }
  }
}
</script>

<style lang="scss" scoped>
.compass {
  width: 40px;
  height: 40px;
}

.round-bg {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  // background-color: rgba(0, 0, 0, 0.5);
  border: 1px solid $c-brown;
}

.triangle {
  position: absolute;
  transform-origin: top center;
  top: 35px;
  left: 28px;
  width: 15px;
  height: 15px;

  fill: $c-brown;
}

</style>
