<template>
  <div>
    <v-img
      :src="`/media/${$root.currentSettings.registrationPageBgImage.id}`"
      class="bg-image"
    />
    <div class="dark-layer"></div>
    <v-container fluid class="registration-container">
      <v-row>

        <v-col cols="12" align="center">
          <MolteniLogo class="logo"/>
        </v-col>

        <v-col
          cols="12"
          sm="8" offset-sm="2"
          lg="6" offset-lg="3"
          align="center"
          class="my-16"
        >
          <p class="uppercase font-weight-bold mt-6 letter-spacing-25">{{ $root.allLanguages[$route.params.language].registrationFormPreTitleCopy }}</p>
          <h2 class="uppercase bodoni mt-6">{{ $root.allLanguages[$route.params.language].registrationFormPageTitleCopy }}</h2>
        </v-col>

        <v-col cols="12" sm="8" offset-sm="2">
          <v-row>
            <v-col cols="12" align="end" class="form-container pa-0">
              <div class="line"></div>
              <div class="form-content">

                <!-- Form title -->
                <v-form v-if="!showEmailInvalid" v-model="emailForm.valid" :lazy-validation="emailForm.lazy">
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12" offset-sm="0"
                      md="10" offset-md="1"
                      align="center"
                    >
                      <h4 class="uppercase font-weight-100 mb-6">{{ $root.allLanguages[$route.params.language].registrationFormTitleCopy }}</h4>
                      <!--<h3 class="uppercase bodoni">{{ $root.allLanguages[$route.params.language].registrationFormAfterTitleCopy }}</h3>-->
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="12" md="9" class="">
                      <h6 class="uppercase c-anthracite">{{$root.allLanguages[$route.params.language].registrationFormEmailPlaceholderCopy}}</h6>
                      <v-text-field
                        class="pt-0 bodoni form-email"
                        v-model="emailForm.loginEmail"
                        :rules="emailForm.loginEmailRules"
                        :label="''"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3" justify="left">
                      <button
                        class="form-submit-button login uppercase"
                        :disabled="!emailForm.valid"
                        :class="{ 'active' : emailForm.valid}"
                        @click.prevent="onSendLogin"
                      >
                        <h6 class="uppercase c-anthracite">{{ $root.allLanguages[$route.params.language].enter }}</h6>
                      </button>
                    </v-col>
                  </v-row>
                </v-form>
                <v-row v-else justify="center" class="align-center">
                  <h4 class="uppercase font-weight-100 mb-6">{{ $root.allLanguages[$route.params.language].registrationFormEmailInvalidTitle }}</h4>
                  <p class="uppercase font-weight-100 mb-12 align-center">{{ $root.allLanguages[$route.params.language].registrationFormEmailInvalidDescription }}</p>
                </v-row>

                <!-- First step-->
                <v-row justify="center" v-if="!showEmailInvalid">
                  <p>{{ $root.allLanguages[$route.params.language].or }}</p>
                </v-row>

                <v-row justify="center" v-show="form.currentFormStepIndex == 0" class="px-3">
                  <button
                    class="form-submit-button register uppercase"
                    @click.prevent="form.currentFormStepIndex = 1"
                  >
                    <h6 class="uppercase c-anthracite">{{ $root.allLanguages[$route.params.language].registerNow }}</h6>
                  </button>
                </v-row>

                <!-- Second step -->
                <v-row v-show="form.currentFormStepIndex == 1">
                  <v-col cols="12">
                    <v-form v-model="form.valid" :lazy-validation="form.lazy">
                      <h5 class="bold uppercase">{{ $root.allLanguages[$route.params.language].registerNow }}</h5>
                      <h5 class="uppercase my-3 c-brown">{{ form.requestType }}</h5>

                      <h6 class="mt-9 uppercase c-anthracite">{{$root.allLanguages[$route.params.language].registrationFormNamePlaceholderCopy}}</h6>
                      <v-text-field
                        class="pt-0 bodoni form-textfield"
                        v-model="form.name"
                        :rules="form.nameRules"
                        :label="''"
                        required
                      ></v-text-field>

                      <h6 class="mt-9 uppercase c-anthracite">{{$root.allLanguages[$route.params.language].registrationFormSurnamePlaceholderCopy}}</h6>
                      <v-text-field
                        class="pt-0 bodoni form-textfield"
                        v-model="form.surname"
                        :rules="form.surnameRules"
                        :label="''"
                        required
                      ></v-text-field>

                      <h6 class="mt-9 uppercase c-anthracite">{{$root.allLanguages[$route.params.language].registrationFormEmailPlaceholderCopy}}</h6>
                      <v-text-field
                        class="pt-0 bodoni form-email"
                        v-model="form.email"
                        :rules="form.emailRules"
                        :label="''"
                        required
                      ></v-text-field>

                      <h6 class="mt-9 uppercase c-anthracite">{{$root.allLanguages[$route.params.language].role}}</h6>
                      <v-select
                        class="pt-0 bodoni form-textfield"
                        v-model="form.role"
                        :items=" $root.allLanguages[$route.params.language].registrationFormPersonTypesCopies"
                        :label="''"
                      >
                        <template slot="append">
                          <ArrowDownSvg class="select-arrow" />
                        </template>
                      </v-select>

                      <h6 class="mt-9 uppercase c-anthracite">{{$root.allLanguages[$route.params.language].registrationFormCountryPlaceholderCopy}}</h6>
                      <v-select
                        class="pt-0 bodoni form-textfield"
                        v-model="form.select"
                        item-text="name"
                        :items="countryList"
                        :rules="[v => !!v || this.$root.allLanguages[this.$route.params.language].fieldRequired]"
                        :label="''"
                        required
                      ></v-select>

                      <h6 class="mt-9 uppercase c-anthracite">{{$root.allLanguages[$route.params.language].registrationFormCityPlaceholderCopy}}</h6>
                      <v-text-field
                        class="pt-0 bodoni form-email"
                        v-model="form.city"
                        :rules="form.cityRules"
                        :label="''"
                        required
                      ></v-text-field>

                      <MarkdownBlock
                        v-if="$root.allLanguages[$route.params.language].registrationFormTerms"
                        :inline="false"
                        linkify tag="div" class="mt-12 form-terms"
                        :text="$root.allLanguages[$route.params.language].registrationFormTerms" />

                      <v-row class="ma-0">
                        <RegistrationCheckbox @click.native="form.checkboxMarketing = !form.checkboxMarketing" :check="form.checkboxMarketing"></RegistrationCheckbox>
                        <MarkdownBlock
                        ref="labelcheckbox"
                        v-if="$root.allLanguages[$route.params.language].registrationFormMarketing"
                        :inline="false"
                        linkify tag="div" class="ml-3 label-checkbox"
                        :text="$root.allLanguages[$route.params.language].registrationFormMarketing" />
                      </v-row>
                      <v-row class="ma-0">
                        <MarkdownBlock
                        ref="labelcheckbox"
                        v-if="$root.allLanguages[$route.params.language].registrationFormMarketingDetail"
                        :inline="false"
                        linkify tag="div"
                        :class="{'open': showDetailMarketing}"
                        class="ml-8 label-checkbox detail"
                        :text="$root.allLanguages[$route.params.language].registrationFormMarketingDetail" />
                      </v-row>

                      <v-row class="ma-0">
                        <RegistrationCheckbox @click.native="form.checkboxProfile = !form.checkboxProfile" :check="form.checkboxProfile"></RegistrationCheckbox>
                        <MarkdownBlock
                        ref="labelcheckbox"
                        v-if="$root.allLanguages[$route.params.language].registrationFormProfile"
                        :inline="false"
                        linkify tag="div" class="ml-3 label-checkbox"
                        :text="$root.allLanguages[$route.params.language].registrationFormProfile" />
                      </v-row>
                      <v-row class="ma-0">
                        <MarkdownBlock
                        ref="labelcheckbox"
                        v-if="$root.allLanguages[$route.params.language].registrationFormProfileDetail"
                        :inline="false"
                        linkify tag="div"
                        :class="{'open': showDetailProfile}"
                        class="ml-8 label-checkbox detail"
                        :text="$root.allLanguages[$route.params.language].registrationFormProfileDetail" />
                      </v-row>

                      <v-row class="ma-0">
                        <RegistrationCheckbox @click.native="form.checkboxMarketingGroup = !form.checkboxMarketingGroup" :check="form.checkboxMarketingGroup"></RegistrationCheckbox>
                        <MarkdownBlock
                        ref="labelcheckbox"
                        v-if="$root.allLanguages[$route.params.language].registrationFormMarketingGroup"
                        :inline="false"
                        linkify tag="div" class="ml-3 label-checkbox"
                        :text="$root.allLanguages[$route.params.language].registrationFormMarketingGroup" />
                      </v-row>
                      <v-row class="ma-0">
                        <MarkdownBlock
                        ref="labelcheckbox"
                        v-if="$root.allLanguages[$route.params.language].registrationFormMarketingGroupDetail"
                        :inline="false"
                        linkify tag="div"
                        :class="{'open': showDetailMarketingGroup}"
                        class="ml-8 label-checkbox detail"
                        :text="$root.allLanguages[$route.params.language].registrationFormMarketingGroupDetail" />
                      </v-row>
                      <p class="error c-red align-center" v-show="showError">{{$root.allLanguages[$route.params.language].registrationError}}</p>
                      <button
                        class="form-submit-button uppercase mt-16"
                        :class="{ 'active' : form.valid}"
                        :disabled="!form.valid"
                        @click.prevent="onSendForm"
                      >
                        <h6 class="uppercase c-anthracite">{{ $root.allLanguages[$route.params.language].registrationFormSubmitButtonCopy }}</h6>
                      </button>
                    </v-form>
                  </v-col>
                </v-row>
                <v-row v-show="form.currentFormStepIndex == 2">
                  <v-col
                    cols="12"
                    sm="10" offset-sm="1"
                    md="8" offset-md="2"
                    lg="6"
                    offset-lg="3"
                    align="center"
                  >
                    <div>
                      <h4 class="xl bodoni uppercase">{{ $root.allLanguages[$route.params.language].registrationFormThankCopy }}</h4>
                      <h4 class="uppercase font-weight-normal mb-10">{{ form.name }}!</h4>
                      <p class="mb-10">
                        {{ $root.allLanguages[$route.params.language].registrationFormThankMessageFirstPartCopy }}
                        <strong class="font-weight-bold">{{ form.email }}</strong>
                        {{ $root.allLanguages[$route.params.language].registrationFormThankMessageSecondPartCopy }}
                      </p>
                      <p>
                        {{ $root.allLanguages[$route.params.language].registrationFormProblemMessageCopy }}
                        <strong class="send-again-link font-weight-bold uppercase" @click="form.currentFormStepIndex = 1">
                          {{ $root.allLanguages[$route.params.language].registrationFormSendAgainCopy }}
                        </strong>
                      </p>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import RegistrationCheckbox from '../atoms/RegistrationCheckbox.vue'
import axios from 'axios'

import MolteniLogo from '@/assets/svg/molteni-logo-white.svg'
// import ProfileButton from '@/atoms/RegistrationProfileSelectionButton.vue'
import enCountries from '@/assets/data/en-countries.json'
import itCountries from '@/assets/data/it-countries.json'
import cnCountries from '@/assets/data/cn-countries.json'
import { MarkdownBlock } from '@monogrid/vue-lib'
import ArrowDownSvg from '@/assets/svg/arrow-down.svg'

export default {
  name: 'RegistrationScreen',
  data () {
    return {
      enCountry: enCountries,
      itCountry: itCountries,
      cnCountry: cnCountries,
      showError: false,
      showEmailInvalid: false,
      showDetailMarketing: false,
      showDetailProfile: false,
      showDetailMarketingGroup: false,
      emailForm: {
        valid: true,
        lazy: false,
        loginEmail: '',
        loginEmailRules: [
          v => !!v || this.$root.allLanguages[this.$route.params.language].fieldRequired,
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
        ]
      },
      form: {
        currentFormStepIndex: 0,
        requestType: '',
        valid: true,
        name: '',
        role: null,
        nameRules: [
          v => !!v || this.$root.allLanguages[this.$route.params.language].fieldRequired,
          v => (v && v.length <= 20) || 'Name must be less than 20 characters'
        ],
        surname: '',
        surnameRules: [
          v => !!v || this.$root.allLanguages[this.$route.params.language].fieldRequired,
          v => (v && v.length <= 20) || 'Surname must be less than 20 characters'
        ],
        city: '',
        cityRules: [
          v => !!v || this.$root.allLanguages[this.$route.params.language].fieldRequired
        ],
        email: '',
        emailRules: [
          v => !!v || this.$root.allLanguages[this.$route.params.language].fieldRequired,
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid'
        ],
        select: null,
        countries: [
          'Italy',
          'Uk'
        ],
        checkboxMarketing: false,
        checkboxProfile: false,
        checkboxMarketingGroup: false,
        lazy: false
      }
    }
  },

  mounted () {
    const checkboxLinks = document.querySelectorAll('.label-checkbox a')
    for (let i = 0; i < checkboxLinks.length; i++) {
      const link = checkboxLinks[i]
      link.onclick = (e) => {
        console.log(e.target.hash)
        if (e.target.hash === '#marketing') {
          this.showDetailMarketing = !this.showDetailMarketing
        }
        if (e.target.hash === '#profiling') {
          this.showDetailProfile = !this.showDetailProfile
        }
        if (e.target.hash === '#group') {
          this.showDetailMarketingGroup = !this.showDetailMarketingGroup
        }
        return false
      }
    }

    // prefill country

    if (this.$root.userCountryCode) {
      for (let i = 0; i < this.countryList.length; i++) {
        const element = this.countryList[i]
        if (element.alpha2 === this.$root.userCountryCode) {
          this.form.select = element.name
        }
      }
    }
  },
  components: {
    MolteniLogo, MarkdownBlock, RegistrationCheckbox, ArrowDownSvg
  },

  computed: {
    countryList () {
      // console.log(this[this.$route.params.language + 'Country'])
      return this[this.$route.params.language + 'Country']
    }
  },
  methods: {
    async onSendLogin () {
      // this.$emit('onAuthorized', this.emailForm.loginEmail)
      this.$events.emit('isLoadingStart')
      const formData = {
        requestTime: Date.now(),
        email: this.emailForm.loginEmail,
        owner: 'lorenza.preda@molteni.it'
      }
      const result = await axios.post('/api/validate', formData) // eslint-line-disable
      this.$events.emit('isLoadingEnd')
      console.log(result)

      if (result.data.result) {
        this.$emit('onAuthorized', result.data.contactId)
      } else {
        this.showEmailInvalid = true
      }
    },
    async onSendForm () {
      let userTypeInEnglish = ''
      const usersArray = this.$root.allLanguages[this.$route.params.language].registrationFormPersonTypesCopies
      const userTypeIndex = usersArray.indexOf(this.form.role)
      if (this.form.role) {
        userTypeInEnglish = this.$root.allLanguages.en.registrationFormPersonTypesCopies[userTypeIndex].toLowerCase()
      }

      let countryCode = this.form.select

      for (let i = 0; i < this.countryList.length; i++) {
        const element = this.countryList[i]
        if (element.name === this.form.select) {
          countryCode = element.alpha2
        }
      }
      // const mtags = ['VIRTUALTOUR2022']

      // if (this.form.checkboxMarketingGroup) {
      //   mtags.push('privacy_marketinggruppomolteni')
      // }

      // if (this.form.checkboxProfile) {
      //   mtags.push('privacy_profilazione')
      // }

      // if (this.form.checkboxMarketing) {
      //   mtags.push('privacy_marketing')
      // }

      let userType = ''
      switch (userTypeIndex) {
        case 0:
          userType = 'A&D'
          break
        case 1:
          userType = 'BLOGGER/INFLUENCER'
          break
        case 2:
          userType = 'CONSUMER/PRIVATE'
          break
        case 3:
          userType = 'DEALER'
          break
        case 4:
          userType = 'CONTRACTOR/DEVELOPER/REAL ESTATE/BUILDER'
          break
        case 5:
          userType = 'INSTITUTION'
          break
        case 6:
          userType = 'CORPORATE'
          break
        case 7:
          userType = 'FORNITORE/CONSULENTE'
          break
      }
      const formData = {
        options: { iddatabase: 1 },
        values: {
          EMAIL: this.form.email,
          NAME: this.form.name,
          SURNAME: this.form.surname,
          TIPOLOGIACONTATTO: userType,
          STATO: countryCode,
          CITTA: this.form.city,
          VIRTUAL_TOUR: true,
          ORIGIN: 'VirtualTour2022',
          // DATA_VIRTUAL_TOUR: new Date().toISOString(),
          PRIVACY_MARKETING_MOLTENI: this.form.checkboxMarketing,
          PRIVACY_PROFILING_MOLTENI: this.form.checkboxProfile,
          PRIVACYMARKETINGGROUP: this.form.checkboxMarketingGroup
        }
        // requestTime: Date.now(),
        // async: true,
        // contact: {
        //   email: this.form.email,
        //   name: this.form.name + ' ' + this.form.surname,
        //   address: {
        //     city: this.form.city,
        //     country: countryCode
        //   }
        // },
        // owner: 'lorenza.preda@molteni.it',
        // tags: mtags,
        // forceOptIn: true,
        // forceOptOut: false,
        // properties: {
        //   'custom.usertype': userTypeInEnglish
        // },
        // useApiDoubleOptIn: true,
        // lang: this.$route.params.language,
        // consentDetails: [
        //   {
        //     consentName: 'MARKETINGGRUPPOMOLTENI',
        //     consentAccept: this.form.checkboxMarketingGroup,
        //     agreementDate: Date.now()
        //   },
        //   {
        //     consentName: 'PROFILAZIONE',
        //     consentAccept: this.form.checkboxProfile,
        //     agreementDate: Date.now()
        //   },
        //   {
        //     consentName: 'MARKETING',
        //     consentAccept: this.form.checkboxMarketing,
        //     agreementDate: Date.now()
        //   }
        // ]
      }

      console.log('sending data:', formData)
      this.showError = false
      this.$events.emit('isLoadingStart')

      const result = await axios.post('/api/register', formData) // eslint-line-disable
      this.$events.emit('isLoadingEnd')
      console.log(result)

      if (result.data.success && this.form.currentFormStepIndex < 2) {
        this.$emit('onAuthorized', this.form.email, userTypeInEnglish)
        // this.form.currentFormStepIndex++
      } else {
        this.showError = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.registration-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  overflow-x: hidden;
  overflow-y: auto;
}

.bg-image {
  overflow: hidden;
  position: fixed;
  width: 100vw;
  height: 100vh;
}

.form-container {
  position: relative;
  margin: 8vh auto;
  text-align: left;
  background-color: $c-light-grey;
}

::v-deep .form-textfield input {
  text-transform: uppercase;
  font-size: 20px;
}

::v-deep .form-email input {
  font-size: 20px;
}

::v-deep .form-textfield .v-select__selection {
  text-transform: uppercase;
  font-size: 20px;
}

::v-deep .v-text-field__details .error--text {
  font-family: 'HelveticeNeue' !important;
  font-style: italic;
  color: $c-red !important;
}

::v-deep .v-input--is-dirty {
  ::before {
    border-color: $c-brown !important;
  }
}

::v-deep a {
  color: $c-black;
  text-decoration: underline;
}

.line {
  width: 0;
  height: 160px;
  border-left: solid 1px $c-brown;
  position: absolute;
  top: -50px;
  left: 50%;
  transform: translate(-50%, -50%);

  @include breakpoint('sm-and-down') {
    top: -30px;
    height: 100px;
  }
}

.form-content {
  color: $c-black;
  padding: 64px 20%;

  @include breakpoint('sm-and-down') {
    padding: 64px 24px;
  }
}

::v-deep .form-terms p {
  font-size: 12px !important;
}

::v-deep .label-checkbox p {
  font-size: 12px !important;
}

::v-deep .label-checkbox {
  max-width: calc(100% - 40px);
}

::v-deep .detail {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.5s;

  &.open {
    max-height: 200px;
  }
}

.form-submit-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 158px;
  height: 55px;
  margin: 0 auto;
  color: $c-black;
  letter-spacing: 0.6;
  transition: all 0.5s ease-in-out;
  background-color: $c-brown;

  &.register {
    border: 2px solid $c-black;
    background-color: transparent;
  }

  &.register,
  &.login {
    width: 100%;
    margin: 0;
  }

  &:not(:disabled) {
    &:hover {
      background-color: $c-brown;
      border-style: none;

      h6 {
        color: $c-white;
      }
    }
  }

  &:disabled {
    opacity: 0.5;

    &.login {
      border-style: none;
      color: $c-white;
    }

    h6 {
      color: $c-white;
    }
  }

  &.active {
    background-color: $c-brown;
    border-style: none;

    h6 {
      color: $c-white;
    }
  }
}

.send-again-link {
  color: $c-brown;
  cursor: pointer;
}

h5,
h6 {
  line-height: 1.75;
  letter-spacing: 1.78px;
}

h6 {
  font-weight: normal;
}

.form-email {
  ::v-deep .v-input__slot::after,
  ::v-deep .v-input__slot::before {
    border-color: $c-brown !important;
  }
}

.select-arrow {
  width: 20px;
  height: 20px;
}
</style>
