<template>
  <v-app id="app" class="app" v-show="!platform.prerenderer && isReady" ref="mainapp">

    <Panolens360
      ref="pano"
      v-if="$route.name !== 'Cookie' && allLoaded && isAuthorized"
      v-show="isPano"
    />

    <transition name="fade">
      <router-view v-if="(allLoaded && isAuthorized) || $route.name === 'Cookie'" />
    </transition>

    <ErrorScreen v-if="allLoaded && !isAuthorized && !isNewUser"></ErrorScreen>

    <RegistrationScreen v-if="!comingSoon && allLoaded && !isAuthorized && isNewUser && $route.name !== 'Cookie'" @onAuthorized="emailAuthorized"></RegistrationScreen>
    <ComingSoonScreen v-if="comingSoon && allLoaded && !isAuthorized && isNewUser && $route.name !== 'Cookie'"></ComingSoonScreen>

    <CreditScreen v-if="allLoaded && isAuthorized && showCredit"></CreditScreen>

    <transition name="fade">
      <HotSpotOverlay v-if="isReady && allLoaded && showHotspotContent && isAuthorized"/>
    </transition>

    <!-- Loading -->
    <div v-if="isLoading" class="preloader fullscreen transparent-black">
      <div class="loader-6 center"><span></span></div>
    </div>

    <BannerCookie></BannerCookie>
    <!--<VersionInfo
      v-if="$root.process.env.SERVICE_STAGE !== 'production'"
      :version="$root.process.env.GIT_VERSION"
      :commit-hash="$root.process.env.GIT_COMMIT_HASH"
      />-->

    <RotateDevice v-if="isReady && allLoaded"></RotateDevice>
  </v-app>
</template>

<script>
import ComingSoonScreen from './organisms/ComingSoonScreen.vue'
import CreditScreen from './organisms/CreditScreen.vue'
import RegistrationScreen from './organisms/RegistrationScreen.vue'
import RotateDevice from './organisms/RotateDevice.vue'
import BannerCookie from './atoms/BannerCookie.vue'
import ErrorScreen from './organisms/ErrorScreen.vue'
// import { VersionInfo } from '@monogrid/vue-lib'
import { Platform } from '@monogrid/js-utils'
import shareImage from '@/assets/img/share.jpg'
import Panolens360 from '@/molecules/Panolens360'
import axios from 'axios'

import SiteSetting from '@/graphql/ListSiteSetting.gql'
import ListTranslation from '@/graphql/ListTranslation.gql'
import ListFloor from '@/graphql/ListFloor.gql'
import password from '@/assets/data/password.json'

/* Preloader gif */
import preloaderGif from '@/assets/img/preloader.gif'

/* Hotspot */
import HotSpotOverlay from '@/molecules/HotspotOverlayContent.vue'

export default {
  name: 'App',

  components: {
    Panolens360,
    // VersionInfo,
    HotSpotOverlay,
    ErrorScreen,
    BannerCookie,
    RotateDevice,
    RegistrationScreen,
    CreditScreen,
    ComingSoonScreen
  },

  data: function () {
    return {
      /* Preloader */
      showCredit: false,
      preloaderGif,
      userpassword: password,
      isLoading: false,
      isNewUser: false,
      isAuthorized: false, // Set false to test registration route
      // this flag is used to show the app once the preloading
      // (if present) is finished
      isReady: false,
      isAllSiteSettingsLoaded: false,
      isAllTranslationsLoaded: false,
      isFloorLoaded: false,
      // platform, accessible with an import statement
      // or by using this.$root.platform
      // NOTE: import statement is preferred
      platform: Platform,
      showHotspotContent: false,
      comingSoon: false
    }
  },

  mounted: function () {
    this.$root.visitedRooms = []
    this.$root.visitedSaloneRooms = []
    // set this.isReady = true
    // when all stuff that needs to be loaded for the app is loaded
    // if you need to preload stuff, delete this line and set to true later
    this.isReady = true
    /* Preloader */
    this.$events.on('isLoadingStart', () => { this.isLoading = true })
    this.$events.on('isLoadingEnd', () => { this.isLoading = false })

    this.$events.on('showCredit', () => { this.showCredit = true })
    this.$events.on('hideCredit', () => { this.showCredit = false })
    this.$events.on('scrollToTop', () => {
      console.log(this.$refs.mainapp)
      this.$refs.mainapp.$el.scrollTop = 0
    })
    // console.log('MOUNTED', this.$route)
    if (!this.$root.userCountryCode) {
      const options = {
        method: 'GET',
        url: 'https://telize-v1.p.rapidapi.com/location',
        headers: {
          'x-rapidapi-key': 'd5eea42185msh846659cd829ef6ep102ea5jsnedbbed87c2c5',
          'x-rapidapi-host': 'telize-v1.p.rapidapi.com'
        }
      }

      axios.request(options).then((response) => {
        console.log('response', response)
        if (!this.$root.userCountryCode) {
          this.$root.userCountryCode = response.data.country_code.toLowerCase()
          if (this.$root.userCountryCode === 'us') {
            this.$root.usVersion = true
          }
        }

        return response
      }).catch((error) => {
        console.error(error)
      })
    }

    this.checkRoute()
  },

  // Query
  apollo: {
    // Site settings
    SiteSettings: {
      query: SiteSetting,
      async result () {
        console.log(this.SiteSettings)
        if (!this.isAllSiteSettingsLoaded) {
          this.$root.arraySettings = this.SiteSettings.items
          for (let i = 0; i < this.$root.arraySettings.length; i++) {
            const element = this.$root.arraySettings[i]
            if (element._slug.indexOf('salone') === -1 && element._slug.indexOf('design') === -1) {
              this.$root.collectionSettings = element
            } else {
              this.$root.saloneSettings = element
            }
          }

          this.$root.currentSettings = this.$root.collectionSettings
          // console.log('Settings loaded', this.$root.currentSettings)
          this.comingSoon = this.$root.collectionSettings.comingSoon
          this.checkFloor()
          this.isAllSiteSettingsLoaded = true
        }
      }
    },
    // All translations
    ListTranslation: {
      query: ListTranslation,
      async result () {
        this.$root.allLanguages = {}
        for (let i = 0; i < this.ListTranslation.items.length; i++) {
          const element = this.ListTranslation.items[i]
          this.$root.allLanguages[element._slug] = element
        }
        // console.log('Translation loaded', this.$root.allLanguages)
        // console.log('DEBUG: CURRENT LANG TRANSLATIONS:', this.$root.allLanguages[this.$route.params.language])

        this.isAllTranslationsLoaded = true
      }
    },
    Floors: {
      query: ListFloor,
      async result () {
        this.$root.allFloors = this.Floors.items
        this.checkFloor()
        this.isFloorLoaded = true
        // console.log('Floors', this.$root.floors)
      }
    }
  },

  methods: {
    checkFloor () {
      if (this.$root.arraySettings && this.$root.allFloors) {
        this.$root.allRooms = 0
        this.$root.allSaloneRooms = 0

        for (let i = 0; i < this.$root.arraySettings.length; i++) {
          const setting = this.$root.arraySettings[i]

          for (let n = 0; n < setting.floors.length; n++) {
            const floor = setting.floors[n]

            if (floor._slug === this.$route.params.floor && this.$route.params.floor) {
              // get correct setting and floor
              this.$root.currentSettings = setting
              this.$root.updateFloorBySetting()
            }

            for (let r = 0; r < floor.rooms.length; r++) {
              const room = floor.rooms[r]
              if (room.positionInMap.indexOf('stair') === -1) {
                if (setting._slug.indexOf('salone') === -1 && setting._slug.indexOf('design') === -1) {
                  this.$root.allRooms++
                } else {
                  this.$root.allSaloneRooms++
                }
              }
            }
          }
        }

        console.log('allrooms: ', this.$root.allRooms, ' - salone rooms:', this.$root.allSaloneRooms)
      }
    },
    emailAuthorized (email, userType) {
      this.isAuthorized = true
      this.$root.userType = userType || 'private'
      this.$root.userEmail = email
      document.cookie = 'moltenitoken=useremail; max-age=15552000;'
      if (this.$ga) {
        this.$ga.event({
          eventCategory: 'molteni experience',
          eventAction: 'authorized',
          eventLabel: this.$root.userType,
          eventValue: this.$root.userEmail
        })
      }
      // document.cookie = 'molteniemail=' + email + '; max-age=15552000;'
    },

    async checkRoute () {
      // hotspot content
      if (this.$route.params.hotspotContent) {
        // console.log('Open hotspot', this.$route.params.hotspotContent)
        this.showHotspotContent = true
      } else {
        this.showHotspotContent = false
      }

      // add test country
      if (this.$route.query.test_country) {
        this.$root.userCountryCode = this.$route.query.test_country
        if (this.$root.userCountryCode === 'us') {
          this.$root.usVersion = true
        }
      }

      // login add default token to enable login even without token

      if (!this.isAuthorized) {
        let userToken = ''
        if (document.cookie && document.cookie !== '') {
          const cookieMolteni = document.cookie.split('; ').find(row => row.startsWith('moltenitoken'))
          if (cookieMolteni) {
            userToken = cookieMolteni.split('=')[1]
          }
        }

        if (this.$route.query.token) {
          userToken = this.$route.query.token
        }
        if (userToken !== '' && (userToken === 'useremail' || userToken === 'userbanner' || userToken === '1110173111')) {
          this.isAuthorized = true
          this.$root.userType = 'private'
          document.cookie = 'moltenitoken=' + userToken + '; max-age=15552000;'
        } else if (userToken !== '' && userToken.indexOf('rt') > -1) {
          // token generated from frontend ex: rt0ts1597828134081ddd
          const userTypeIndex = userToken[userToken.indexOf('rt') + 2]
          console.log('userType', userTypeIndex)
          const result = await axios.post('https://portal.molteni.it/Contacts/MolteniApi/verifyCustomer?loginToken=' + userToken)
          console.log(result)
          if (result.data.Success) {
            this.isAuthorized = true
            document.cookie = 'moltenitoken=' + userToken + '; max-age=15552000;'
            console.log('userType', parseInt(userTypeIndex))
            if (parseInt(userTypeIndex) === 0) {
              this.$root.userType = 'press'
            }

            if (parseInt(userTypeIndex) === 1) {
              this.$root.userType = 'architects'
            }

            if (parseInt(userTypeIndex) === 2) {
              this.$root.userType = 'private'
            }

            if (parseInt(userTypeIndex) === 3) {
              this.$root.userType = 'dealer'
            }

            console.log('userType backend:', this.$root.userType)
          }
        } else if (userToken !== '') {
          this.isNewUser = false
          for (let i = 0; i < this.userpassword.press.length; i++) {
            const token = this.userpassword.press[i]

            if (userToken === token) {
              this.isAuthorized = true
              document.cookie = 'moltenitoken=' + userToken + '; max-age=15552000;'
              this.$root.userType = 'press'
              // console.log('user authorized')
            }
          }
          for (let i = 0; i < this.userpassword.architects.length; i++) {
            const token = this.userpassword.architects[i]

            if (userToken === token) {
              this.isAuthorized = true
              document.cookie = 'moltenitoken=' + userToken + '; max-age=15552000;'
              this.$root.userType = 'architects'
              // console.log('user authorized')
            }
          }
          for (let i = 0; i < this.userpassword.admin.length; i++) {
            const token = this.userpassword.admin[i]

            if (userToken === token) {
              this.isAuthorized = true
              document.cookie = 'moltenitoken=' + userToken + '; max-age=15552000;'
              this.$root.userType = 'admin'
              // console.log('user authorized')
            }
          }
          console.log('userType frontend:', this.$root.userType)
        } else if (this.$root.userEmail === '') {
          // uncomment this for registration
          this.isAuthorized = false
          this.isNewUser = true

          /* comment this line below for registration
          if (this.$route.query.registration) {
            this.isAuthorized = false
            this.isNewUser = true
          } else {
            this.isAuthorized = true
            this.$root.userType = 'private'
          } */
        }

        if (this.isAuthorized) {
          if (this.$ga) {
            this.$ga.event({
              eventCategory: 'molteni experience',
              eventAction: 'authorized',
              eventLabel: this.$root.userType,
              eventValue: userToken
            })
          }
        }
      }
    }
  },

  computed: {
    allLoaded () {
      return this.isReady && this.isAllSiteSettingsLoaded && this.isAllTranslationsLoaded && this.isFloorLoaded
    },
    isPano () {
      return this.$route.name !== 'Home'
    }
  },

  watch: {
    '$route' (value) {
      // console.log('ROUTE:', value)
      this.checkRoute()
    },
    isReady: function (value) {
      // hide / show preloader
      this.$root.preloader.visible = !value
    }
  },

  metaInfo: function () {
    return {
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: 'Molteni&C|Dada Virtual Experience',
      // all titles will be injected into this template
      titleTemplate: '%s Molteni&C|Dada Virtual Experience',
      base: { href: '/' },
      meta: [
        { 'http-equiv': 'X-UA-Compatible', content: 'IE=edge' },
        { name: 'apple-mobile-web-app-capable', content: 'yes' },
        { name: 'mobile-web-app-capable', content: 'yes' },
        // twitter
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:site', content: '@moltenidada' },
        { name: 'twitter:creator', content: '@moltenidada' },
        // generic description
        { vmid: 'description', name: 'description', content: 'An immersive journey to discover the 2021 collection' },
        // og tags
        { vmid: 'ogtype', property: 'og:type', content: 'website' },
        { vmid: 'ogurl', property: 'og:url', content: 'https://' + this.$root.process.env.SERVICE_TARGET_DOMAIN },
        { vmid: 'ogtitle', property: 'og:title', itemprop: 'name', content: 'Molteni&C|Dada Virtual Experience' },
        { vmid: 'ogdescription', property: 'og:description', content: 'An immersive journey to discover the 2021 collection' },
        { vmid: 'ogimage', property: 'og:image', content: 'https://' + this.$root.process.env.SERVICE_TARGET_DOMAIN + shareImage },
        { vmid: 'ogimagetype', property: 'og:image:type', content: 'image/jpeg' },
        { vmid: 'ogimageheight', property: 'og:image:width', content: '1920' },
        { vmid: 'ogimagewidth', property: 'og:image:height', content: '1205' }
      ]
    }
  }
}
</script>

<style lang="scss">

@font-face {
  font-family: 'HelveticeNeue';
  font-style: normal;
  font-weight: 300;
  src:
    url('assets/fonts/HelveticaNeue.woff2') format('woff2'),
    url('assets/fonts/HelveticaNeue.woff') format('woff');
}

@font-face {
  font-family: 'HelveticeNeue';
  font-style: bold;
  font-weight: bold;
  src:
    url('assets/fonts/HelveticaNeueMedium.woff2') format('woff2'),
    url('assets/fonts/HelveticaNeueMedium.woff') format('woff');
}

@font-face {
  font-family: 'Bodoni';
  font-style: normal;
  font-weight: 400;
  src:
    url('assets/fonts/BodoniW01Book.woff2') format('woff2'),
    url('assets/fonts/BodoniW01Book.woff') format('woff');
}

html,
body {
  overflow: hidden !important;

  .theme--light.v-application {
    color: $c-white;
    font-family: 'HelveticeNeue';
    letter-spacing: normal;
    line-height: normal;
    font-weight: 300;
    overflow-y: scroll;
  }
}

.app {
  background-color: $c-black;
  overflow: hidden;
}

a {
  text-decoration: none;
  color: $c-brown;
}

p {
  font-size: 18px;
  line-height: 1.67;

  @include breakpoint('md-and-down') {
    font-size: 14px;
  }
}

h1 {
  font-size: 72px;

  @include breakpoint('lg-and-down') {
    font-size: 50px;
    line-height: 0.9;
  }

  @include breakpoint('md-and-down') {
    font-size: 36px;
    line-height: 0.9;
  }

  @include breakpoint('sm-and-down') {
    font-size: 36px;
  }
}

h2 {
  font-size: 60px;

  @include breakpoint('md-and-down') {
    font-size: 48px;
  }

  @include breakpoint('sm-and-down') {
    font-size: 36px;
  }

  @include breakpoint('xs-only') {
    font-size: 28px;
  }
}

h3 {
  font-size: 48px;

  @include breakpoint('md-and-down') {
    font-size: 36px;
  }
}

h4 {
  font-size: 24px;
  letter-spacing: normal;

  @include breakpoint('md-and-down') {
    font-size: 22px;
  }

  @include breakpoint('sm-and-down') {
    font-size: 20px;
  }

  &.xl {
    font-size: 36px;

    @include breakpoint('lg-and-down') {
      font-size: 32px;
    }

    @include breakpoint('md-and-down') {
      font-size: 22px;
    }

    @include breakpoint('sm-and-down') {
      font-size: 22px;
    }
  }
}

h5 {
  font-size: 16px;

  @include breakpoint('md-and-down') {
    font-size: 14px;
  }
}

h6 {
  font-size: 14px;
}

span {
  font-size: 12px;
}

strong {
  font-weight: bold !important;
}

.iphone .iphone-hide {
  display: none;
}

.ipad .iphone-hide {
  display: none;
}

.display-none {
  display: none;
}

.line-height-15 {
  line-height: 1.5;
}

.svg-fill-white {
  fill: $c-white;

  path {
    fill: $c-white;
  }
}

.svg-stroke-white {
  stroke: $c-white;

  path {
    stroke: $c-white;
  }
}

.svg-fill-brown {
  fill: $c-brown;

  path {
    fill: $c-brown;
  }
}

.svg-fill-none {
  fill: none;

  path {
    fill: none;
  }
}

.svg-stroke-brown {
  stroke: $c-brown;

  path {
    stroke: $c-brown;
  }
}

.svg-fill-black {
  fill: $c-black;

  path {
    fill: $c-black;
  }
}

.svg-stroke-black {
  stroke: $c-black;

  path {
    stroke: $c-black;
  }
}

.width-100 {
  width: 100%;
}

.height-100 {
  height: 100%;
}

.height-80 {
  height: 80%;
}

.pointer {
  cursor: pointer;
}

.c-black {
  color: $c-black;
}

.c-anthracite {
  color: $c-anthracite;
}

.c-brown {
  color: $c-brown;
}

.c-red {
  color: $c-red;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}

.align-left {
  text-align: left;
}

.font-weight-bold {
  font-weight: bold;
}

.font-weight-normal {
  font-weight: normal;
}

.font-weight-100 {
  font-weight: 100;
}

.bodoni {
  font-family: 'Bodoni';
  font-weight: 100;
}

.uppercase {
  text-transform: uppercase;
}

.italic {
  font-style: italic;
}

.bold {
  font-weight: bold;
}

.extra-light {
  font-weight: 100;
}

.inline-block {
  display: inline-block;
  vertical-align: middle;
}

.letter-spacing-25 {
  letter-spacing: 2.5px;
}

.letter-spacing-20 {
  letter-spacing: 2px;
}

.letter-spacing-15 {
  letter-spacing: 1.5px;
}

.letter-spacing-12 {
  letter-spacing: 1.2px;
}

.letter-spacing-10 {
  letter-spacing: 1px;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dark-layer {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 1;
  background-color: $c-black-85;
}

.fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.preloader {
  z-index: 10 !important;
}

.transparent-black {
  background-color: rgba(0, 0, 0, 0.6);
  color: $c-white;
  z-index: 2;
}

.transparent-black-85 {
  background-color: rgba(0, 0, 0, 0.85);
  color: $c-white;
  z-index: 2;
}

.center-absolute {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.logo {
  max-width: 220px !important;
  width: 100% !important;
  height: auto !important;
}

.opacity-3 {
  opacity: 0.3;
}

#CybotCookiebotDialogBody { //stylelint-disable-line
  max-width: 80% !important;
}

#CybotCookiebotDialogDetailBody { //stylelint-disable-line
  max-width: 80% !important;
}

#CybotCookiebotDialog { //stylelint-disable-line
  font-family: 'HelveticeNeue' !important;
}

.fade-leave-active,
.fade-enter-active {
  transition: opacity 1s $ease-out-quint;
}

.fade-enter,
.fade-leave-to {
  opacity: 0 !important;
}

@keyframes pulse {
  0% { transform: translate(-50%, -50%) scale(0);
    opacity: 1; }

  100% { transform: translate(-50%, -50%) scale(1);
    opacity: 0; }
}

</style>
