<template>
  <v-container fluid class="content-gallery px-0 ma-0" :class="[{ 'gallery-fullscreen': isFullscreen }, 'bg-' + $root.roomClass]">
    <IconFullscreen v-if="!isFullscreen" class="icon-fullscreen svg-fill-white pointer" @click="onFullscreen(true)"></IconFullscreen>
    <div v-if="isFullscreen" class="button-gallery-close">
      <IconClose class="icon-close svg-fill-white pointer" @click="onFullscreen(false)"></IconClose>
    </div>

    <v-row ref="container" class="gallery pa-0 ma-0" :class="'bg-' + $root.roomClass">
      <v-col cols="12" md="12" class="pa-0">
        <v-row class="mb-3 mt-0 description-container">
          <div v-for="(image, index) in content.image"
            class="text"
            :class="getImageClass(index)"
            :key="'img'+index"
          >
            <p
              v-show="content[$route.params.language + 'Caption'] &&
              content[$route.params.language + 'Caption'][index] &&
              content[$route.params.language + 'Caption'][index] !== '' &&
              (content[$route.params.language + 'Caption'][index][0] !== ' ' || content[$route.params.language + 'Caption'][index].length > 3) &&
              content[$route.params.language + 'Caption'][index][0] !== null &&
              content[$route.params.language + 'Caption'][index][0] !== 'null'"
              class="description uppercase mb-0">{{content[$route.params.language + 'Caption'] ? content[$route.params.language + 'Caption'][index] : ''}}</p>
          </div>
        </v-row>
        <v-row v-drag="onDrag" @mouseup="finishDrag" class="fill-height">
          <div class="image-container" :style="{'transform' : 'translateX( ' + galleryX + 'px )'}">
            <img
              v-for="(image, index) in content.image"
              :src="'/media/'+image.id"
              :key="'img'+index"
              :ref="'photo'+index"
              draggable="false"
              @load="loaded(index)"
              @mousedown="onImageDown"
              @click="onImageClick($event, index);">
          </div>
          <div class="nav-container" v-if="content.image.length > 1">
            <div v-for="(image, index) in content.image"
              class="nav"
              :class="{'active': index === currentImage}"
              :key="'img'+index"
              @click="onNavClick(index)"
            ></div>
        </div>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Viewport } from '@monogrid/vue-lib'
import IconFullscreen from '@/assets/svg/expand.svg'
import IconClose from '@/assets/svg/close.svg'

export default {
  name: 'ContentGallery',
  mixins: [Viewport],

  props: {
    content: {
      type: Object
    }
  },

  components: {
    IconFullscreen, IconClose
  },

  mounted () {
    this.isSwiping = false
    this.isChangingGallery = false
    this.firstTouchX = 0

    // mobile interaction

    document.addEventListener('touchstart', (e) => {
      this.isSwiping = true
      this.firstTouchX = e.touches[0].clientX
    }, false)

    document.addEventListener('touchmove', (e) => {
      this.isSwiping = false
      if (e.touches[0].clientX - this.firstTouchX > 30 && !this.isChangingGallery) {
        this.currentImage = Math.max(0, this.currentImage - 1)
        this.isChangingGallery = true
        this.updateGalleryX()
      }

      if (e.touches[0].clientX - this.firstTouchX < -30 && !this.isChangingGallery) {
        this.currentImage = Math.min(this.content.image.length - 1, this.currentImage + 1)
        this.isChangingGallery = true
        this.updateGalleryX()
      }
    }, false)

    document.addEventListener('touchend', (e) => {
      this.isSwiping = false
      this.isChangingGallery = false
    }, false)
  },

  destroyed () {
    document.removeEventListener('touchstart', null)
    document.removeEventListener('touchend', null)
    document.removeEventListener('touchmove', null)
  },

  methods: {
    onImageDown (e) {
      console.log(e.clientX)
      this.startMouseX = e.clientX
    },
    finishDrag () {
      this.isSwiping = false
      this.isChangingGallery = false
    },
    onDrag ({ deltaX, startX, startY }) {
      console.log(deltaX, startX, startY)

      if (!this.isChangingGallery && deltaX !== 0) {
        if (deltaX < 0) {
          this.currentImage = this.currentImage < this.content.image.length - 1 ? this.currentImage + 1 : this.content.image.length - 1
        } else {
          this.currentImage = this.currentImage > 0 ? this.currentImage - 1 : 0
        }
        this.updateGalleryX()
        this.isChangingGallery = true
      }
    },
    loaded (index) {
      if (index === 0) {
        this.currentImage = 0
        this.updateGalleryX()
      }
    },

    onFullscreen (isFullscreen) {
      if (this.viewPort.width < 600) {
        if (!this.isFullscreen) {
          setTimeout(this.centerGallery, 300)
        } else {
          this.$refs.container.scrollLeft = 0
          setTimeout(this.updateGalleryX, 300)
        }
      } else {
        setTimeout(this.updateGalleryX, 300)
      }

      this.isFullscreen = isFullscreen
    },

    onNavClick (index) {
      // console.log(index)
      this.currentImage = index
      this.updateGalleryX()
    },

    onImageClick (event, index) {
      console.log(event)
      if (event.clientX === this.startMouseX) {
        this.currentImage = index

        if (!this.isFullscreen) {
          this.onFullscreen(true)
        }
        this.updateGalleryX()
      }
    },

    centerGallery () {
      if (this.viewPort.width < 600) {
        let targetX = 0
        for (let i = 0; i < this.currentImage; i++) {
          const image = this.$refs['photo' + i][0]
          targetX += image.clientWidth + 10
        }
        this.$refs.container.scrollLeft = targetX
      }
    },

    updateGalleryX () {
      if (this.viewPort.width < 600 && this.isFullscreen) {
        this.galleryX = 0
      } else {
        let posX = 0
        const currentPhoto = this.$refs['photo' + this.currentImage]
        if (currentPhoto || currentPhoto[0]) {
          posX -= (currentPhoto.width || currentPhoto[0].clientWidth) / 2
        }

        if (this.currentImage > 0) {
          for (let i = 0; i < this.currentImage; i++) {
            const prevPhoto = this.$refs['photo' + i]
            if (prevPhoto) {
              posX -= (prevPhoto.width || prevPhoto[0].clientWidth)
              posX -= this.viewPort.width < 600 ? 10 : 30
            }
          }
        }

        this.galleryX = posX
      }
    },

    getImageClass (index) {
      const diff = this.currentImage - index
      if (diff === 0) {
        return 'center'
      } else if (diff === 1) {
        return 'left'
      } else if (diff === 2) {
        return 'far-left'
      } else if (diff === -1) {
        return 'right'
      } else if (diff === -2) {
        return 'far-right'
      } else {
        return 'off'
      }
    }
  },

  data () {
    return {
      currentImage: -1,
      isFullscreen: false,
      galleryX: 0
    }
  }
}
</script>

<style lang="scss" scoped>

.button-gallery-close {
  background-color: rgba(0, 0, 0, 0.3);
  position: absolute;
  right: 40px;
  top: 40px;
  z-index: 1;
  width: 60px;
  height: 60px;
  padding: 14px;
  border-radius: 100px;

  @include breakpoint('xs-only') {
    right: 20px;
    top: 20px;
  }

  .icon-close {
    margin: auto;
    width: 32px;
    height: 32px;
  }
}

.icon-fullscreen {
  position: absolute;
  right: 40px;
  top: 40px;
  z-index: 1;
  width: 32px;
  height: 32px;

  @include breakpoint('xs-only') {
    right: 20px;
    top: 20px;
    z-index: 1;
    width: 24px;
    height: 24px;
  }
}

.icon-fullscreen {
  @include breakpoint('xs-only') {
    display: none;
  }
}

.content-gallery {
  padding: 80px 0;

  @include breakpoint('sm-and-down') {
    padding: 40px 0;
  }
}

.gallery,
.content-gallery {
  min-height: 80vh;
  width: 100%;
  background-color: $c-light-green;
  color: $c-white;
  position: relative;
  overflow: hidden;
  padding-bottom: 96px;
  transition: all 0.5s;
  overflow: hidden;

  @include breakpoint('sm-and-down') {
    min-height: 80vh;
    padding-bottom: 0;
  }

  @include breakpoint('xs-only') {
    min-height: 74vh;
  }
}

.gallery-fullscreen {
  height: 100vh;
  width: 100%;
  position: fixed;
  overflow: hidden;
  z-index: 2;
  top: 0;
  left: 0;
  padding: 0 !important;

  .gallery {
    height: 100vh;
    @include breakpoint('xs-only') {
      overflow-x: scroll;
    }
  }

  img {
    height: 100vh;
    width: auto;
  }

  .image-container {
    height: 100%;
    position: relative;
    margin-top: 0;

    @include breakpoint('xs-only') {
      // max-width: 100%;
      position: absolute;
      left: 0;
      display: block;
      overflow: visible;
      width: 100%;
    }
  }

  .description-container,
  .nav-container {
    display: none;
  }
}

.image-container {
  margin-top: 24px;
  height: 80%;
  width: auto;
  position: absolute;
  white-space: nowrap;
  left: 50%;
  transition: transform 0.5s;

  @include breakpoint('xs-only') {
    // max-width: 100%;
    margin-top: 48px;
  }
}

img {
  height: 62vh;
  position: relative;
  display: inline-block;
  margin-right: 30px;
  cursor: pointer;

  @include breakpoint('xs-only') {
    // max-width: 100%;
    height: 40vh;
    margin-right: 10px;
  }
}

.left {
  transform: translateX(-100%);
  opacity: 0;
  left: 10%;
  background-position: center right;
  z-index: 0;
}

.far-left {
  transform: translateX(-150%);
  opacity: 0;
  left: 10%;
  background-position: center right;
  z-index: 0;
}

.far-right {
  transform: translateX(50%);
  opacity: 0;
  left: 90%;
  background-position: center left;
  z-index: 0;
}

.right {
  transform: translateX(0);
  opacity: 0;
  left: 90%;
  background-position: center left;
  z-index: 0;
}

.center {
  transform: translateX(-50%);
  background-position: center center;
  z-index: 1;
  opacity: 1;
}

.off {
  opacity: 0;
  background-position: center center;
  display: none;
}

.description-container {
  height: 60px;
  vertical-align: middle;
}

.text {
  position: absolute;
  width: 60%;
  left: 50%;
  transition: all 0.5s;
  display: inline-block;

  @include breakpoint('sm-and-down') {
    width: 80%;
  }
}

.description {
  display: inline-block;
  text-align: center;
  width: 100%;
  line-height: 24px;
  vertical-align: middle;

  @include breakpoint('sm-and-down') {
    line-height: 18px;
  }

  &::before {
    content: ' ';
    border-left: 1px solid $c-white;
    position: absolute;
    height: 20px;
    width: 1px;
    left: 50%;
    bottom: -25px;
  }
}

.nav-container {
  margin: auto;
  text-align: center;
  height: 10%;
  position: absolute;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;

  @include breakpoint('md-only') {
    bottom: -40px;
  }

  @include breakpoint('sm-and-down') {
    height: auto;
    bottom: 40px;
  }
}

.nav {
  width: 40px;
  height: 4px;
  display: inline-block;
  background-color: $c-white-30;
  cursor: pointer;

  @include breakpoint('sm-and-down') {
    width: 30px;
  }

  @include breakpoint('xs-only') {
    width: 12px;

    &:not(:last-child) {
      margin-right: 5px;
    }
  }

  &.active {
    background-color: $c-light-grey;
  }

  &:not(:last-child) {
    margin-right: 10px;
  }
}

// COLOR RULES
@each $name, $color in $group-colors {
  .bg-#{$name} {
    background-color: #{$color};
  }
}
</style>
