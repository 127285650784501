<template>
  <v-container class="content-text-image">
    <v-row class="mt-md-12">
      <v-col cols="12" md="5">
        <v-row class="height-100 pa-6 pa-md-12"
          align="center"
          justify="center">
          <v-col align="left" class="pl-md-12">
            <h3 class="bodoni mb-12">{{content[$route.params.language + 'Title']}}</h3>
            <p>{{content[$route.params.language + 'Description']}}</p>

            <div class="main-download" v-if="content[$route.params.language + 'Download'] && content[$route.params.language + 'Download'].file">
              <a :download="content[$route.params.language + 'Download'].file.originalName" target="_blank" :href="'/media/'+content[$route.params.language + 'Download'].file.id">
                <OverlayOval :text="content[$route.params.language + 'Download'].title"></OverlayOval>
              </a>
            </div>
          </v-col>
        </v-row>
      </v-col>

      <v-col class="img-container" cols="12" md="7">
        <div class="image" :style="{backgroundImage: 'url(/media/'+ content.image.id+')'}"></div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import OverlayOval from '../atoms/OverlayOval.vue'

export default {
  name: 'ContentTextWithImageRight',

  props: {
    content: {
      type: Object
    }
  },

  components: {
    OverlayOval
  }
}
</script>

<style lang="scss" scoped>
.content-text-image {
  width: 100%;
  min-height: 90vh;
  background-color: $c-white;
  border-top: 1px solid $c-black;
}

.img-container {
  min-height: 80vh;
  width: 100%;

  @include breakpoint('sm-and-down') {
    min-height: 60vh;
  }
}

.image {
  position: relative;
  height: 100%;
  width: 80%;
  left: 10%;
  background-size: contain;
  background-position: center;
}

</style>
