<template>
  <transition name="slide-up">
    <div class="product-preview" v-if="!$root.platform.mobile && currentRoomOver && getAllProductsInRoom(currentRoomOver).length > 0">
      <v-container fluid class="container py-6">
        <v-row class="mb-0 mb-xl-3">
          <h5 class="room-name uppercase letter-spacing-12 font-weight-bold c-brown">{{ $root.allLanguages[$route.params.language].productIn }} {{currentRoomOver.roomName[$route.params.language + 'Title']}}</h5>
        </v-row>
        <v-row>
          <v-col
            v-for="(product, index) in getAllProductsInRoom(currentRoomOver)"
            :key="'prod' + index"
            cols="2"
            xl="3"
            class="px-0 pb-0 pb-xl-3">
            <h4 class="uppercase bodoni letter-spacing-12">{{ product[$route.params.language + 'ProductTitle'] ? product[$route.params.language + 'ProductTitle'] : product.enProductTitle }}</h4>
            <h6 class="uppercase letter-spacing-12 font-weight-normal">{{ product.category[$route.params.language + 'Category'] }}</h6>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'ProductInRoom',

  data () {
    return {
      currentRoomOver: null
    }
  },

  mounted () {
    this.$events.on('onRoomOver', (room) => {
      if (room) {
        this.currentRoomOver = room
      } else {
        this.currentRoomOver = null
      }
    })
  },
  methods: {
    getAllProductsInRoom (room) {
      const products = []

      for (let i = 0; i < room.roomName.hotspotInfo.length; i++) {
        const hotspotGroup = room.roomName.hotspotInfo[i]
        for (let n = 0; n < hotspotGroup.hotspot.hotspotContent.length; n++) {
          const product = hotspotGroup.hotspot.hotspotContent[n]

          let exist = false

          for (let k = 0; k < products.length; k++) {
            const existing = products[k]
            if (existing._slug === product._slug) {
              exist = true
            }
          }

          if (!exist) {
            products.push(product)
          }
        }
      }

      return products
    }
  }
}
</script>

<style lang="scss" scoped>
.product-preview {
  width: 100%;
  background-color: $c-black-bg;
  position: absolute;
  z-index: 3;
  bottom: 0;
  pointer-events: none;

  @include breakpoint('lg-and-down') {
    h4 {
      font-size: 20px;
    }

    h6 {
      font-size: 12px;
    }
  }

  @include breakpoint('md-and-down') {
    h4 {
      font-size: 16px;
    }

    h6 {
      font-size: 10px;
    }
  }

  .container {
    width: 80%;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      width: 1px;
      height: 100%;
      bottom: 10%;
      left: -40px;
      background-color: $c-brown;
    }
  }
}

.slide-up-leave-active,
.slide-up-enter-active {
  transition: transform 0.5s $ease-out-quint;
}

.slide-up-enter,
.slide-up-leave-to {
  transform: translateY(120%) !important;
}
</style>
