<template>
  <div class="content-long-quote">
    <p class="quote mb-12">{{content[$route.params.language + 'Quote']}}</p>

    <div class="molteni-caption">
      <MarkdownBlock class="mb-3" :inline="false" linkify tag="div" :text="content[$route.params.language + 'Caption']" />
    </div>
  </div>
</template>

<script>
import { MarkdownBlock } from '@monogrid/vue-lib'

export default {
  name: 'ContentLongQuote',

  components: {
    MarkdownBlock
  },

  props: {
    content: {
      type: Object
    }
  }
}
</script>

<style lang="scss" scoped>
.content-long-quote {
  width: 90%;

  @include breakpoint('sm-and-down') {
    width: 100%;
    padding: 36px;

    .align-right {
      text-align: left;
    }
  }
}

.quote {
  padding-left: 96px;
  @include breakpoint('sm-and-down') {
    padding-left: 0;
  }
}

.molteni-caption {
  width: 80%;
  padding-left: 96px;
  padding-top: 48px;
  padding-bottom: 48px;
  background-color: $c-light-grey;

  @include breakpoint('sm-and-down') {
    width: 100vw;
    padding-left: 36px;
    margin-left: -36px;
    padding-top: 24px;
    padding-bottom: 24px;
  }

  &::before {
    width: 1px;
    height: 90px;
    content: '';
    border-left: 1px solid $c-brown;
    position: absolute;
    margin-top: -75px;
    margin-left: -48px;

    @include breakpoint('sm-and-down') {
      margin-left: -12px;
      height: 60px;
      margin-top: -40px;
    }
  }

  strong {
    font-weight: 600;
  }
}
</style>
