<template>
  <v-container fluid class="gallery-menu-top pa-0">
    <v-row class="mt-12 navigation" justify="center">
      <h4 class="uppercase mx-6 nav letter-spacing-10 extra-light"
        v-for="(item,index) in content"
        :class="{'active': index === currentIndex}"
        @click="currentIndex = index"
        :key="'title'+index">{{item[$route.params.language+'Title']}}</h4>
    </v-row>
    <v-row class="gallery pa-0 ma-0">
      <v-col class="img-container" cols="12" md="6">
        <div class="image" :style="{backgroundImage: 'url(/media/'+ content[currentIndex].image.id+')'}"></div>
      </v-col>

      <v-col cols="12" md="6">
        <v-row class="height-100 pa-6 pt-0 pt-md-12 pa-md-12"
          align="center"
          justify="center">
          <v-col align="left">
            <p>{{content[currentIndex][$route.params.language + 'Description']}}</p>
          </v-col>
        </v-row>
      </v-col>

    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'ContentGalleryMenuTop',

  props: {
    content: {
      type: Array
    }
  },

  data () {
    return {
      currentIndex: 0
    }
  }
}
</script>

<style lang="scss" scoped>
.gallery-menu-top {
  width: 80%;
  min-height: 90vh;
  margin-top: -10vh;
  background-color: $c-white;

  @include breakpoint('sm-and-down') {
    margin-top: 0;
    width: 100%;
  }
}

.gallery {
  min-height: 80vh;
  width: 100%;
}

.img-container {
  height: 80vh;
  width: 100%;

  @include breakpoint('sm-and-down') {
    height: 60vh;
  }
}

.image {
  position: relative;
  height: 100%;
  width: 80%;
  left: 10%;
  background-size: contain;
  background-position: center;
}

.navigation {
  height: 48px;
}

.nav {
  cursor: pointer;
  position: relative;

  &::before {
    content: '';
    width: 100%;
    height: 1px;
    border-bottom: 1px solid $c-beige;
    position: absolute;
    bottom: 0;
    transform: scaleX(0);
    transition: transform 0.25s;

    @include breakpoint('sm-and-down') {
      bottom: 10px;
    }
  }

  &.active {
    &::before {
      transform: scaleX(1);
    }
  }
}
</style>
