<template>
  <div class="hotspot-capsule" v-show="!isHidden">
    <div v-if="!isOpen" class="pointer button-open absolute-center" @click="toggleArtHotspot">
      <IconPlus class="absolute-center svg-fill-brown"></IconPlus>
    </div>

    <div v-if="isOpen" class="bg-click" @click="toggleArtHotspot"></div>
    <transition name="fade">
      <div v-if="isOpen" class="art-content">
        <v-row class="pa-0 ma-0">
          <v-col cols="5" class="content-image pa-0" :style="{backgroundImage: 'url(/media/'+ hotspot.hotspot.photo.id+')'}">
          </v-col>
          <v-col cols="7" class="content-scroll px-6 py-12">
            <h4 v-if="hotspot.hotspot[$route.params.language + 'WorkTitle']" class="my-3 bodoni extra-light px-6">{{hotspot.hotspot[$route.params.language + 'WorkTitle']}}</h4>
            <MarkdownBlock v-if="hotspot.hotspot[$route.params.language + 'WorkDescription']" class="px-6" :inline="false" linkify tag="div" :text="hotspot.hotspot[$route.params.language + 'WorkDescription']" />
            <div class="gradient"></div>
          </v-col>
        </v-row>

        <div v-if="isOpen" class="pointer button-close" @click="toggleArtHotspot">
          <IconClose class="absolute-center svg-fill-white"></IconClose>
        </div>
      </div>
    </transition>

  </div>
</template>

<script>
import IconPlus from '@/assets/svg/plus.svg'
import IconClose from '@/assets/svg/close.svg'
import { MarkdownBlock } from '@monogrid/vue-lib'
export default {
  name: 'HotspotCapsuleHtml',
  props: {
    hotspot: {
      type: Object,
      required: true
    }
  },

  mounted () {
    this.$events.on('closeArtHotspot', () => {
      this.isOpen = false
    })

    this.$events.on('onCloseHotspot', () => {
      this.isHidden = false
    })

    this.$events.on('onOpenHotspot', (hotspot) => {
      if (hotspot.hotspot.id !== this.hotspot.hotspot.id) {
        this.isHidden = true
      }
    })
  },

  methods: {
    openArtHotspot () {
      if (!this.$root.platform.mobile) {
        this.isOpen = true
        this.$events.emit('openArtHotspot', this.hotspot)
      }
    },
    closeArtHotspot () {
      if (!this.$root.platform.mobile) {
        this.isOpen = false
        this.$events.emit('closeArtHotspot')
      }
    },
    toggleArtHotspot () {
      if (this.isOpen === true) {
        this.isOpen = false
        this.$events.emit('closeArtHotspot')
      } else {
        this.isOpen = true
        this.$events.emit('openArtHotspot', this.hotspot)
      }
    }
  },
  components: {
    IconPlus, IconClose, MarkdownBlock
  },

  data () {
    return {
      isOpen: false,
      isHidden: false
    }
  }
}
</script>

<style lang="scss" scoped>
.gradient {
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
  position: fixed;
  width: calc((100% / 12) * 7);
  height: 60px;
  bottom: 0;
  right: 0;
  pointer-events: none;
}

.round-button {
  width: 40px;
  height: 40px;
  display: block;
  position: relative;
}

.button-open {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: $c-white;
}

.button-close {
  position: absolute;
  z-index: 2;
  top: -20px;
  left: 50%;
  width: 40px;
  height: 40px;
  border-radius: 20px;

  border: 1px solid $c-white;

  @include breakpoint('xs-only') {
    display: none;
  }
}

.bg-click {
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  position: absolute;
  transform: translate(-50%, -50%);
}

.art-content {
  position: absolute;
  width: 50vw;
  height: 50vh;
  left: 0;
  top: 0;
  transform: translate(-50%, -50%);
  background-color: $c-black-85;

  @include breakpoint('xs-only') {
    display: none;
  }

  .row {
    height: 100%;
  }

  img {
    height: 100%;
  }

  .content-image {
    overflow: hidden;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }

  .content-scroll {
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    position: relative;
  }
}
</style>
