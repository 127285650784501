var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"fade-delay","appear":""}},[_c('div',{staticClass:"rooms"},[_vm._l((_vm.floor.rooms),function(room,rindex){return _c('div',{key:rindex,staticClass:"room-container pointer",class:{
        'visited': (_vm.$route.params.roomName !== room.roomName._slug && _vm.visitedRooms.includes(room.roomName._slug)),
        'active': (_vm.$route.params.roomName === room.roomName._slug),
        'stairs': _vm.parsePosition(room.positionInMap).type === 'stairs'
      },style:({
        'left': _vm.parsePosition(room.positionInMap).x + '%',
        'top': _vm.parsePosition(room.positionInMap).y + '%'
      }),on:{"click":function($event){$event.stopPropagation();return _vm.onChangeRoom(_vm.floor._slug, room.roomName._slug)},"mouseover":function($event){return _vm.roomOver(room)},"mouseout":function($event){return _vm.$events.emit('onRoomOver', null)}}},[_c('div',{staticClass:"room-icon",class:{'stairs': _vm.parsePosition(room.positionInMap).type === 'stairs'}},[(_vm.parsePosition(room.positionInMap).type === 'stairs')?_c('IconStairs',{staticClass:"icon pov svg-fill-brown absolute-center"}):(!_vm.visitedRooms.includes(room.roomName._slug) || (_vm.$route.params.roomName === room.roomName._slug))?_c('IconPov',{staticClass:"icon pov svg-fill-white absolute-center"}):(_vm.visitedRooms.includes(room.roomName._slug) && (_vm.$route.params.roomName !== room.roomName._slug))?_c('IconCheck',{staticClass:"icon check svg-fill-brown absolute-center"}):_vm._e()],1),_vm._v(" "),(!_vm.parsePosition(room.positionInMap).type)?_c('p',{staticClass:"room-name uppercase letter-spacing-12"},[_vm._v(_vm._s(room.roomName[_vm.$route.params.language + 'Title']))]):_vm._e()])}),_vm._v(" "),(_vm.floor.exitPosition)?_c('div',{staticClass:"room-container pointer",style:({
        'left': _vm.parsePosition(_vm.floor.exitPosition).x + '%',
        'top': _vm.parsePosition(_vm.floor.exitPosition).y + '%'
      }),on:{"click":function($event){$event.stopPropagation();return _vm.$events.emit('onTourExit')}}},[_c('div',{staticClass:"room-icon exit"},[_c('IconExit',{staticClass:"icon exit svg-fill-brown"})],1)]):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }