<template>
  <div v-if="isVisible" class="exit-popup">
    <div class="dark-layer"></div>

    <v-container fluid class="exit-page-container pa-0">
      <v-row class="my-0">
        <v-col v-show="isShare" cols="12" sm="10" offset-sm="1" md="8" offset-md="2" class="exit-page-content pa-0">
          <v-row class="px-10 py-6">

            <!-- Close page -->
            <v-col cols="2" sm="12" align="left">
              <div class="back-to-tour" @click="isShare = false">
                <ArrowSvg class="mr-sm-4"/>
                <span class="d-none d-sm-block uppercase letter-spacing-20 font-weight-bold">{{ $root.allLanguages[$route.params.language].shareBack }}</span>
              </div>
            </v-col>

            <ExitPopupShare></ExitPopupShare>
          </v-row>
        </v-col>

        <v-col v-show="!isShare" cols="12" sm="10" offset-sm="1" md="8" offset-md="2" class="exit-page-content pa-0">
          <v-row class="px-10 py-6">

            <!-- Close page -->
            <v-col cols="2" sm="12" align="left">
              <div class="back-to-tour" @click="$emit('update:isVisible', false)">
                <ArrowSvg class="mr-sm-4"/>
                <span class="d-none d-sm-block uppercase letter-spacing-20 font-weight-bold">{{ $root.allLanguages[$route.params.language].exitPageBackToTourCopy }}</span>
              </div>
            </v-col>

            <!-- Mobile logo -->
            <v-col cols="8" align="center" class="d-sm-none">
              <MolteniLogoDark class="logo"/>
            </v-col>

            <!-- Text -->
            <v-col cols="12" lg="12" offset-lg="0" class="text-center mt-6">
              <h3 class="uppercase bodoni xl">{{ $root.allLanguages[$route.params.language].exitPageThankTitleCopy }}</h3>
            </v-col>
            <v-col cols="12" md="6" offset-md="3" class="text-center mt-0">
              <p>{{ $root.allLanguages[$route.params.language].exitPageThankMessageCopy }}</p>
            </v-col>

            <!-- Progress -->
            <v-col cols="12" class="tour-progress mb-6">
              <v-row justify="center" align="center">
                <v-col cols="12" md="2">
                  <CircularPercent :radius="50" :percent="mapSalonePercent"></CircularPercent>
                </v-col>
                <v-col cols="12" md="6" align="center" md-align="left">
                  <h4 class="uppercase extra-light">{{ $root.allLanguages[$route.params.language].saloneTitle }}</h4>
                  <p class="">{{ $root.allLanguages[$route.params.language].saloneSubtitle }}</p>
                </v-col>
                <v-col cols="12" md="4">
                  <div class="restart pointer pa-6" @click="$router.push({name: 'Home'}); $events.emit('onTourRestart')">
                    <span class="bold letter-spacing-15">{{ mapSalonePercent === 100 ? $root.allLanguages[$route.params.language].restartCopy : $root.allLanguages[$route.params.language].continueExperience }}</span>
                  </div>
                </v-col>
              </v-row>
              <v-row justify="center" align="center" class="top-line mt-6 pt-6">
                <v-col cols="12" md="2">
                  <CircularPercent :radius="50" :percent="mapPercent"></CircularPercent>
                </v-col>
                <v-col cols="12" md="6" align="center" md-align="left">
                  <h4 class="uppercase extra-light">{{ $root.allLanguages[$route.params.language].welcomeTitleCopy }}</h4>
                  <p class="uppercase">{{ $root.allLanguages[$route.params.language].molteniCompound }}</p>
                </v-col>
                <v-col cols="12" md="4">
                  <div class="restart pointer pa-6" @click="$router.push({name: 'Home'}); $events.emit('onTourRestart')">
                    <span class="bold letter-spacing-15">{{ mapPercent === 100 ? $root.allLanguages[$route.params.language].restartCopy : $root.allLanguages[$route.params.language].continueExperience }}</span>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="px-0">
            <!-- Footer -->
            <exit-footer @share="isShare = true"></exit-footer>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ExitFooter from '../atoms/ExitFooter.vue'
import ExitPopupShare from '../atoms/ExitPopupShare.vue'

import MolteniLogoDark from '@/assets/svg/molteni-logo-dark.svg'
import ArrowSvg from '@/assets/svg/arrow.svg'

import CircularPercent from '../atoms/CircularPercent.vue'

export default {
  props: ['isVisible'],

  data () {
    return {
      isLinkClicked: false,
      isShare: false,
      isContactClicked: false
    }
  },

  components: {
    MolteniLogoDark,
    ArrowSvg,
    CircularPercent,
    ExitPopupShare,
    ExitFooter
  },

  computed: {
    mapPercent () {
      if (this.$root.visitedRooms.length > 0 && this.$root.allRooms > 0) {
        return Math.min(100, Math.ceil((this.$root.visitedRooms.length / this.$root.allRooms) * 100))
      } else {
        return 0
      }
    },
    mapSalonePercent () {
      if (this.$root.visitedSaloneRooms.length > 0 && this.$root.allSaloneRooms > 0) {
        return Math.min(100, Math.ceil((this.$root.visitedSaloneRooms.length / this.$root.allSaloneRooms) * 100))
      } else {
        return 0
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .top-line {
    border-top: 1px solid $c-black;
  }

  .exit-popup {
    position: fixed;
    top: 0;
    z-index: 6;
  }

  .exit-page-container {
    position: fixed;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
  }

  .exit-page-content {
    position: relative;
    height: auto;
    background-color: $c-white;
    color: $c-black;
    margin-top: 100px;
    overflow: hidden;

    @include breakpoint('xs-only') {
      margin-top: 0;
      min-height: 100vh;
    }
  }

  .restart {
    border: 1px solid $c-black-30;
    display: inline-block;

    &:hover {
      border: 1px solid $c-black;
    }
  }

  .back-to-tour {
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;

    svg {
      transform: rotate(-180deg);
      transition: transform 0.25s;
    }

    &:hover {
      svg {
        transform: translateX(-4px) rotate(-180deg);
      }
    }
  }

  .tour-progress {
    position: relative;
    margin-bottom: 180px;

    @include breakpoint('xs-only') {
      margin-bottom: 40px;
    }
  }

  svg {
    width: 20px;
    height: 20px;
  }
</style>
