// Usage example : v-drag.prevent.mouse.window="onDragMove"
const getOptions = (modifiers = {}) => {
  const options = { prevent: false, mouse: false, touch: false, window: false }
  Object.keys(modifiers).forEach(keyValue => {
    const [key, value = true] = keyValue.split(':')
    options[key] = typeof value === 'boolean' ? value : parseInt(value)
  })
  return options
}

export default {
  isFn: true,
  bind (_el, binding) {
    console.log('bidning')
    const { prevent, mouse, touch, window: onWindow } = getOptions(binding.modifiers)
    const el = onWindow ? window : _el
    let isDragging, lastX, lastY, startX, startY
    const preventEvent = e => {
      if (prevent) {
        e.preventDefault()
        e.stopPropagation()
      }
    }

    binding.__dragStartCb = e => {
      preventEvent(e)
      isDragging = true
      const target = e.touches ? e.touches[0] : e
      lastX = startX = target.clientX
      lastY = startY = target.clientY
      binding.value({
        deltaX: 0,
        deltaY: 0,
        clientX: target.clientX,
        clientY: target.clientY,
        startX,
        startY,
        e
      })
    }

    binding.__dragEndCb = e => {
      preventEvent(e)
      isDragging = false
    }

    binding.__dragCb = e => {
      if (!isDragging) return
      preventEvent(e)
      const target = e.touches ? e.touches[0] : e
      binding.value({
        deltaX: target.clientX - lastX,
        deltaY: target.clientY - lastY,
        clientX: target.clientX,
        clientY: target.clientY,
        startX,
        startY,
        e
      })
      lastX = target.clientX
      lastY = target.clientY
    }

    if (!mouse) {
      el.addEventListener('touchstart', binding.__dragStartCb, { passive: !prevent })
      el.addEventListener('touchmove', binding.__dragCb, { passive: !prevent })
      el.addEventListener('touchend', binding.__dragEndCb, { passive: !prevent })
    }

    if (!touch) {
      el.addEventListener('mousedown', binding.__dragStartCb, { passive: !prevent })
      el.addEventListener('mousemove', binding.__dragCb, { passive: !prevent })
      el.addEventListener('mouseup', () => {
        isDragging = false
        console.log('mouse up')
      })
      el.addEventListener('mouseleave', binding.__dragEndCb, { passive: !prevent })
    }
  },
  unbind (_el, binding) {
    const { prevent, mouse, touch, onWindow } = getOptions(binding.modifiers)
    const el = onWindow ? window : _el
    if (!mouse) {
      el.removeEventListener('touchstart', binding.__dragStartCb, { passive: !prevent })
      el.removeEventListener('touchmove', binding.__dragCb, { passive: !prevent })
      el.removeEventListener('touchend', binding.__dragEndCb, { passive: !prevent })
    }

    if (!touch) {
      el.removeEventListener('mousedown', binding.__dragStartCb, { passive: !prevent })
      el.removeEventListener('mousemove', binding.__dragCb, { passive: !prevent })
      el.removeEventListener('mouseup', binding.__dragEndCb, { passive: !prevent })
    }
  }
}
