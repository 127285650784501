<template>
  <div class="content-text-title" :class="{'cta': content[$route.params.language + 'CTA']}">
    <h4 v-if="content[$route.params.language + 'Title']" class="mb-12 font-weight-100 uppercase">{{content[$route.params.language + 'Title']}}</h4>

    <MarkdownBlock v-if="content[$route.params.language + 'Description']" class="mb-3" :inline="false" linkify tag="div" :text="content[$route.params.language + 'Description']" />

    <a v-if="content[$route.params.language + 'CTA']" class="configurator pointer mt-6 pa-6" :href="getStoreLocatorLink" target="_blank">
      <span class="font-weight-bold letter-spacing-15">{{ content[$route.params.language + 'CTA'] }}</span>
    </a>
  </div>
</template>

<script>
import { MarkdownBlock } from '@monogrid/vue-lib'

export default {
  name: 'ContentLongQuote',

  components: {
    MarkdownBlock
  },

  props: {
    content: {
      type: Object
    }
  },

  computed: {
    getStoreLocatorLink () {
      let link = 'https://store.molteni.it/en/store_locator?referer=1&digital_consultancy=1'

      if (this.$root.userCountryCode) {
        const originalCountry = 'de,es,at,ch,gb,us,jp,fr,it'
        const spanishCountry = 'es,ar,bo,cl,co,cr,cu,do,ec,sv,gt,hn,mx,ni,pa,py,pe,pr,uy,ve'

        if (originalCountry.indexOf(this.$root.userCountryCode) > -1) {
          link = 'https://store.molteni.it/' + this.$root.userCountryCode + '/store_locator?referer=1&digital_consultancy=1'
        } else if (spanishCountry.indexOf(this.$root.userCountryCode) > -1) {
          link = 'https://store.molteni.it/es/store_locator'
        }
      }
      return link
    }
  }
}
</script>

<style lang="scss" scoped>
.configurator {
  border: 1px solid $c-black-30;
  display: inline-block;
  color: $c-black;

  &:hover {
    border: 1px solid $c-black;
  }
}

.content-text-title {
  width: 80%;

  padding: 120px 84px;

  @include breakpoint('sm-and-down') {
    width: 100%;
    padding: 60px 36px;
  }

  &.cta {
    width: 100%;
    padding: 0 84px 120px;
    text-align: center;

    @include breakpoint('sm-and-down') {
      width: 100%;
      padding: 0 36px 60px;
    }

    h3 {
      font-family: Bodoni;
      font-weight: 100;
      margin-bottom: 24px;
    }

    h4 {
      font-weight: 100;
      text-transform: uppercase;
    }
  }
}
</style>
