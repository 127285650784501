<template>
  <div class="content-quote">
    <h4 class="bodoni line-height-15">{{content[$route.params.language + 'Text']}}</h4>
    <p class="c-brown uppercase align-right mt-6">{{content.author}}</p>
  </div>
</template>

<script>
export default {
  name: 'ContentQuote',

  props: {
    content: {
      type: Object
    }
  }
}
</script>

<style lang="scss" scoped>
.content-quote {
  width: 80%;
  padding: 96px;
  padding-top: 48px;
  padding-bottom: 48px;
  background-color: $c-light-grey;

  @include breakpoint('sm-and-down') {
    width: 100%;
    padding: 36px;

    .align-right {
      text-align: left;
    }
  }
}
</style>
