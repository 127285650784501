<template>
  <div>
    <v-img
      :src="`/media/${$root.currentSettings.errorPageBgImage.id}`"
      class="bg-image"
    />
    <div class="dark-layer"></div>
    <v-container fluid class="comingsoon-page-container">
      <v-row>

        <v-col cols="12" align="center">
          <MolteniLogo class="logo"/>
        </v-col>

        <v-col
          cols="12"
          sm="8" offset-sm="2"
          lg="6" offset-lg="3"
          align="center"
          class="comingsoon-page-content"
        >
          <!--<h4 class="uppercase font-weight-100 mb-6">{{ $root.allLanguages[$route.params.language].errorCopy }}</h4>-->
          <h2 v-if="$root.allLanguages[$route.params.language].comingSoonTitle" class="uppercase bodoni mb-3 mb-md-10">{{ $root.allLanguages[$route.params.language].comingSoonTitle }}</h2>
          <!--<p class="mb-3 mb-md-10">{{ $root.allLanguages[$route.params.language].errorPageMessageCopy }}</p>-->
          <MarkdownBlock v-if="$root.allLanguages[$route.params.language].comingSoonCopy" class="mb-3 mb-md-10" :inline="false" linkify tag="div" :text="$root.allLanguages[$route.params.language].comingSoonCopy" />
          <a v-if="$root.allLanguages[$route.params.language].comingSoonSubscribe" class="subscribe pointer pa-6" :href="getSubscribeLink" target="_blank">
            <span class="font-weight-bold letter-spacing-15 uppercase">{{ $root.allLanguages[$route.params.language].comingSoonSubscribe }}</span>
          </a>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { MarkdownBlock } from '@monogrid/vue-lib'

import MolteniLogo from '@/assets/svg/molteni-logo-white.svg'

export default {
  name: 'ErrorScreen',

  components: {
    MolteniLogo, MarkdownBlock
  },

  computed: {
    getSubscribeLink () {
      return 'https://www.molteni.it/' + this.$route.params.language + '/landing/newsletter-subscription'
    }
  }
}
</script>

<style lang="scss" scoped>

.subscribe {
  border: 1px solid $c-black-30;
  display: inline-block;
  color: $c-white;
  background-color: $c-white-30;
  transition: all 0.25s;

  &:hover {
    color: $c-black;
    background-color: $c-white;
  }
}

.comingsoon-page-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  overflow-x: hidden;
  overflow-y: auto;
}

.bg-image {
  overflow: hidden;
  position: fixed;
  width: 100vw;
  height: 100vh;
}

.registration-link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 157px;
  height: 55px;
  margin: 0 auto;
  color: $c-white;
  border: 2px solid $c-white-30;
  text-decoration: none;
  letter-spacing: 0.6;
}

.comingsoon-page-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 150px);
}
</style>
