<template>
  <transition name="fade-delay" appear>
    <div class="rooms">
      <div class="room-container pointer"
        v-for="(room, rindex) in floor.rooms"
        :key="rindex"
        @click.stop="onChangeRoom(floor._slug, room.roomName._slug)"
        @mouseover="roomOver(room)"
        @mouseout="$events.emit('onRoomOver', null)"

        :class="{
          'visited': ($route.params.roomName !== room.roomName._slug && visitedRooms.includes(room.roomName._slug)),
          'active': ($route.params.roomName === room.roomName._slug),
          'stairs': parsePosition(room.positionInMap).type === 'stairs'
        }"
        :style="{
          'left': parsePosition(room.positionInMap).x + '%',
          'top': parsePosition(room.positionInMap).y + '%'
        }">
        <div class="room-icon"
          :class="{'stairs': parsePosition(room.positionInMap).type === 'stairs'}">
          <IconStairs v-if="parsePosition(room.positionInMap).type === 'stairs'" class="icon pov svg-fill-brown absolute-center"></IconStairs>
          <IconPov v-else-if="!visitedRooms.includes(room.roomName._slug) || ($route.params.roomName === room.roomName._slug)" class="icon pov svg-fill-white absolute-center"></IconPov>
          <IconCheck v-else-if="visitedRooms.includes(room.roomName._slug) && ($route.params.roomName !== room.roomName._slug)" class="icon check svg-fill-brown absolute-center"></IconCheck>
        </div>
        <p v-if="!parsePosition(room.positionInMap).type" class="room-name uppercase letter-spacing-12">{{room.roomName[$route.params.language + 'Title']}}</p>
      </div>

      <div class="room-container pointer"
        @click.stop="$events.emit('onTourExit')"
        v-if="floor.exitPosition"
        :style="{
          'left': parsePosition(floor.exitPosition).x + '%',
          'top': parsePosition(floor.exitPosition).y + '%'
        }">
        <div class="room-icon exit">
          <IconExit class="icon exit svg-fill-brown"></IconExit>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import IconStairs from '@/assets/svg/stairs.svg'
import IconExit from '@/assets/svg/exit.svg'
import IconPov from '@/assets/svg/pov.svg'
import IconCheck from '@/assets/svg/check.svg'

export default {
  name: 'FloorMapDot',

  components: {
    IconPov, IconCheck, IconExit, IconStairs
  },

  props: {
    floor: {
      type: Object
    }
  },

  computed: {
    visitedRooms () {
      return this.$root.currentSettings._slug.indexOf('salone') > -1 ? this.$root.visitedSaloneRooms : this.$root.visitedRooms
    }
  },
  methods: {
    roomOver (room) {
      if (this.parsePosition(room.positionInMap).type !== 'stairs') {
        this.$events.emit('onRoomOver', room)
      }
    },
    onChangeRoom (floor, room) {
      if (this.$route.params.roomName !== room) {
        // console.log(this.$root.getFloor(room))
        const newFloor = this.$root.getFloor(room) || floor
        // console.log('onChangeRoom', newFloor)
        this.$emit('onRoomClick', room)
        this.$events.emit('useCameraInitialPoint')
        this.$router.push(
          {
            name: 'Room',
            params: {
              floor: newFloor,
              roomName: room
            }
          }
        )
      }
    },
    parsePosition (posString) {
      return {
        x: posString.split(',')[0],
        y: posString.split(',')[1],
        type: posString.split(',')[2]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.room-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 80px;

  &::before,
  &::after {
    content: '';
    position: absolute;
    border: 1px solid $c-brown;
    width: 0;
    height: 0;
    border-radius: 0;
    opacity: 0;
    transition: all 0.5s;
  }

  &.visited {
    border-style: none;

    .room-icon {
      border: 1px solid $c-brown;
    }
  }

  &.active {
    .room-icon {
      background-color: $c-brown;
      border-style: none;
    }

    .room-name {
      display: none;
    }

    &::before {
      display: block;
      width: 56px;
      height: 56px;
      border-radius: 28px;
      opacity: 1;
      background-color: none !important;
    }

    &::after {
      display: block;
      width: 80px;
      height: 80px;
      opacity: 1;
      border-radius: 40px;
    }
  }

  &.active.stairs {
    .room-icon {
      background-color: $c-white;
      border: 1px solid $c-brown;
    }

    &::before,
    &::after {
      display: none;
    }
  }

  &:hover.stairs {
    &::before {
      opacity: 1;
      // background-color: transparent;
    }
  }

  &:hover.active {
    &::before {
      background-color: transparent;
      border: 1px solid $c-brown;
      opacity: 1;
    }

    .room-icon {
      background-color: $c-brown;

      .icon {
        fill: $c-white !important;

        path { //stylelint-disable
          fill: $c-white !important;
        }
      }
    }
  }

  &:hover {
    &::before {
      content: '';
      position: absolute;
      width: 56px;
      height: 56px;
      border-radius: 56px;
      display: block;
      opacity: 1;
      background-color: $c-white-30;
      border-style: none;
    }

    .room-icon {
      background-color: $c-white;

      .icon {
        fill: $c-brown !important;

        path {
          fill: $c-brown !important;
        }
      }
    }

    .room-name {
      display: block;
    }
  }
}

.room-icon {
  width: 32px;
  height: 32px;
  padding: 3px;
  border: 1px solid $c-white;
  border-radius: 50%;
  position: absolute;
  pointer-events: none;
  transition: all 0.5s;

  @include breakpoint('xs-only') {
    padding-left: 7px;
  }

  .icon {
    width: 16px;
  }

  &.stairs,
  &.exit {
    width: 44px;
    height: 44px;
    background-color: $c-white;
    padding: 9px;
    padding-left: 13px;

    .icon {
      width: 20px;
    }
  }
}

.room {
  position: absolute;
  background-color: #ccc;
  width: 6px;
  height: 6px;
  border-radius: 50%;
}

.room-name {
  width: 100px;
  text-align: center;
  position: absolute;
  top: 64px;
  font-size: 10px;
  pointer-events: none;

  @include breakpoint('sm-and-down') {
    font-size: 10px;
    line-height: 12px;
  }
}

.fade-delay-leave-active,
.fade-delay-enter-active {
  transition: opacity 0.5s 0.5s $ease-out-quint;
}

.fade-delay-enter,
.fade-delay-leave-to {
  opacity: 0 !important;
}

</style>
