<template>
  <v-row class="downloads px-0" align="center" justify="center">
    <!-- Datasheet -->
    <v-col class="download px-6" cols="12" md="4"  v-if="hotspotContent[$route.params.language + 'ProductDatasheet'] || hotspotContent.enProductDatasheet">
      <a
        :download="hotspotContent[$route.params.language + 'ProductDatasheet'].originalName"
        :href="'/media/'+ (hotspotContent[$route.params.language + 'ProductDatasheet'] ? hotspotContent[$route.params.language + 'ProductDatasheet'].id : hotspotContent.enProductDatasheet.id)"
      >
        <OverlayOval :text="$root.allLanguages[$route.params.language].productDatasheetCopy"></OverlayOval>
      </a>
    </v-col>
    <!-- Technical drawing -->
    <v-col class="download px-6" cols="12" md="4" v-if="hotspotContent.productTechnicalDrawing">
      <a
        :href="'/media/'+hotspotContent.productTechnicalDrawing.id"
        :download="hotspotContent.productTechnicalDrawing.originalName"
      >
        <OverlayOval :text="$root.allLanguages[$route.params.language].productTechnicalDrawingCopy"></OverlayOval>
      </a>
    </v-col>
    <!-- Press release
    <v-col class="download px-6" cols="12" md="4" v-if="$root.currentSettings.emailContact && !hotspotContent.hidePress && $root.userType !== 'architects' && $root.userType !== 'private' && $root.userType !== 'dealer'">
      <a target="_blank" :href="'mailto:' + $root.currentSettings.emailContact">
        <OverlayOval :icon="'mail'" :text="$root.allLanguages[$route.params.language].productPressReleaseCopy"></OverlayOval>
      </a>
    </v-col>
    -->
  </v-row>
</template>

<script>
import OverlayOval from '../atoms/OverlayOval.vue'

export default {
  name: 'ContentDownload',

  components: {
    OverlayOval
  },

  props: {
    hotspotContent: {
      type: Object
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
