<template>
  <div class="hotspot-art-mobile" v-if="hotspot" :class="{'capsule': hotspot.isCapsule}">

    <div class="art-content pb-6">
      <img v-if="hotspot.photo" :src="'/media/'+hotspot.photo.id">
      <h4 class="bodoni mb-6 px-6">{{hotspot.artist}}<br>{{hotspot[$route.params.language + 'Caption']}}</h4>
      <MarkdownBlock class="mb-3 px-6" :inline="false" linkify tag="h4" :text="hotspot[$route.params.language + 'WorkTitle'] ? hotspot[$route.params.language + 'WorkTitle'] : hotspot.workTitle" />
      <MarkdownBlock class="px-6" :inline="false" linkify tag="div" :text="hotspot[$route.params.language + 'WorkDescription']" />
    </div>

    <div class="gradient" v-if="hotspot.isCapsule"></div>

    <div class="pointer button-close" @click.stop="hotspot = null; $events.emit('closeArtHotspot')">
      <IconClose class="absolute-center svg-fill-white"></IconClose>
    </div>
  </div>
</template>

<script>
import IconClose from '@/assets/svg/close.svg'
import { MarkdownBlock } from '@monogrid/vue-lib'

export default {
  name: 'HotspotArtHtmlMobile',

  mounted () {
    this.$events.on('openArtHotspot', (hotspot) => {
      this.hotspot = hotspot.hotspot
    })
  },

  components: {
    IconClose, MarkdownBlock
  },

  data () {
    return {
      hotspot: null
    }
  }
}
</script>

<style lang="scss" scoped>
.hotspot-art-mobile {
  position: fixed;
  display: none;
  bottom: 0;
  width: 100vw;

  @include breakpoint('xs-only') {
    display: block;
  }
}

h4.bodoni {
  max-width: 80%;
}

.button-close {
  width: 40px;
  height: 40px;
  border-radius: 20px;
  border: 1px solid $c-white;

  position: absolute;
  top: 20px;
  right: 20px;
}

.capsule .button-close {
  top: -20px;
}

.capsule .art-content {
  max-height: 65vh;
  overflow-x: hidden;
  overflow-y: auto;
}

.gradient {
  background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
  position: fixed;
  width: 100%;
  height: 60px;
  bottom: 0;
  right: 0;
  pointer-events: none;
}

.art-content {
  position: relative;
  background-color: $c-black-50;

  img {
    width: 100%;
  }
}
</style>
