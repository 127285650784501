<template>
  <div>
    <v-img
      :src="`/media/${$root.currentSettings.errorPageBgImage.id}`"
      class="bg-image"
    />
    <div class="dark-layer"></div>
    <v-container fluid class="error-page-container">
      <v-row>

        <v-col cols="12" align="center">
          <MolteniLogo class="logo"/>
        </v-col>

        <v-col
          cols="12"
          sm="8" offset-sm="2"
          lg="6" offset-lg="3"
          align="center"
          class="error-page-content"
        >
          <h4 class="uppercase font-weight-100 mb-6">{{ $root.allLanguages[$route.params.language].errorCopy }}</h4>
          <h2 class="uppercase bodoni mb-10">{{ $root.allLanguages[$route.params.language].errorPageTitleCopy }}</h2>
          <p class="mb-10">{{ $root.allLanguages[$route.params.language].errorPageMessageCopy }}</p>
          <!--<p class="mb-10">
            {{ $root.allLanguages[$route.params.language].errorPageRegisterMessageCopy }}
            <strong class="font-weight-bold">
              {{ $root.allLanguages[$route.params.language].errorPageRegisterMessageInviteCopy }}
            </strong>.
          </p>
          <router-link
            :to="{ name: 'Registration' }"
            class="registration-link uppercase"
          >
            <h6>{{ $root.allLanguages[$route.params.language].errorPageButtonCopy }}</h6>
          </router-link>-->
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>

import MolteniLogo from '@/assets/svg/molteni-logo-white.svg'

export default {
  name: 'ErrorScreen',

  components: {
    MolteniLogo
  }
}
</script>

<style lang="scss" scoped>

  .error-page-container {
    width: 100vw;
    height: 100vh;
    position: fixed;
    overflow-x: hidden;
    overflow-y: auto;
  }

  .bg-image {
    overflow: hidden;
    position: fixed;
    width: 100vw;
    height: 100vh;
  }

  .registration-link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 157px;
    height: 55px;
    margin: 0 auto;
    color: $c-white;
    border: 2px solid $c-white-30;
    text-decoration: none;
    letter-spacing: 0.6;
  }

  .error-page-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 150px);
  }
</style>
