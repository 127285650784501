<template>
  <v-container fluid class="mt-6 mt-md-0 pa-0 gallery-text-right" :class="[
    {'flip-mobile': content.flipMobile},
    {'white-version': content.whiteVersion},
    'bg-' + $root.roomClass]">
    <v-row class="gallery">
      <v-col cols="12" :md="content.whiteVersion ? 7 : 6">
        <h4 v-if="content[$route.params.language + 'Title']" class="title-mobile uppercase mb-6 px-6 font-weight-100">{{content[$route.params.language + 'Title']}}</h4>

        <div class="image-container" v-if="content.image.length > 1">
          <div v-for="(image, index) in content.image"
            class="image"
            :class="getImageClass(index)"
            :key="'img'+index"
            @click="onImageClick(index)"
            :style="{backgroundImage: 'url(/media/'+image.id+')'}"></div>
        </div>
        <div v-else class="single-image-container">
          <img :src="'/media/'+content.image[0].id">
        </div>
        <div class="nav-container" v-if="content.image.length > 1">
          <div v-for="(image, index) in content.image"
            class="nav"
            :class="{'active': index === currentImage}"
            :key="'img'+index"
            @click="onImageClick(index)"
          ></div>
        </div>
      </v-col>
      <v-col cols="12" :md="content.whiteVersion ? 5 : 6">
        <v-row class="height-100 px-6 pt-0 pb-3 pb-md-9 pr-sm-16"
          align="center"
          justify="center">
          <v-col align="left">
            <h4 v-if="content[$route.params.language + 'Title']" class="title uppercase mb-6 mb-md-12 font-weight-100">{{content[$route.params.language + 'Title']}}</h4>
            <MarkdownBlock v-if="content[$route.params.language + 'RichTitle']" class="rich-title mb-6 mb-md-12" :inline="false" linkify tag="div" :text="content[$route.params.language + 'RichTitle']" />

            <MarkdownBlock v-if="content[$route.params.language + 'Description']" :inline="false" linkify tag="div" :text="content[$route.params.language + 'Description']" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { MarkdownBlock } from '@monogrid/vue-lib'

export default {
  name: 'ContentGalleryRightText',

  components: {
    MarkdownBlock
  },

  props: {
    content: {
      type: Object
    }
  },

  mounted () {
    if (this.content.image && this.content.image.length > 1) {
      this.isChangingGallery = false

      document.addEventListener('touchstart', (e) => {
        this.isSwiping = true
        this.firstTouchX = e.touches[0].clientX
      }, false)

      document.addEventListener('touchmove', (e) => {
        this.isSwiping = false
        if (e.touches[0].clientX - this.firstTouchX > 30 && !this.isChangingGallery) {
          this.currentImage = Math.max(0, this.currentImage - 1)
          this.isChangingGallery = true
          this.onImageClick(this.currentImage)
        }

        if (e.touches[0].clientX - this.firstTouchX < -30 && !this.isChangingGallery) {
          this.currentImage = Math.min(this.content.image.length - 1, this.currentImage + 1)
          this.isChangingGallery = true
          this.onImageClick(this.currentImage)
        }
      }, false)

      document.addEventListener('touchend', (e) => {
        this.isSwiping = false
        this.isChangingGallery = false
      }, false)
    }
  },
  methods: {
    onImageClick (index) {
      this.currentImage = index
    },

    getImageClass (index) {
      const diff = this.currentImage - index
      /* if (diff === 0) {
        return 'center'
      } else if (diff === 1 || (diff * -1 === this.content.image.length - 1)) {
        return 'left'
      } else if (diff === -1 || (diff === this.content.image.length - 1)) {
        return 'right'
      } else {
        return 'off'
      } */
      if (diff === 0) {
        return 'center'
      } else if (diff === 1) {
        return 'left'
      } else if (this.currentImage === 0 && index === this.content.image.length - 1) {
        return 'left'
      } else {
        return 'off'
      }
    }
  },

  data () {
    return {
      currentImage: 0
    }
  }
}
</script>

<style lang="scss" scoped>
.gallery,
.gallery-text-right {
  padding: 120px 0;
  min-height: 90vh;
  width: 100%;
  background-color: $c-beige;
  color: $c-black;
  overflow: hidden;

  @include breakpoint('sm-and-down') {
    margin: auto;
    padding: 60px 0;
  }

  &.white-version {
    background-color: $c-white;
    color: $c-black;
    min-height: auto;
  }
}

.title {
  @include breakpoint('xs-only') {
    display: none;
  }
}

.title-mobile {
  display: none;
  @include breakpoint('xs-only') {
    display: block;
  }
}

.rich-title {
  h4 {
    text-transform: uppercase;
    font-weight: 100;
  }

  h3 {
    font-family: Bodoni;
    font-weight: 300;
    font-size: 24px;
    letter-spacing: normal;

    @include breakpoint('md-and-down') {
      font-size: 22px;
    }

    @include breakpoint('sm-and-down') {
      font-size: 20px;
    }
  }
}

.white-version.flip-mobile {
  .gallery {
    @include breakpoint('xs-only') {
      flex-direction: column-reverse;
    }
  }

  .image-container {
    @include breakpoint('xs-only') {
      height: 50vh;
    }
  }
}

.white-version {
  .gallery {
    background-color: $c-white;
    color: $c-black;
    padding: 40px 0;
    min-height: auto;

    @include breakpoint('sm-and-down') {
      margin: auto;
      padding: 20px 0;
    }
  }

  .image {
    width: 90%;
    left: 10%;

    @include breakpoint('xs-only') {
      width: 100%;
      left: 0;
    }
  }

  .image-container {
    @include breakpoint('xs-only') {
      height: 80vh;
    }
  }
}

.single-image-container {
  img {
    width: calc(100% - 84px);
    margin-left: 84px;

    @include breakpoint('xs-only') {
      width: 100%;
      margin-left: 0;
    }
  }
}

.image-container {
  height: 70vh;
  width: 100%;
  position: relative;

  @include breakpoint('xs-only') {
    height: 64vh;
  }
}

.image {
  position: absolute;
  height: 100%;
  width: 80%;
  left: 20%;
  background-size: contain;
  background-position: left top;
  transition: opacity 0.5s, transform 0.5s;
  cursor: pointer;
  z-index: 0;
  opacity: 0;

  @include breakpoint('xs-only') {
    left: 24px;
  }

  &.left {
    transform: translateX(-110%);
    opacity: 1;
  }

  &.center {
    z-index: 1;
    opacity: 1;
  }

  &.off {
    transform: translateX(10%);
    opacity: 0;
  }
}

.nav-container {
  margin-left: 20%;
  @include breakpoint('xs-only') {
    margin-left: 24px;
  }
}

.nav {
  width: 40px;
  height: 4px;
  display: inline-block;
  margin-right: 10px;
  background-color: $c-white-30;
  cursor: pointer;

  &.active {
    background-color: $c-light-grey;
  }
}

// COLOR RULES
@each $name, $color in $group-colors {
  .bg-#{$name} {
    background-color: #{$color};

    .gallery {
      background-color: #{$color};
    }
  }
}
</style>
