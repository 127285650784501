<template>
  <div
    @click="onButtonClick"
    class="tutorial-btn uppercase"
    :class="{'clicked': clicked}"
  >
    {{ text }}
  </div>
</template>

<script>
export default {

  props: ['text'],

  data () {
    return {
      clicked: false
    }
  },

  methods: {
    onButtonClick () {
      this.clicked = true
      setTimeout(() => {
        this.clicked = false
      }, 500)
      this.$emit('onClick')
    }
  }

}
</script>

<style lang="scss" scoped>
  .tutorial-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 158px;
    height: 55px;
    margin: 0 auto;
    letter-spacing: 0.6;
    color: $c-white;
    border: 1px solid $c-white-30;
    cursor: pointer;
    transition: all 0.4s ease-in-out;

    @include breakpoint('xs-only') {
      width: 200px;
      height: 50px;
    }

    &:hover {
      border-color: $c-white;
    }

    &.clicked {
      transition: all 0.4s ease-in-out;
      background-color: $c-white-30;
    }
  }
</style>
