<template>
  <div class="audio svg-stroke-white" :class="{'playing': (playing)}">
    <span></span>
    <span></span>
    <span></span>
    <span></span>
    <span></span>
  </div>
</template>

<script>
export default {
  props: ['playing']
}
</script>

<style lang="scss" scoped>
  .audio {
    width: 20px;
    height: 20px;
    position: relative;

    span {
      position: absolute;
      bottom: 50%;
      transform: translateY(50%);
      width: 1px;
      height: 4px;
      background-color: $c-white;
      display: block;

      &:nth-child(2) {
        left: 5px;
        height: 12px;
      }

      &:nth-child(3) {
        left: 10px;
        height: 20px;

      }

      &:nth-child(4) {
        left: 15px;
        height: 12px;
      }

      &:nth-child(5) {
        left: 20px;
      }
    }

    &.playing {
      span {
        animation: audio 1.5s infinite ease-in-out;

        &:nth-child(2) {
          animation-delay: 0.2s;
        }

        &:nth-child(3) {
          animation-delay: 0.4s;
        }

        &:nth-child(4) {
          animation-delay: 0.6s;
        }

        &:nth-child(5) {
          animation-delay: 0.8s;
        }
      }

      @keyframes audio {
        0%,
        50%,
        100% {
          height: 5px;
        }

        25% {
          height: 30px;
        }
      }
    }
  }
</style>
