<template>
  <div
    class="hotspot-container absolute-center"
    :class="{'open': isOpen}"
    v-show="!isHidden"
    @click.stop="onOpenClick">

    <div class="dot absolute-center" :class="{'open': isOpen}"></div>
    <div class="hotspot-content-container absolute-center" :class="{'open': isOpen}">

      <!-- CLOSE ICON -->
      <div v-if="isOpen" class="close absolute-center svg-stroke-white" @click.stop="onMouseLeave">
        <CloseSvg/>
      </div>

      <div v-if="isOpen" >
        <!-- COLLECTION PRODUCTS -->
        <div
          v-for="(content, index) in hotspotContent"
          :key="index"
          class="button-content"
          :class="['pos-'+ index + '-' + hotspotContent.length,
            {'active': currentOver === content.title}]"
          @click.stop="onClick(content._slug)"
          @mouseover="currentOver = content.title"
          @mouseout="currentOver = null"
          >

          <div class="circle absolute-center"></div>
          <div class="button-content-title">
            <h4 v-if="content.category" class="uppercase letter-spacing-12">{{ content.category[$route.params.language + 'Category'] }}</h4>
            <h5 class="letter-spacing-12" v-if="content.collection">{{ content.collection[$route.params.language + 'Title'] }}</h5>
          </div>

          <div class="button-content-circle"></div>
          <img v-if="content.category && content.category.iconBlack" :src="'/media/' + content.category.iconBlack.id">
        </div>

        <div class="title-container">
          <div v-for="(content, index) in hotspotContent"
            :key="index">
            <h1
              @mouseover="currentOver = content.title"
              @mouseout="currentOver = null"
              @click.stop="onClick(content._slug)"
              class="hotspot-collection-title bodoni uppercase my-3"
              :class="[
                {'dark': currentOver && currentOver !== content.title},
                {'underline': currentOver && currentOver === content.title}]">{{content[$route.params.language + 'ProductTitle'] ? content[$route.params.language + 'ProductTitle'] : content.title}}</h1>
          </div>
        </div>

        <!-- PLAY ICON
        <div class="button-content">
          <PlaySvg/>
        </div>
        -->

      </div>
    </div>
  </div>
</template>

<script>

import CloseSvg from '@/assets/svg/close.svg'
// import PlaySvg from '@/assets/svg/play.svg'
import { Viewport } from '@monogrid/vue-lib'

export default {
  name: 'HotspotHtml',
  mixins: [Viewport],

  components: {
    CloseSvg
    // PlaySvg
  },

  computed: {
    hotspotContent () {
      let hsContent = this.hotspot.hotspot.hotspotContent

      if (this.hotspot.hotspot.hotspotContentCategory && this.hotspot.hotspot.hotspotContentCategory.length > 1) {
        hsContent = []

        for (let i = 0; i < this.hotspot.hotspot.hotspotContentCategory.length; i++) {
          const element = this.hotspot.hotspot.hotspotContentCategory[i]
          if (element.category) {
            element.hotspot.category = element.category
          }
          hsContent.push(element.hotspot)
        }
      }
      return hsContent
    }
  },
  props: {
    hotspot: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      currentOver: null,
      isOpen: false,
      isHidden: false
    }
  },

  mounted () {
    this.$events.on('onCloseHotspot', () => {
      this.isOpen = false
      this.isHidden = false
    })

    this.$events.on('onOpenHotspot', (hotspot) => {
      if (hotspot.hotspot.title !== this.hotspot.hotspot.title) {
        this.isHidden = true
      }
    })
  },

  methods: {
    onOpenClick () {
      // console.log('open')
      this.isOpen = true
      this.$events.emit('onOpenHotspot', this.hotspot)
    },

    onMouseLeave () {
      if (this.viewPort.width > 600) {
        this.isOpen = false
        this.$events.emit('onCloseHotspot')
        this.$root.currentHotspot = null
      }
    },

    onClick (slug) {
      this.$root.currentHotspot = this.hotspot.hotspot
      this.$router.push({
        name: 'Room',
        params: { hotspotContent: slug }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.hotspot-container {
  position: absolute;
  transition: width 0.5s, height 0.5s;
  border-radius: 50%;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    display: block;
    width: 105px;
    height: 105px;
    box-sizing: border-box;
    top: 0;
    left: 0;
    visibility: initial;
    transform-origin: center center;
    transform: translate(-50%, -50%) scale(0.3);
    opacity: 0;
    border-radius: 60px;
    background-color: $c-white;
    animation: pulse 4s cubic-bezier(0.075, 0.82, 0.165, 1) infinite;
  }

  &:hover {
    .dot {
      width: 20px;
      height: 20px;
      border-radius: 10px;
    }
  }

  &.open,
  &.open:hover {
    width: 300px;
    height: 300px;
    background-color: transparent;

    @include breakpoint('xs-only') {
      pointer-events: none;
    }

    &::before {
      visibility: hidden;
    }
  }
}

.dot {
  width: 10px;
  height: 10px;
  background-color: $c-white;
  border-radius: 5px;
  transition: all 0.5s;

  @include breakpoint('sm-and-up') {
    &.open {
      display: none;
    }
  }
}

.close {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border: 1px solid $c-white;
  border-radius: 30px;

  @include breakpoint('xs-only') {
    display: none;
  }

  svg {
    fill: $c-white;
    width: 20px;
    height: auto;
    transition: transform 0.25s;
  }

  &:hover {
    svg {
      transform: scale(1.2);
    }
  }
}

.hotspot-content-container {
  background: none;
  width: 100px;
  height: 100px;
  border-radius: 50px;
  border: 1px solid $c-white-30;
  transform: translate(-50%, -50%);
  transition: all 0.5s $ease-out-quint;

  &.open {
    width: 370px;
    height: 370px;
    border-radius: 185px;
    border: 85px solid $c-white-30;

    @include breakpoint('xs-only') {
      width: 100px;
      height: 100px;
      border-radius: 50px;
      border: 1px solid $c-white-30;
    }
  }
}

.title-container {
  position: absolute;
  right: 160px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;

  @include breakpoint('xs-only') {
    display: none;
  }
}

.hotspot-collection-title {
  pointer-events: all;
  transition: opacity 0.5;
  position: relative;
  display: inline-block;
  white-space: nowrap;
  max-width: 45vw;

  @include breakpoint('md-and-down') {
    line-height: 0.8;
  }

  @include breakpoint('sm-and-down') {
    white-space: normal;
    line-height: 0.8;
  }

  &::before {
    content: '';
    bottom: 0;
    position: absolute;
    transform: translateY(100%);
    width: 0;
    transition: width 0.5s;
    height: 1px;
    border-bottom: 1px solid $c-white;
  }

  &.underline {
    &::before {
      width: 100%;
    }
  }

  &.dark {
    opacity: 0.5;
  }
}

.button-content {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  z-index: 1;
  transform: translate(-50%, -50%);

  @include breakpoint('xs-only') {
    display: none;
  }
  //3 palline
  &.pos-0-3 {
    left: 115%;
    top: -15%;
  }

  &.pos-0-1,
  &.pos-1-3 {
    left: 140%;
    top: 50%;
  }

  &.pos-2-3 {
    left: 115%;
    top: 115%;
  }
  //4 palline
  &.pos-0-4 {
    left: 50%;
    top: -50%;
  }

  &.pos-1-4,
  &.pos-0-2 {
    left: 140%;
    top: 0;
  }

  &.pos-2-4,
  &.pos-1-2 {
    left: 140%;
    top: 100%;
  }

  &.pos-3-4 {
    left: 50%;
    top: 150%;
  }

  .circle {
    background-color: #fff;
    width: 80px;
    height: 80px;
    border-radius: 40px;
    box-shadow: 0 13px 34px 0 rgba(0, 0, 0, 0.3);
  }

  svg {
    width: 30px;
    fill: $c-black;
  }

  &::before {
    content: '';
    z-index: 0;
    position: absolute;
    width: 200vw;
    height: 200vh;
    // transform: translate(-50%, -50%);
    background-color: $c-black-60;
    opacity: 0;
    transition: opacity 0.25s;
    pointer-events: none;
  }

  .button-content-title {
    opacity: 0;
    transform: translateX(200px);
    width: 280px;
    color: #fff;
    transition: all 0.4s ease-in-out;
  }

  .button-content-circle {
    opacity: 0;
    position: absolute;
    z-index: 0;
    border: 45px solid rgba(255, 255, 255, 0.5);
    width: 186px;
    height: 186px;
    border-radius: 50%;
    transform: scale(0);
    transition: all 0.4s ease-in-out;
  }

  img {
    position: absolute;
    width: 40px;
    z-index: 4;
  }

  &.active {
    z-index: 2;

    &::before,
    .button-content-title,
    .button-content-circle {
      opacity: 1;
    }

    .button-content-circle {
      transform: scale(1);
    }
  }
}
</style>
